import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import GuestRoute from './GuestRoute';
import { ROLE } from '../utils/helper';
import ExamPaperSave from '../containers/ExamPaperCreation/ExamPaperSave';
import QuestionAndAnswers from '../containers/ExamPaperCreation/QuestionAndAnswers';
import EditOrReview from '../containers/ExamPaperCreation/EditOrReview';
import PendingExamPapers from '../containers/PendingExamPapers';
import PublishedExamPapers from '../containers/PublishedExamPapers';
import History from '../containers/History';
import YearBreakDown from '../containers/History/YearBreakDown';
import ExamPaperSubscribe from '../containers/ExamPaperSubscribe';

import Registration from '../containers/Registration';
import ViewPublishedExam from '../containers/PublishedExamPapers/ViewExam';
import Reports from '../containers/Reports';
import Profile from '../containers/Profile';
import SettlementDetails from '../containers/SettlementDetails';
import BatchResults from '../containers/BatchResults';
import Subscriptions from '../containers/Subscriptions';
import keycloakService from '../keycloakService';
import TypistHome from '../containers/TypistHome';
import CorrectorHome from '../containers/CorrectorHome';
import TypistSubscribeHandle from '../containers/TypistSubscribe';
import Welcome from '../components/Welcome';
import TypistHistory from '../components/Typist/TypistHistory';
import TypistPaperRequest from '../components/Typist/TypistPaperRequest';
import TypistCurrentWork from '../components/Typist/TypistCurrentWork';
import PaperReviewRequest from '../components/Typist/PaperReviewRequest';
import TypingPapers from '../components/TypistSubscribe/TypingPapers';
import TypingReviewRequest from '../components/TypistSubscribe/TypingReviewRequest';
import TypistRejectedRequest from '../components/TypistSubscribe/TypistRejectedRequest';
import TypingCompleted from '../components/TypistSubscribe/TypingCompleted';
import TypingPendingRequest from '../components/TypistSubscribe/TypingPendingRequest';
import TypistTeacherRequest from '../components/Typist/TypistTeacherRequest';
import QuestionSecondPart from '../containers/ExamPaperCreation/QuestionSecondPart';
import CorrectorPaperRequest from '../components/Corrector/CorrectorPaperRequest';
import CorrectorCurrentWork from '../components/Corrector/CorrectorCurrentWork';
import AnnotationResult from '../containers/Annotation';
import CorrectorHistory from '../components/Corrector/CorrectorHistory';
import CorrectorTeacherRequest from '../components/Corrector/CorrectorTeacherRequest';
import CorrectorSubscribeHandle from '../containers/CorrectorSubscribe';
import CorrectorRejectedRequest from '../components/CorrectorSubscribe/CorrectorRejectedRequest';
import CorrectingPapers from '../components/CorrectorSubscribe/CorrectingPapers';
import CorrectingPendingRequest from '../components/CorrectorSubscribe/CorrectingPendingRequest';
import HandleSelector from '../containers/AddSelector';
import FAQ from '../containers/Faq';
import PromoCodeSubscription from '../components/PromoCodeSubscribe';
import pointsTopupViaEmail from '../components/PointsTopupViaMail';
import AdminHome from '../containers/AdminHome';
import TutorHome from '../containers/TutorHome';
import Income from '../containers/Income';
import LearnLaText from '../containers/LearnLaText';

export default function Routes() {
  const hasTeacherRole = keycloakService.hasRole([ROLE.Teacher]);
  const hasTypistRole = keycloakService.hasRole([ROLE.Typist]);
  const hasCorrectorRole = keycloakService.hasRole([ROLE.Corrector]);
  const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);
  const hasTutorRole = keycloakService.hasRole([ROLE.Tutor]);
  return (
    <Router>
      <Switch>
        <GuestRoute exact path="/sign-up" component={Registration} />
        {!hasTeacherRole &&
          !hasTypistRole &&
          !hasCorrectorRole &&
          !hasAdminRole &&
          !hasTutorRole && <GuestRoute exact path="/" component={Welcome} />}
        {hasTeacherRole && (
          <PrivateRoute exact path="/" component={History} requiredRoles={[ROLE.Teacher]} />
        )}
        {hasTypistRole && (
          <PrivateRoute exact path="/" component={TypistHome} requiredRoles={[ROLE.Typist]} />
        )}
        {hasCorrectorRole && (
          <PrivateRoute exact path="/" component={CorrectorHome} requiredRoles={[ROLE.Corrector]} />
        )}
        {hasAdminRole && (
          <PrivateRoute exact path="/" component={AdminHome} requiredRoles={[ROLE.Admin]} />
        )}
        {hasTutorRole && (
          <PrivateRoute exact path="/" component={TutorHome} requiredRoles={[ROLE.Tutor]} />
        )}
        <PrivateRoute path="/income" component={Income} requiredRoles={[ROLE.Teacher]} />
        <PrivateRoute
          path="/create-paper"
          component={ExamPaperSave}
          requiredRoles={[ROLE.Teacher, ROLE.Admin]}
        />
        <PrivateRoute
          path="/learn-latext"
          component={LearnLaText}
          requiredRoles={[ROLE.Teacher, ROLE.Admin]}
        />
        <PrivateRoute
          path="/teacher_assignment/admin"
          component={ExamPaperSave}
          requiredRoles={[ROLE.Admin]}
        />
        <PrivateRoute
          path="/edit-paper/:examPaperId"
          component={ExamPaperSave}
          requiredRoles={[ROLE.Teacher, ROLE.Typist, ROLE.Admin]}
        />
        <PrivateRoute
          path="/exam-paper/:examPaperId/questions"
          component={QuestionAndAnswers}
          requiredRoles={[ROLE.Teacher, ROLE.Typist, ROLE.Admin]}
        />
        <PrivateRoute
          path="/exam-paper/:examPaperId/part_2-questions"
          component={QuestionSecondPart}
          requiredRoles={[ROLE.Teacher, ROLE.Typist, ROLE.Admin]}
        />
        <PrivateRoute
          path="/exam-paper/:examPaperId/review"
          component={EditOrReview}
          requiredRoles={[ROLE.Teacher, ROLE.Typist, ROLE.Admin]}
        />
        <PrivateRoute
          path="/pending-exam-papers"
          component={PendingExamPapers}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          path="/published-exam-papers"
          component={PublishedExamPapers}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          path="/published-exam-paper/:examPaperId/view"
          component={ViewPublishedExam}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/history"
          component={YearBreakDown}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/subscribe-paper"
          component={ExamPaperSubscribe}
          requiredRoles={[ROLE.Teacher, ROLE.Tutor]}
        />
        <PrivateRoute
          exact
          path="/subscriptions"
          component={Subscriptions}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/typist"
          component={TypistSubscribeHandle}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/correction"
          component={CorrectorSubscribeHandle}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/corrector-accept-papers"
          component={CorrectingPapers}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/profile"
          component={Profile}
          requiredRoles={[ROLE.Teacher, ROLE.Typist, ROLE.Admin, ROLE.Corrector]}
        />
        <PrivateRoute
          exact
          path="/reports"
          component={Reports}
          requiredRoles={[ROLE.Teacher, ROLE.Tutor]}
        />
        <PrivateRoute
          exact
          path="/settlement-details"
          component={SettlementDetails}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/batch-results"
          component={BatchResults}
          requiredRoles={[ROLE.Teacher, ROLE.Tutor]}
        />
        <PrivateRoute
          exact
          path="/teacher-in-review"
          component={TypingReviewRequest}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/typing-rejected"
          component={TypistRejectedRequest}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/typing-papers"
          component={TypingPapers}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/typing-completed"
          component={TypingCompleted}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/typing-pending"
          component={TypingPendingRequest}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/corrector-rejected"
          component={CorrectorRejectedRequest}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/corrector-request-pending-papers"
          component={CorrectingPendingRequest}
          requiredRoles={[ROLE.Teacher]}
        />
        <PrivateRoute
          exact
          path="/typist-teachers-requests"
          component={TypistTeacherRequest}
          requiredRoles={[ROLE.Typist]}
        />
        <PrivateRoute
          exact
          path="/typist-history"
          component={TypistHistory}
          requiredRoles={[ROLE.Typist]}
        />
        <PrivateRoute
          exact
          path="/typist-papers-requests"
          component={TypistPaperRequest}
          requiredRoles={[ROLE.Typist]}
        />
        <PrivateRoute
          exact
          path="/typist-current-work"
          component={TypistCurrentWork}
          requiredRoles={[ROLE.Typist]}
        />
        <PrivateRoute
          exact
          path="/in-review"
          component={PaperReviewRequest}
          requiredRoles={[ROLE.Typist]}
          in-review
        />
        <PrivateRoute
          exact
          path="/corrector-papers-requests"
          component={CorrectorPaperRequest}
          requiredRoles={[ROLE.Corrector]}
        />
        <PrivateRoute
          exact
          path="/corrector-current-work"
          component={CorrectorCurrentWork}
          requiredRoles={[ROLE.Corrector]}
        />
        <PrivateRoute
          path="/annotation/:examId/:attemptId"
          component={AnnotationResult}
          requiredRoles={[ROLE.Corrector]}
        />
        <PrivateRoute
          path="/corrector-history"
          component={CorrectorHistory}
          requiredRoles={[ROLE.Corrector]}
        />
        <PrivateRoute
          path="/corrector-teachers-requests"
          component={CorrectorTeacherRequest}
          requiredRoles={[ROLE.Corrector]}
        />
        <PrivateRoute
          path="/admin-add-subject"
          component={HandleSelector}
          requiredRoles={[ROLE.Admin]}
        />
        <PrivateRoute
          path="/promo-code"
          component={PromoCodeSubscription}
          requiredRoles={[ROLE.Admin]}
        />
        <PrivateRoute
          path="/points-subscription-student"
          component={pointsTopupViaEmail}
          requiredRoles={[ROLE.Admin]}
        />
        <PrivateRoute path="/faq" component={FAQ} requiredRoles={[ROLE.Admin]} />
      </Switch>
    </Router>
  );
}
