import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { pointsTopupViamail } from '../../actions/pointAction';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputLabel,
  OutlinedInput,
  makeStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  right: {
    margin: '4px',
    marginTop: '10px'
  },
  bold: {
    fontWeight: 'bold'
  }
}));
function pointsTopupViaEmail() {
  const [promoAmount, setPromoAmount] = useState(0);
  const [studentEmail, setStudentEmail] = useState('');
  const dispatch = useDispatch();
  const [disable, setdisable] = useState(true);
  const [message, setMessage] = useState({ status: '', msg: '' });
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == 'promoAmount') {
      if (/^\d*$/.test(value)) {
        setPromoAmount(value);
      } else {
        setMessage({
          msg: 'please_numeric_value',
          status: 'error'
        });
        setTimeout(() => {
          setMessage({});
        }, 3000);
      }
    } else if (name == 'studentEmail') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setStudentEmail(value);
      } else {
        setMessage({
          msg: 'please_enter_valid_email',
          status: 'error'
        });
        setTimeout(() => {
          setMessage({});
        }, 3000);
      }
    }
    if (promoAmount != 0 && studentEmail != '') {
      setdisable(false);
    }
  };
  const handlePointTopUp = () => {
    try {
      dispatch(
        pointsTopupViamail(studentEmail, promoAmount, (res) => {
          setMessage({
            msg: res,
            status: 'success'
          });

          setTimeout(() => {
            setMessage({});
          }, 3000);
        })
      );
    } catch {
      console.error('something wrong');
    }
  };
  return (
    <DashboardLayout>
      <Card
        style={{
          border: '1px solid #186CEE',
          margin: '10% 20% 0 20%',
          borderRadius: '15px'
        }}
      >
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <form className={classes.root} noValidate autoComplete="off">
            <h2
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px'
              }}
            >
              {t('email_base_point_topup')}
            </h2>
            <Divider />

            <Grid className={classes.container} container spacing={3}>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>{t('promo_amount')}</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="promoAmount"
                  required
                  onChange={handleChange}
                  placeholder={t('enter_promo_amount')}
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>{t('student_email')}</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="studentEmail"
                  autoComplete="email"
                  required
                  onChange={handleChange}
                  placeholder={t('enter_student_email')}
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
            </Grid>
            {message.msg != '' && (
              <p style={{ color: message.status === 'success' ? 'green' : 'red' }}>
                {t(message.msg)}
              </p>
            )}
            <Grid
              container
              spacing={3}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Grid item xs={6} sm={6}>
                <Button
                  fullWidth
                  onClick={() => handlePointTopUp()}
                  disabled={disable}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #186CEE',
                    padding: '10px',
                    height: '40px',
                    margin: '0 auto',
                    borderRadius: '15px',
                    color: !disable ? 'white' : 'black',
                    backgroundColor: !disable ? '#186CEE' : 'white',
                    cursor: !disable ? 'pointer' : 'not-allowed',
                    textAlign: 'center'
                  }}
                >
                  {t('create')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default pointsTopupViaEmail;
