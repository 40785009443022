import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getDashboardDetails } from '../../actions/userAction';
import Chart from '../../components/Chart/Chart';
import Widget from '../../components/Widget/Widget';
import HistoryWidget from '../../components/Widget/HistoryWidget/HistoryWidget';
import { getExamPaperYearHistory } from '../../actions/examPaperHistoryAction';

const monthsInOrder = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
];
const getAllGraphData = (subscribedPapers = []) => {
  let graphData = [];
  if (subscribedPapers && subscribedPapers.length > 0) {
    subscribedPapers.map((a) => {
      let seriesData = { name: '', data: [] };
      seriesData.name = a.examTitle;
      let monthlyEarnings = a.monthlyEarning;
      const months = Object.keys(monthlyEarnings);
      if (months && months.length > 0) {
        months.sort(function (a, b) {
          return monthsInOrder.indexOf(a) - monthsInOrder.indexOf(b);
        });
        months.forEach((m) => {
          seriesData.data.push({ x: m, y: monthlyEarnings[m] });
        });
      }
      graphData.push(seriesData);
    });
  }
  return graphData;
};

const seriesData = {
  series: [],
  options: {
    chart: {
      type: 'area',
      height: 300,
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },

    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      title: {
        text: 'Months'
      },
      type: 'category'
    },
    yaxis: {
      title: {
        text: 'Amount'
      }
    }
  }
};

class PendingExamPaper extends Component {
  constructor() {
    super();
    this.state = {
      isDataLoaded: false,
      graphData: seriesData,
      local: ''
    };
    this.handleLocalization = this.handleLocalization.bind(this);
  }
  componentDidMount() {
    const { getDashboardDetails, getExamPaperYearHistory, selectedLanguage } = this.props;
    getDashboardDetails();
    getExamPaperYearHistory();
    this.setState({ local: selectedLanguage });
  }

  componentDidUpdate(preProps) {
    const { selectedLanguage, dashboardInfo } = this.props;
    if (preProps.dashboardInfo !== dashboardInfo) {
      const { graphData } = this.state;
      const data = getAllGraphData(dashboardInfo.subscribedPapers);
      graphData.series = data;
      this.setState({ graphData, isDataLoaded: true });
    }
    if (selectedLanguage !== preProps.selectedLanguage) {
      this.setState({ local: selectedLanguage });
    }
  }
  handleLocalization(heading) {
    const { local } = this.state;
    if (heading == 'overall_income') {
      if (local == 'sinhala') {
        return 'සමස්ත ආදායම';
      } else if (local == 'tamil') {
        return 'மொத்த வருமானம்';
      } else {
        return 'Overall Income';
      }
    }
  }
  render() {
    const { dashboardInfo, paperYearStats } = this.props;
    const { isDataLoaded, graphData } = this.state;
    return (
      <Fragment>
        <DashboardLayout>
          <div className="widgets">
            <Widget
              type="subscription"
              amount={dashboardInfo.subscriptions}
              item={{
                id: 70,
                title: 'Subscriptions',
                path: '/subscriptions'
              }}
            />
            <Widget
              type="paper"
              amount={dashboardInfo.publishedExams}
              item={{ id: 22, title: 'Published exam papers', path: '/published-exam-papers' }}
            />
            <Widget
              type="income"
              amount={dashboardInfo.totalEarning}
              item={{ id: 10, title: 'Dashboard', path: '/income' }}
            />
          </div>
          <div className="charts">
            {isDataLoaded ? (
              <Chart
                title={this.handleLocalization('overall_income')}
                options={graphData.options}
                series={graphData.series}
                type="area"
                width={700}
              />
            ) : null}
            <HistoryWidget historyData={paperYearStats} />
          </div>
        </DashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardInfo: state.user.dashboardInfo,
  selectedLanguage: state.language.selectedLanguage,
  paperYearStats: state.paperHistory.paperYearStats
});

const mapDispatchToProps = {
  getDashboardDetails,
  getExamPaperYearHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingExamPaper);
