import React, { useEffect } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamPapers } from '../../actions/examPaperAction';
import keycloakService from '../../keycloakService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

function TypingReviewRequest() {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const dispatch = useDispatch();
  const history = useHistory();
  const pendingExamPapers = useSelector((state) => state.examPaper.pendingExamPapers);
  useEffect(() => {
    const userDetails = keycloakService.getUserDetails();
    dispatch(fetchExamPapers(userDetails.sub, { status: 'PENDING,DRAFT' })); //no point fetchExamPapers take as status=''
  }, []);
  const handleClick = (examId) => {
    history.push(`published-exam-paper/${examId}/view`);
  };
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                {t('typist_requesting')}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table arial-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('typist_name')}</TableCell>
                    <TableCell>{t('subject_name')}</TableCell>
                    <TableCell>{t('subject_title')}</TableCell>
                    <TableCell>{t('typist_status')}</TableCell>
                    <TableCell>{t('action')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingExamPapers.length == 0 ? (
                    <p style={{ color: 'orange' }}>{t('no_typist_completed')}</p>
                  ) : (
                    pendingExamPapers.length >= 1 &&
                    pendingExamPapers.map(
                      (allPapers, index) =>
                        allPapers.typistStatus === 'REVIEW' && (
                          <TableRow key={index}>
                            <TableCell>{allPapers.typistName}</TableCell>
                            <TableCell>{allPapers.subject}</TableCell>
                            <TableCell>{allPapers.title}</TableCell>
                            <TableCell>{allPapers.typistStatus}</TableCell>
                            <TableCell>
                              <Button
                                onClick={() => handleClick(allPapers.id)}
                                color="primary"
                                style={{ backgroundColor: '#7EC8E3' }}
                              >
                                {t('view')}
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                    )
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypingReviewRequest;
