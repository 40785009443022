import React, { useEffect } from 'react';
import './HistoryWidget.css';
import PublishIcon from '@mui/icons-material/Publish';
import image from './chemistry-bg.d15053e8159411c51ddc.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const HistoryItem = ({ title }) => {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <div className="content">
      <div className="left">
        <img src={image} className="image" />
      </div>
      <div className="right">
        <span className="title">{title}</span>
        <div className="action">
          <span>{t('view_report')}</span>
        </div>
        <div className="status">
          <PublishIcon className="icon" />
          <span>{t('published')}</span>
        </div>
      </div>
    </div>
  );
};

const HistoryWidget = ({ historyData }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const handleClick = async (item) => {
    const menu = {
      title: item.title,
      id: item.id
    };
    await localStorage.setItem('menu', JSON.stringify(menu));
    history.push(item.path);
  };
  return (
    <div className="historyWidget">
      <div className="top">
        <h1 className="title">{t('teacher_dash_history')}</h1>
      </div>
      <div>
        {historyData
          .map((row, index) => <HistoryItem title={row.paperTitle} key={index} />)
          .slice(Math.max(historyData.length - 5, 0))}
      </div>
      {historyData && historyData.length >= 1 && (
        <div
          onClick={() =>
            handleClick({ id: 22, title: 'Published exam papers', path: '/published-exam-papers' })
          }
        >
          <a className="link">See More</a>
        </div>
      )}
    </div>
  );
};

export default HistoryWidget;
