import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { hrs, mins, numbersOnly } from '../../../utils/helper';
import Description from '../../ExamPaper/Description';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ExamInstructions = (props) => {
  const {
    handleChange,
    handleDescription,
    handleChangeDuration,
    examPaper,
    errors,
    durationInMins,
    durationInHrs,
    initialValue,
    classes,
    descript
  } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <div>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('no_of_question')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <OutlinedInput
            name="noOfQuestions"
            value={examPaper.noOfQuestions}
            onChange={handleChange}
            placeholder=""
            inputProps={{ 'aria-label': 'no of question' }}
            fullWidth
            error={!!errors.noOfQuestions}
            onKeyPress={numbersOnly}
          />
          <FormHelperText id="title-helper-text">{errors.noOfQuestions || ''}</FormHelperText>
        </Grid>
        {examPaper.category == 'MCQ' && (
          <>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>{t('no_of_answers')}</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <OutlinedInput
                name="noOfAnswers"
                value={examPaper.noOfAnswers}
                onChange={handleChange}
                placeholder=""
                inputProps={{ 'aria-label': 'no of answer' }}
                fullWidth
                error={!!errors.noOfAnswers}
                onKeyPress={numbersOnly}
              />
              <FormHelperText id="title-helper-text">{errors.noOfAnswers || ''}</FormHelperText>
            </Grid>
          </>
        )}
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('duration')} (hh:mm)
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <FormControl
            style={{
              width: '42%',
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px'
            }}
          >
            <Select
              name="durationInHrs"
              value={durationInHrs}
              labelId="select-duration-in-hrs"
              id="select-duration-in-hrs"
              onChange={handleChangeDuration}
              error={!!errors.durationInMinute}
            >
              <MenuItem value={0}>00</MenuItem>
              {hrs.map((info, i) => (
                <MenuItem key={i} value={info}>
                  {info}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span className={classes.right}>:</span>
          <FormControl
            style={{
              width: '42%',
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px'
            }}
          >
            <Select
              name="durationInMins"
              labelId="select-duration-in-minus"
              id="select-duration-in-minus"
              value={durationInMins}
              onChange={handleChangeDuration}
              required
              error={!!errors.durationInMinute}
            >
              <MenuItem value={0}>00</MenuItem>
              {mins.map((info, i) => (
                <MenuItem key={i} value={info}>
                  {info}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText id="title-helper-text">{errors.durationInMinute || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label}>{t('exam_instruction')}</InputLabel>
        </Grid>
        <Description
          input={
            examPaper.description !== '' && typeof examPaper.description == 'string'
              ? JSON.parse(examPaper.description)
              : initialValue
          }
          setInput={(val) => handleDescription(descript, val)}
        />
        <FormHelperText id="title-helper-text">{errors.description || ''}</FormHelperText>
      </Grid>
    </div>
  );
};

export default ExamInstructions;
