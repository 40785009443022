import React, { Component, Fragment } from 'react';
import FaqHandler from '../../components/Faq/FaqHandler';
import DashboardLayout from '../../layouts/DashboardLayout';
import { connect } from 'react-redux';
import { fetchAvailableAllFaq, deleteFaq, updateFaq, patchFaq } from '../../actions/faqAction';
import FaqAdding from '../../components/Faq/FaqAdding';

const questionInitialBlock = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableFAQ: [],
      selectedFaqId: '',
      selectedFaqQuestion: { question: '', questionTa: '', questionSi: '' },
      selectedFaqAnswer: { answer: '', answerTa: '', answerSi: '' },
      newAnswer: questionInitialBlock,
      newQuestion: '',
      newQuestionAnswer: {
        question: '',
        answer: questionInitialBlock,
        questionTa: '',
        answerTa: questionInitialBlock,
        questionSi: '',
        answerSi: questionInitialBlock
      }
    };
    this.handleFaqChange = this.handleFaqChange.bind(this);
    this.handleEditSetData = this.handleEditSetData.bind(this);
    this.handleFaqQuesChange = this.handleFaqQuesChange.bind(this);
    this.handleDeleteFaq = this.handleDeleteFaq.bind(this);
    this.handleUpdateChanges = this.handleUpdateChanges.bind(this);
    this.addNewAnswer = this.addNewAnswer.bind(this);
    this.addNewQuestion = this.addNewQuestion.bind(this);
    this.handleAddQuestionAnswer = this.handleAddQuestionAnswer.bind(this);
    this.setHandleButtons = this.setHandleButtons.bind(this);
  }
  componentDidMount() {
    const { fetchAvailableAllFaq } = this.props;
    fetchAvailableAllFaq();
  }
  componentDidUpdate(preProps) {
    const { availableFaq } = this.props;
    if (preProps.availableFaq != availableFaq) {
      console.log('inside the did update');
      this.setState({ availableFAQ: availableFaq });
    }
  }
  handleFaqChange(val, lang) {
    const changedFaq = JSON.stringify(val);
    if (lang == 'answer') {
      this.setState((prevState) => ({
        selectedFaqAnswer: {
          ...prevState.selectedFaqAnswer,
          answer: changedFaq
        }
      }));
    } else if (lang == 'answerTa') {
      this.setState((prevState) => ({
        selectedFaqAnswer: {
          ...prevState.selectedFaqAnswer,
          answerTa: changedFaq
        }
      }));
    } else if (lang == 'answerSi') {
      this.setState((prevState) => ({
        selectedFaqAnswer: {
          ...prevState.selectedFaqAnswer,
          answerSi: changedFaq
        }
      }));
    }
  }
  handleEditSetData(faq) {
    console.log('faq', faq);
    this.setState({
      selectedFaqId: faq.id,
      selectedFaqQuestion: {
        question: faq.question,
        questionTa: faq.questionTa,
        questionSi: faq.questionSi
      },
      selectedFaqAnswer: {
        answer: faq.answer,
        answerTa: faq.answerTa,
        answerSi: faq.answerSi
      }
    });
  }
  handleFaqQuesChange(e) {
    const { value, name } = e.target;
    if (name === 'question') {
      this.setState((prevState) => ({
        selectedFaqQuestion: {
          ...prevState.selectedFaqQuestion,
          question: value
        }
      }));
    } else if (name === 'questionTa') {
      this.setState((prevState) => ({
        selectedFaqQuestion: {
          ...prevState.selectedFaqQuestion,
          questionTa: value
        }
      }));
    } else if (name === 'questionSi') {
      this.setState((prevState) => ({
        selectedFaqQuestion: {
          ...prevState.selectedFaqQuestion,
          questionSi: value
        }
      }));
    }
  }
  handleDeleteFaq(id) {
    const { deleteFaq } = this.props;
    const { fetchAvailableAllFaq } = this.props;
    deleteFaq(id, () => fetchAvailableAllFaq());
  }
  handleUpdateChanges() {
    const { updateFaq, fetchAvailableAllFaq } = this.props;
    const { selectedFaqId, selectedFaqQuestion, selectedFaqAnswer } = this.state;
    const faq = {
      id: selectedFaqId,
      question: selectedFaqQuestion.question,
      answer: selectedFaqAnswer.answer,
      questionTa: selectedFaqQuestion.questionTa,
      answerTa: selectedFaqAnswer.answerTa,
      questionSi: selectedFaqQuestion.questionSi,
      answerSi: selectedFaqAnswer.answerSi
    };
    updateFaq(faq, selectedFaqId, () => fetchAvailableAllFaq());
    this.setState({
      selectedFaqId: '',
      selectedFaqQuestion: { question: '', questionTa: '', questionSi: '' },
      selectedFaqAnswer: { answer: '', answerTa: '', answerSi: '' }
    });
  }
  addNewAnswer(val, lang) {
    if (lang == 'answer') {
      this.setState((preValue) => ({
        newQuestionAnswer: {
          ...preValue.newQuestionAnswer,
          answer: val
        }
      }));
    } else if (lang == 'answerTa') {
      this.setState((preValue) => ({
        newQuestionAnswer: {
          ...preValue.newQuestionAnswer,
          answerTa: val
        }
      }));
    } else if (lang == 'answerSi') {
      this.setState((preValue) => ({
        newQuestionAnswer: {
          ...preValue.newQuestionAnswer,
          answerSi: val
        }
      }));
    }
  }
  addNewQuestion(e) {
    const { value, name } = e.target;
    if (name == 'question') {
      this.setState((preValue) => ({
        newQuestionAnswer: {
          ...preValue.newQuestionAnswer,
          question: value
        }
      }));
    } else if (name == 'questionTa') {
      this.setState((preValue) => ({
        newQuestionAnswer: {
          ...preValue.newQuestionAnswer,
          questionTa: value
        }
      }));
    } else if (name == 'questionSi') {
      this.setState((preValue) => ({
        newQuestionAnswer: {
          ...preValue.newQuestionAnswer,
          questionSi: value
        }
      }));
    }
  }
  handleAddQuestionAnswer() {
    const { patchFaq, fetchAvailableAllFaq } = this.props;
    const { newQuestionAnswer } = this.state;
    const faq = {
      question: newQuestionAnswer.question,
      answer: JSON.stringify(newQuestionAnswer.answer),
      questionTa: newQuestionAnswer.questionTa,
      answerTa: JSON.stringify(newQuestionAnswer.answerTa),
      questionSi: newQuestionAnswer.questionSi,
      answerSi: JSON.stringify(newQuestionAnswer.answerSi)
    };

    patchFaq(faq, () => {
      this.setState(
        {
          newQuestionAnswer: {
            question: '',
            answer: questionInitialBlock,
            questionTa: '',
            answerTa: questionInitialBlock,
            questionSi: '',
            answerSi: questionInitialBlock
          }
        },
        () => fetchAvailableAllFaq()
      );
    });
  }
  setHandleButtons() {
    this.setState({ selectedFaqId: '' });
  }
  render() {
    const {
      availableFAQ,
      selectedFaqId,
      selectedFaqQuestion,
      selectedFaqAnswer,
      newQuestionAnswer
    } = this.state;
    console.log('availableFAQ', availableFAQ);
    return (
      <Fragment>
        <DashboardLayout>
          <FaqAdding
            newQuestionAnswer={newQuestionAnswer}
            addNewAnswer={this.addNewAnswer}
            addNewQuestion={this.addNewQuestion}
            handleAddQuestionAnswer={this.handleAddQuestionAnswer}
          />
          <FaqHandler
            faqDetail={availableFAQ}
            selectedFaqId={selectedFaqId}
            selectedFaqQuestion={selectedFaqQuestion}
            selectedFaqAnswer={selectedFaqAnswer}
            handleFaqChange={this.handleFaqChange}
            handleEditSetData={this.handleEditSetData}
            handleFaqQuesChange={this.handleFaqQuesChange}
            handleDeleteFaq={this.handleDeleteFaq}
            handleUpdateChanges={this.handleUpdateChanges}
            setHandleButtonsDrop={this.setHandleButtons}
          />
        </DashboardLayout>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  availableFaq: state.faq.availableFAQ
});
const mapDispatchToProps = {
  fetchAvailableAllFaq,
  deleteFaq,
  updateFaq,
  patchFaq
};
export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
