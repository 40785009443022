import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Science from './Images/chemistry-bg.d15053e8159411c51ddc.png';
import './publishExamPaper.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function ResultsCard({ publishedExamPapers }) {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <Grid container spacing={3}>
      {publishedExamPapers.map((publishedExamPaper) => (
        <Grid item xs={12} sm={12} key={publishedExamPaper.id}>
          <Card className="paper-card">
            <CardMedia
              image={Science}
              title="Live from space album cover"
              className="pcard-image"
            />
            <CardContent className="card-content">
              <Typography variant="h6" gutterBottom>
                {publishedExamPaper.title || ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {t('subject')} - {publishedExamPaper.subject || ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {t('category')} - {publishedExamPaper.category || ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {t('grade')} - {publishedExamPaper.grade || ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {t('date')} - {moment(publishedExamPaper.scheduledTime).format('DD/MM/YYYY')}
              </Typography>
            </CardContent>
            <CardActions className="card-action">
              <div
                style={{
                  border: '1px solid #186CEE',
                  padding: '5px 20px',
                  marginBottom: '5px',
                  borderRadius: '10px'
                }}
              >
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'inherit'
                  }}
                  className="button"
                  href={`published-exam-paper/${publishedExamPaper.id}/view`}
                >
                  {t('view')}
                </a>
              </div>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
