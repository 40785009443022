import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import NameSelector from '../Selector/NameSelector';
import { uploadFiles } from '../../actions/examPaperAction';
import Compressor from 'compressorjs';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function Addselector(props) {
  const {
    Language,
    Subject,
    Grade,
    handleChangeClick,
    handleSubjectChange,
    addSubject,
    handleTeacherChange,
    addhandleTeacher,
    teachers,
    selectedTeacherId,
    selector,
    newSubject
  } = props;
  const dispatch = useDispatch();
  const [image, setImage] = useState('');
  const [msg, setMsg] = useState(null);
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const handleCompressImage = (e) => {
    const file = e.target.files;
    const compressPromises = [];

    if (file[0] && file[0].size > 1024 * 1024 * 2) {
      setMsg('please_upload_less_than_2MB');
    }
    setMsg('uploading');

    const compressPromise = new Promise((resolve, reject) => {
      new Compressor(file[0], {
        quality: 0.8,
        success(result) {
          const formData = new FormData();
          formData.append('file', result);
          dispatch(
            uploadFiles(formData, (url) => {
              setImage(url);
              resolve();
            })
          );
        },
        error(err) {
          console.error(err.message);
          reject(err);
        }
      });
    });

    compressPromises.push(compressPromise);
    Promise.all(compressPromises)
      .then(() => {
        console.log('All files processed and dispatched successfully');
        setMsg('please_Click_upload_button');
      })
      .catch((error) => {
        console.error('Error processing files:', error);
      });
  };
  const addhandleSubject = () => {
    addSubject(image);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px'
      }}
    >
      <div
        style={{
          width: '60%',
          border: '1px solid #186CEE',
          borderRadius: '15px',
          padding: '30px',
          marginBottom: '20px',
          textAlign: 'center'
        }}
      >
        <h1 style={{ marginBottom: '20px' }}>{t('add_subject')}</h1>

        <div style={{ marginBottom: '20px' }}>
          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="language"
              onChange={handleChangeClick}
              options={Language}
              placeHolderText={t('select_language')}
            />
          </FormControl>

          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="grade"
              onChange={handleChangeClick}
              options={Grade}
              placeHolderText={t('select_grade')}
            />
          </FormControl>

          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <InputLabel>{t('select_subject')}</InputLabel>
            <OutlinedInput
              name="subject"
              onChange={handleSubjectChange}
              placeholder={t('select_subject')}
              inputProps={{ 'aria-label': 'title' }}
            />
          </FormControl>
        </div>

        <div
          style={{
            border: '1px solid #186CEE',
            padding: '15px',
            borderRadius: '15px',
            marginBottom: '20px',
            textAlign: 'center'
          }}
        >
          {msg && <span>{msg}</span>}
          <input
            type="file"
            id="fileInput"
            onChange={handleCompressImage}
            accept="image/*"
            style={{ display: 'none' }}
          />
          <label
            htmlFor="fileInput"
            style={{
              cursor: 'pointer',
              display: 'inline-block',
              border: '1px solid #186CEE',
              padding: '10px 20px',
              borderRadius: '15px',
              marginTop: '10px',
              backgroundColor: '#f0f0f0'
            }}
          >
            {t('choose_image')}
          </label>
        </div>

        <div
          style={{
            border: '1px solid #186CEE',
            borderRadius: '10px',
            padding: '15px',
            marginBottom: '20px'
          }}
        >
          {image ? (
            <img
              src={image}
              alt="Preview"
              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
            />
          ) : (
            <p>{t('image_not')}</p>
          )}
        </div>

        <Button
          onClick={addhandleSubject}
          disabled={
            selector.language === '' || selector.grade === '' || newSubject === '' || image === ''
          }
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '15px',
            color:
              selector.language !== '' && selector.grade !== '' && newSubject !== '' && image !== ''
                ? 'white'
                : 'black',
            backgroundColor:
              selector.language !== '' && selector.grade !== '' && newSubject !== '' && image !== ''
                ? '#186CEE'
                : '#f0f0f0',
            cursor:
              selector.language !== '' && selector.grade !== '' && newSubject !== '' && image !== ''
                ? 'pointer'
                : 'not-allowed',
            fontWeight: 'bold'
          }}
        >
          {t('add_subject_btn')}
        </Button>
      </div>

      <div
        style={{
          width: '60%',
          border: '1px solid #186CEE',
          borderRadius: '15px',
          padding: '30px',
          textAlign: 'center'
        }}
      >
        <h1 style={{ marginBottom: '20px' }}>{t('add_teacher')}</h1>

        <div
          style={{
            display: 'block',
            justifyContent: 'space-between',
            gap: '15px',
            marginBottom: '20px'
          }}
        >
          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="language"
              onChange={handleChangeClick}
              options={Language}
              placeHolderText={t('select_language')}
            />
          </FormControl>

          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="grade"
              onChange={handleChangeClick}
              options={Grade}
              placeHolderText={t('select_grade')}
            />
          </FormControl>

          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="subject"
              onChange={handleChangeClick}
              options={Subject}
              placeHolderText={t('select_subject')}
            />
          </FormControl>

          <Autocomplete
            id="teacher-id"
            name="teacher"
            options={teachers}
            getOptionLabel={(option) => option.fullName}
            style={{ flex: '2' }}
            renderInput={(params) => (
              <TextField {...params} label={t('select_teacher')} variant="outlined" />
            )}
            onChange={handleTeacherChange}
            value={teachers.find((teacher) => teacher.id === selectedTeacherId)}
            key={selectedTeacherId}
          />
        </div>

        <Button
          onClick={addhandleTeacher}
          disabled={
            selector.language === '' ||
            selector.grade === '' ||
            selector.subject === '' ||
            !selectedTeacherId
          }
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '15px',
            color:
              selector.language !== '' &&
              selector.grade !== '' &&
              selector.subject !== '' &&
              selectedTeacherId
                ? 'white'
                : 'black',
            backgroundColor:
              selector.language !== '' &&
              selector.grade !== '' &&
              selector.subject !== '' &&
              selectedTeacherId
                ? '#186CEE'
                : '#f0f0f0',
            cursor:
              selector.language !== '' &&
              selector.grade !== '' &&
              selector.subject !== '' &&
              selectedTeacherId
                ? 'pointer'
                : 'not-allowed',
            fontWeight: 'bold'
          }}
        >
          {t('add_teacher_btn')}
        </Button>
      </div>
    </div>
  );
}

export default Addselector;
