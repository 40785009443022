import React, { useEffect } from 'react';
import './SideBar.css';
import TeacherMenu from './TeacherMenu.json';
import AdminMenu from './AdminMenu.json';
import TypistMenu from './TypistMenu.json';
import CorrectorMenu from './CorrectorMenu.json';
import TutorMenu from './TutorMenu.json';
import SidebarItem from './SidebarItem.jsx';
import logo from './images/logo.png';
import keycloakService from '../../keycloakService';
import { ROLE } from '../../utils/helper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SideBar = () => {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const hasTeacherRole = keycloakService.hasRole([ROLE.Teacher]);
  const hasTypistRole = keycloakService.hasRole([ROLE.Typist]);
  const hasCorrectorRole = keycloakService.hasRole([ROLE.Corrector]);
  const hasTutorRole = keycloakService.hasRole([ROLE.Tutor]);
  const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);

  const getPortalTitleForLoggedInUser = () => {
    if (hasAdminRole) {
      return 'header_logoAdmin';
    } else if (hasTeacherRole) {
      return 'header_logoTeacher';
    } else if (hasTutorRole) {
      return 'header_logoTutor';
    } else if (hasTypistRole) {
      return 'header_logoTypist';
    } else if (hasCorrectorRole) {
      return 'header_logoCorrector';
    } else {
      return 'header_logoTeacher';
    }
  };

  return (
    <div className="sidebar">
      <div className="top">
        <img src={logo} className="logo" />
        <span className="title">{t(getPortalTitleForLoggedInUser())}</span>
      </div>
      <hr></hr>
      <div className="center">
        {hasTeacherRole &&
          TeacherMenu.map((item, index) => <SidebarItem key={index} item={item} />)}
        {hasTypistRole && TypistMenu.map((item, index) => <SidebarItem key={index} item={item} />)}
        {hasCorrectorRole &&
          CorrectorMenu.map((item, index) => <SidebarItem key={index} item={item} />)}
        {hasAdminRole && AdminMenu.map((item, index) => <SidebarItem key={index} item={item} />)}
        {hasTutorRole && TutorMenu.map((item, index) => <SidebarItem key={index} item={item} />)}
      </div>
    </div>
  );
};

export default SideBar;
