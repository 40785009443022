import React, { useCallback, useMemo, useRef, useState } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate, useSlate } from 'slate-react';
import { createEditor, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';

import Box from '@material-ui/core/Box';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import CodeIcon from '@material-ui/icons/Code';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ImageIcon from '@material-ui/icons/Image';
import Divider from '@material-ui/core/Divider';
import { useDispatch } from 'react-redux';
import Compressor from 'compressorjs';
import { uploadFiles, deleteFile } from '../../actions/examPaperAction';
import Toast from '../Toast';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import Latex from 'react-latex-next';
import LaTexRichTextEditor from '../Modal/LaTexRichTextEditor';

const SubScript = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    width="24"
    height="24"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M16 7.41L11.41 12L16 16.59L14.59 18L10 13.41L5.41 18L4 16.59L8.59 12L4 7.41L5.41 6L10 10.59L14.59 6L16 7.41m5.85 13.62h-4.88v-1l.89-.8c.76-.65 1.32-1.19 1.7-1.63c.37-.44.56-.85.57-1.24a.898.898 0 0 0-.27-.7c-.18-.16-.47-.28-.86-.28c-.31 0-.58.06-.84.18l-.66.38l-.45-1.17c.27-.21.59-.39.98-.53s.82-.24 1.29-.24c.78.04 1.38.25 1.78.66c.4.41.62.93.62 1.57c-.01.56-.19 1.08-.54 1.55c-.34.47-.76.92-1.27 1.36l-.64.52v.02h2.58v1.35z"
      fill="currentColor"
    />
  </svg>
);

const SuperScript = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    width="24"
    height="24"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M16 7.41L11.41 12L16 16.59L14.59 18L10 13.41L5.41 18L4 16.59L8.59 12L4 7.41L5.41 6L10 10.59L14.59 6L16 7.41M21.85 9h-4.88V8l.89-.82c.76-.64 1.32-1.18 1.7-1.63c.37-.44.56-.85.57-1.23a.884.884 0 0 0-.27-.7c-.18-.19-.47-.28-.86-.29c-.31.01-.58.07-.84.17l-.66.39l-.45-1.17c.27-.22.59-.39.98-.53S18.85 2 19.32 2c.78 0 1.38.2 1.78.61c.4.39.62.93.62 1.57c-.01.56-.19 1.08-.54 1.55c-.34.48-.76.93-1.27 1.36l-.64.52v.02h2.58V9z"
      fill="currentColor"
    />
  </svg>
);

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code'
};

const insertImageNode = (editor, url) => {
  if (!url) return;
  const image = [{ type: 'image', src: url, alt: 'image', children: [{ text: '' }] }];
  Transforms.insertNodes(editor, image, { select: true });
};
const RichEditor = ({ value, setValue, borderColor, borderRadius }) => {
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState('');
  const [errorToastOpen, setErrorToastOpen] = useState(false);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const inputFile = useRef(null);
  const [showLaTex, setShowLaTex] = useState(false);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 1024 * 1024 * 2) {
      setErrMsg('Please upload less than 2MB size file');
      setErrorToastOpen(true);
      e.target.value = '';
    }
    new Compressor(file, {
      quality: 0.8,
      success(result) {
        const formData = new FormData();
        formData.append('file', result);
        dispatch(
          uploadFiles(formData, (url) => {
            insertImageNode(editor, url);
            e.target.value = '';
          })
        );
      },
      error(err) {
        console.log(err.message);
      }
    });
  };

  const handleErrorToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorToastOpen(false);
    setErrMsg('');
  };
  const handleAddFormula = (tex) => {
    if (!tex) return;
    const formula = [{ type: 'formula', forTex: '$$' + tex + '$$', children: [{ text: '' }] }];
    Transforms.insertNodes(editor, formula, { select: true });
    setShowLaTex(!showLaTex);
  };
  return (
    <div>
      <Box
        p={1}
        m={2}
        border={1}
        borderColor={borderColor != null ? borderColor : 'grey.500'}
        borderRadius={borderRadius !== null ? borderRadius : 4}
      >
        <Slate
          editor={editor}
          value={value}
          onChange={(value) => {
            setValue(value);
          }}
        >
          <Toolbar>
            <MarkButton format="bold">
              <FormatBoldIcon />
            </MarkButton>
            <MarkButton format="italic">
              <FormatItalicIcon />
            </MarkButton>
            <MarkButton format="underline">
              <FormatUnderlinedIcon />
            </MarkButton>
            <MarkButton format="code">
              <CodeIcon />
            </MarkButton>
            <MarkButton format="subscript">
              <SubScript />
            </MarkButton>
            <MarkButton format="superscript">
              <SuperScript />
            </MarkButton>
            <MarkButton format="formula">
              <EmojiSymbolsIcon onClick={() => setShowLaTex(!showLaTex)} />
            </MarkButton>
            <BlockButton format="heading-one">
              <LooksOneIcon />
            </BlockButton>
            <BlockButton format="heading-two">
              <LooksTwoIcon />
            </BlockButton>
            <BlockButton format="block-quote">
              <FormatQuoteIcon />
            </BlockButton>
            <BlockButton format="numbered-list">
              <FormatListNumberedIcon />
            </BlockButton>
            <BlockButton format="bulleted-list">
              <FormatListBulletedIcon />
            </BlockButton>
            {InsertImageButton(inputFile)}
          </Toolbar>
          <Box pl={1}>
            <input
              type="file"
              id="file"
              ref={inputFile}
              accept="image/*"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder="Enter you question here…"
              spellCheck
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
                if (event.key === 'Backspace') {
                  const { selection } = editor;
                  if (selection && selection.anchor) {
                    const selectedImage = editor.children[selection.anchor.path[0]];
                    if (selectedImage && selectedImage.src) {
                      dispatch(deleteFile(selectedImage.src));
                    }
                  }
                }
              }}
            />
          </Box>
        </Slate>
        <Toast
          type="error"
          open={errorToastOpen}
          msg={errMsg}
          handleClose={handleErrorToastClose}
        />
      </Box>
      {showLaTex && (
        <LaTexRichTextEditor
          open={showLaTex}
          setShowLaTex={setShowLaTex}
          handleAddFormula={handleAddFormula}
        />
      )}
    </div>
  );
};

export const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'image':
      return <Image {...props} />;
    case 'formula':
      return <RichLatex {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Leaf = ({ attributes, children, leaf }) => {
  console.log('leaf.text', leaf.text);
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }

  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }
  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <Box ml={1} mt={1}>
      <ToggleButton
        value={format}
        selected={isBlockActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
        style={{ lineHeight: 1 }}
      >
        {children}
      </ToggleButton>
    </Box>
  );
};

const InsertImageButton = (ref) => {
  return (
    <Box ml={1} mt={1}>
      <ToggleButton
        style={{ lineHeight: 1 }}
        onMouseDown={(event) => {
          event.preventDefault();
          ref.current.click();
        }}
      >
        <ImageIcon />
      </ToggleButton>
    </Box>
  );
};

const MarkButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <Box ml={1} mt={1}>
      <ToggleButton
        value={format}
        selected={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        style={{ lineHeight: 1 }}
      >
        {children}
      </ToggleButton>
    </Box>
  );
};

// eslint-disable-next-line no-unused-vars
const Menu = React.forwardRef(({ children, ...props }, ref) => (
  <>
    <Box display="flex" direction="row" justify="flex-start" alignItems="center" flexWrap="wrap">
      {children}
    </Box>
    <Box pt={2}>
      <Divider variant="middle" />
    </Box>
  </>
));

// eslint-disable-next-line no-unused-vars
const Toolbar = React.forwardRef(({ className, ...props }, ref) => <Menu {...props} ref={ref} />);

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format
  });
  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const Image = ({ attributes, element, children }) => {
  return (
    <div {...attributes}>
      <div style={{ userSelect: 'none' }} contentEditable={false}>
        <img alt={element.alt} src={element.src} style={{ size: '80%' }} />
      </div>
      {children}
    </div>
  );
};
const RichLatex = ({ attributes, element, children }) => {
  return (
    <div {...attributes}>
      <div style={{ userSelect: 'none' }} contentEditable={false}>
        <Latex>{element.forTex}</Latex>
      </div>
      {children}
    </div>
  );
};
export default RichEditor;
