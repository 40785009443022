import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import './header.css';
import keycloakService from '../../keycloakService';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../../actions/localizationAction';
import UserImage from './images/user-image-default.png';
import DropDownTriangle from './images/drop-down-triangle.png';
import English from './images/us.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { fetchProfileDetail } from '../../actions/userAction';

const TeacherHeader = () => {
  const isLoggedIn = keycloakService.isLoggedIn();
  const userName = keycloakService.getUserName();
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  const profile = useSelector((state) => state.user.userProfile);
  let location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfileDetail({}));
  }, []);
  const handleLogin = (e) => {
    e.preventDefault();
    keycloakService.login();
  };

  const handleLogout = (e) => {
    e.preventDefault();
    keycloakService.logout();
  };

  const handleLanguageChange = (e) => {
    localStorage.setItem('language', e.target.value);
    dispatch(setLanguage(e.target.value));
    i18n.changeLanguage(e.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return (
    <div className="header-top">
      <header className="site-teacher-header">
        <div className="dashboard-wrapper site-header__wrapper">
          <div className="site-header__start">
            <h1 className="title">{location.pathname.split('/')[1]}</h1>
          </div>
          <div className="site-header__end">
            <ul className="nav__wrapper">
              <li className="auth__item d-none" style={{ listStyle: 'none' }}></li>
              <li className="auth__item language-select" style={{ listStyle: 'none' }}>
                <span className="" href="/">
                  <img src={English} className="" />
                  <span className="t1">
                    <select
                      className="lang-select"
                      name="languages"
                      value={language}
                      onChange={handleLanguageChange}
                    >
                      <option value="en">English</option>
                      <option value="tamil">தமிழ்</option>
                      <option value="sinhala">සිංහල</option>
                    </select>
                  </span>
                </span>
              </li>
              <li className="auth__item help-icon" style={{ listStyle: 'none' }}>
                <a className="icon" href="/">
                  <HelpOutlineOutlinedIcon />
                </a>
              </li>
              {isLoggedIn ? (
                <li className="auth__item bdr-left" style={{ listStyle: 'none' }}>
                  <div className="dropdown">
                    <button
                      className="dropbtn"
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                    >
                      <span className="header-profile-user">
                        <img
                          src={profile?.profileImage ? profile.profileImage : UserImage}
                          className="round-user-image"
                        />
                        <span className="user-available-dot"></span>
                        <span className="text-start">
                          {userName} <img src={DropDownTriangle} />
                        </span>
                      </span>
                    </button>
                  </div>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem>
                                <Link to={'/profile'} underline="none">
                                  Profile
                                </Link>
                              </MenuItem>
                              <div style={{ marginLeft: '15px' }}>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                              </div>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </li>
              ) : (
                <li className="auth__item">
                  <a onClick={handleLogin} className="button" href="/#">
                    {t('header_login_btn_text')}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
};

export default TeacherHeader;
