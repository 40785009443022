import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import {
  getExamPaperYearHistory,
  getExamPaperMonthsHistory
} from '../../actions/examPaperHistoryAction';
import DashboardLayout from '../../layouts/DashboardLayout';
import YearBreakDown from '../../components/PaperHistory/yearBreakDown';
import MonthsBreakDown from '../../components/PaperHistory/MonthBreakDown';

class PendingExamPaper extends Component {
  constructor() {
    super();
    this.state = {
      openYearBreakDown: false
    };
    this.handleYearCountClick = this.handleYearCountClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const { getExamPaperYearHistory } = this.props;
    getExamPaperYearHistory();
  }

  handleYearCountClick(paperId, year) {
    const { getExamPaperMonthsHistory } = this.props;
    this.setState({ openYearBreakDown: true }, () => {
      getExamPaperMonthsHistory({
        examId: paperId,
        year
      });
    });
  }

  handleBack() {
    this.setState({ openYearBreakDown: false });
  }

  render() {
    const { openYearBreakDown } = this.state;
    const { paperYearStats, paperMonthStats } = this.props;
    return (
      <Fragment>
        <DashboardLayout>
          <Container>
            {openYearBreakDown ? (
              <MonthsBreakDown historyYearData={paperMonthStats} handleBack={this.handleBack} />
            ) : (
              <YearBreakDown
                historyData={paperYearStats}
                yearCountClick={this.handleYearCountClick}
              />
            )}
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  paperYearStats: state.paperHistory.paperYearStats,
  paperMonthStats: state.paperHistory.paperMonthStats
});

const mapDispatchToProps = {
  getExamPaperMonthsHistory,
  getExamPaperYearHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingExamPaper);
