import Latex from 'react-latex-next';
import React, { useState, useRef, memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Close } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const buttonStyle = {
  padding: '8px 12px',
  borderRadius: '8px',
  margin: '5px',
  background: '#f0f0f0',
  border: '1px solid #ccc',
  cursor: 'pointer'
};

const inputStyle = {
  padding: '10px',
  width: '90%',
  maxWidth: '500px',
  fontSize: '16px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  boxSizing: 'border-box'
};
function LaTexRichTextEditor(props) {
  const { open, setShowLaTex, handleAddFormula } = props;
  const history = useHistory();
  const [formula, setFormula] = useState('');
  const inputRef = useRef(null); // Create a ref to access the input element
  const frac = `$\\frac{x}{y}$`;
  const sub = '$X_k$';
  const sup = '$X^k$';
  const sum = '$\\sum$';
  const pi = '$\\pi$';
  const int = '$\\int$';
  const temp = '$$X_k=\\frac{1}{N}\\sum_{n=0}^{N-1}x_k e^{i2\\pi k\\frac{n}{N}}$$';
  const tempFormat = 'X_k=\\frac{1}{N}\\sum_{n=0}^{N-1}x_k e^{i2\\pi k\\frac{n}{N}}';

  const handleChange = (e) => {
    if (e.target.value !== '' && !e.target.matches(':focus-visible')) {
      return;
    }

    setFormula(e.target.value);
  };

  const handleKeyboardInput = (e) => {
    if (inputRef.current && inputRef.current.matches(':focus-visible')) {
      setFormula(e.target.value);
    }
  };
  const handleClick = (btnType) => {
    if (btnType === 'sub') {
      setFormula(formula + '_');
    }
    if (btnType === 'frac') {
      setFormula(formula + '\\frac');
    }
    if (btnType === 'sum') {
      setFormula(formula + '\\sum');
    }
    if (btnType === 'sup') {
      setFormula(formula + '^');
    }
    if (btnType === 'pi') {
      setFormula(formula + '\\pi');
    }
    if (btnType === 'int') {
      setFormula(formula + '\\int');
    }
  };
  const handleLearnLaText = () => {
    history.push('/learn-latext');
  };
  return (
    <div>
      <Dialog open={open} aria-labelledby="responsive-dialog-title" maxWidth="sm" fullWidth>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
            <Close
              onClick={() => setShowLaTex(!open)}
              style={{ cursor: 'pointer', color: '#555' }}
            />
          </div>
          <DialogContentText>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                gap: '10px'
              }}
            >
              <div style={{ border: '1px solid #186CEE', padding: '10px', borderRadius: '10px' }}>
                <label>This Small Example you can use to understand latex</label>
                <Latex>{temp}</Latex>
                <p>{tempFormat}</p>
                <label>try to bring same formula clicking suitable buttons</label>
              </div>
              <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                <button onClick={() => handleClick('frac')} style={buttonStyle}>
                  <Latex>{frac}</Latex>
                </button>
                <button onClick={() => handleClick('sub')} style={buttonStyle}>
                  <Latex>{sub}</Latex>
                </button>
                <button onClick={() => handleClick('sup')} style={buttonStyle}>
                  <Latex>{sup}</Latex>
                </button>
                <button onClick={() => handleClick('sum')} style={buttonStyle}>
                  <Latex>{sum}</Latex>
                </button>
                <button onClick={() => handleClick('pi')} style={buttonStyle}>
                  <Latex>{pi}</Latex>
                </button>
                <button onClick={() => handleClick('int')} style={buttonStyle}>
                  <Latex>{int}</Latex>
                </button>
              </div>

              <div style={{ textAlign: 'center', width: '100%' }}>
                <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Input your Formula</p>
                <input
                  type="text"
                  style={inputStyle}
                  value={formula}
                  onChange={handleChange}
                  onInput={handleKeyboardInput}
                  ref={inputRef}
                />
              </div>

              <h1 style={{ textAlign: 'center', fontSize: '24px', marginTop: '20px' }}>
                <Latex>{`$$${formula}$$`}</Latex>
              </h1>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleLearnLaText} style={{ border: '1px solid #186CEE' }}>
            Lear to add Formula
          </Button>
          <Button onClick={() => handleAddFormula(formula)} color="primary" variant="contained">
            Add Formula
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(LaTexRichTextEditor);
