import { useState, useEffect } from 'react';
import Icon from '@mui/material/Icon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './SidebarItem.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function SidebarItem({ item }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const handleClickMenu = async (item) => {
    const menu = {
      title: item.title,
      id: item.id
    };
    await localStorage.setItem('menu', JSON.stringify(menu));
    history.push(item.path);
  };
  const menuIdentification = (item) => {
    const storedItem = localStorage.getItem('menu') ? localStorage.getItem('menu') : null;
    const id = item.id;
    const firstDigit = parseInt(id.toString()[0], 10);
    if (storedItem != null && firstDigit == parseInt(JSON.parse(storedItem).id?.toString()[0], 10))
      return true;
  };
  const subMenuIdentification = (item) => {
    const storedItem = localStorage.getItem('menu') ? localStorage.getItem('menu') : null;
    const id = item.id;
    const firstDigit = parseInt(id.toString()[0], 10);
    const secondDigit = parseInt(id.toString()[1], 10);
    if (
      storedItem != null &&
      secondDigit == parseInt(JSON.parse(storedItem).id?.toString()[1], 10) &&
      firstDigit == parseInt(JSON.parse(storedItem).id?.toString()[0], 10)
    )
      return true;
  };
  if (item.children) {
    return (
      <div className={open || menuIdentification(item) ? 'sidebar-item open' : 'sidebar-item'}>
        <div className="sidebar-title" onClick={() => setOpen(!open)}>
          <Icon className="icon">{item.icon}</Icon>
          <span>{t(item.localization)}</span>
          {open ? <ArrowDropUpIcon className="icon" /> : <ArrowDropDownIcon className="icon" />}
        </div>
        <div className="sidebar-content">
          {item.children.map((child, index) => (
            <SidebarItem key={index} item={child} />
          ))}
        </div>
      </div>
    );
  } else {
    const menuBoolean = subMenuIdentification(item);
    return (
      <div
        className={menuBoolean ? 'sidebar-subitem-clicked' : 'sidebar-subitem'}
        onClick={() => handleClickMenu(item)}
      >
        <Icon className={menuBoolean ? 'icon-clicked' : 'icon'}>{item.icon}</Icon>
        <a href={item.path} className={menuBoolean ? 'title-clicked' : 'title'}>
          {t(item.localization)}
        </a>
        <div className="icon"></div>
      </div>
    );
  }
}
