import React, { Component, createRef } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DashboardLayout from '../../layouts/DashboardLayout';
import CSVFileReader from '../../components/CSVReader';
import { connect } from 'react-redux';
import {
  fetchValidatedUserList,
  fetchAllTeachers,
  fetchTeacherPapers,
  subscribePapers,
  clearValidUserList
} from '../../actions/examPaperAction';
import StudentDetailsTable from '../../components/StudentDetailsTable';
import NotFoundStudentList from '../../components/NotFundStudentList';
import { validateEmail } from '../../utils/helper';
import { Card, CardContent } from '@material-ui/core';

class ExamPaperSubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCsvFileUploaded: false,
      validUserDetails: [],
      unfoundEmails: [],
      errorMsg: '',
      teachers: [],
      examPapers: [],
      selectedTeacherId: '',
      selectedPaperId: '',
      isSubscribeClicked: false,
      successMsg: '',
      batch: '',
      local: ''
    };
    this.handleCSVUpload = this.handleCSVUpload.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleUpdateDetails = this.handleUpdateDetails.bind(this);
    this.deleteUnFoundEmail = this.deleteUnFoundEmail.bind(this);
    this.handleTeacherChange = this.handleTeacherChange.bind(this);
    this.handlePaperChange = this.handlePaperChange.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.handleClearCsv = this.handleClearCsv.bind(this);
    this.csvFormRef = createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLocalization = this.handleLocalization.bind(this);
  }

  componentDidMount() {
    const { fetchAllTeachers, selectedLanguage } = this.props;
    fetchAllTeachers();
    this.setState({ local: selectedLanguage });
  }

  componentDidUpdate(preProps) {
    const { selectedLanguage, validatedUserData } = this.props;
    if (preProps.validatedUserData !== validatedUserData) {
      if (validatedUserData.USERS_FOUND) {
        this.setState({ validUserDetails: validatedUserData.USERS_FOUND });
      }
      if (validatedUserData.USERS_NOT_FOUND) {
        this.setState({ unfoundEmails: validatedUserData.USERS_NOT_FOUND });
      }
    }
    if (selectedLanguage !== preProps.selectedLanguage) {
      this.setState({ local: selectedLanguage });
    }
  }

  handleCSVUpload(data) {
    const { fetchValidatedUserList } = this.props;
    this.setState({ isCsvFileUploaded: true });
    if (data && data.length) {
      const userEmails = this.getEmailList(data);
      fetchValidatedUserList(userEmails);
    }
  }

  getEmailList(users) {
    return users.map((user) => user.email);
  }

  handleChangeEmail(e, index) {
    const { unfoundEmails } = this.state;
    unfoundEmails[index] = e.target.value;
    this.setState({ unfoundEmails, errorMsg: '' });
  }

  deleteUnFoundEmail(email) {
    const { unfoundEmails } = this.state;
    const newEmails = unfoundEmails.filter((unfoundEmail) => unfoundEmail !== email);
    this.setState({ unfoundEmails: newEmails });
  }

  validateUpdatedEmails(newEmails, validUserDetails) {
    let isValid = true;
    let message = '';
    if (newEmails.length) {
      newEmails.forEach((newEmail) => {
        if (!validateEmail(newEmail)) {
          isValid = false;
          message = `${newEmail} is not valid. please enter valid email`;
        } else {
          const duplicateUser = validUserDetails.find(
            (existingUser) => existingUser.email === newEmail
          );
          if (duplicateUser) {
            isValid = false;
            message = `${duplicateUser.email} already added in the list please check the emails again`;
          }
        }
      });
    }
    return {
      isValid,
      message
    };
  }

  getUpdatedEmails(newEmails, validUserDetails) {
    let oldEmails = [];
    if (validUserDetails.length) {
      oldEmails = validUserDetails.map((validUserDetail) => validUserDetail.email);
    }
    oldEmails = [...oldEmails, ...newEmails];
    return oldEmails;
  }

  handleUpdateDetails(e) {
    e.preventDefault();
    const { unfoundEmails, validUserDetails } = this.state;
    const { fetchValidatedUserList } = this.props;
    const { isValid, message } = this.validateUpdatedEmails(unfoundEmails, validUserDetails);
    if (!isValid) {
      this.setState({ errorMsg: message });
      return;
    }
    const updatedEmailList = this.getUpdatedEmails(unfoundEmails, validUserDetails);
    fetchValidatedUserList(updatedEmailList);
  }

  handleTeacherChange(e, teacher) {
    e.preventDefault();
    if (!teacher || !teacher.id) return;
    const { fetchTeacherPapers } = this.props;
    this.setState({ selectedTeacherId: teacher.id }, () => {
      fetchTeacherPapers(teacher.id, 'PUBLISHED');
    });
  }

  handlePaperChange(e, paper) {
    e.preventDefault();
    if (!paper || !paper.id) return;
    this.setState({ selectedPaperId: paper.id });
  }

  clearSuccessMsg() {
    setTimeout(() => {
      this.setState({ successMsg: '' });
    }, 3000);
  }

  handleSubscribe(e) {
    e.preventDefault();
    const { subscribePapers } = this.props;
    const { selectedPaperId, validUserDetails, batch } = this.state;
    this.setState({ isSubscribeClicked: true });
    const subscribeData = {
      examId: selectedPaperId,
      studentIds: validUserDetails.map((user) => user.id),
      batch
    };
    subscribePapers(subscribeData, () => {
      this.setState(
        {
          successMsg: 'paper subscribed successfully',
          selectedTeacherId: '',
          selectedPaperId: '',
          isSubscribeClicked: false,
          batch: ''
        },
        () => {
          this.handleClearCsv(e);
          this.clearSuccessMsg();
        }
      );
    });
  }

  handleClearCsv(e) {
    const { clearValidUserList } = this.props;
    e.preventDefault();
    this.csvFormRef.current.reset();
    clearValidUserList();
    this.setState({
      isCsvFileUploaded: false,
      validUserDetails: [],
      unfoundEmails: []
    });
  }

  handleInputChange(e) {
    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }
  handleLocalization(heading) {
    const { local } = this.state;
    if (heading == 'paper_subscribe') {
      if (local == 'sinhala') {
        return 'විභාග ප්‍රශ්න පත්‍රය Subscribe කරන්න';
      } else if (local == 'tamil') {
        return 'தேர்வு தாள் குழுசேர்';
      } else {
        return 'Paper Subscribe';
      }
    } else if (heading == 'select_teacher') {
      if (local == 'sinhala') {
        return 'ගුරුවරයා තෝරන්න';
      } else if (local == 'tamil') {
        return 'ஆசிரியர் தேர்வு';
      } else {
        return 'select teacher';
      }
    } else if (heading == 'select_exam') {
      if (local == 'sinhala') {
        return 'විභාග ප්‍රශ්න පත්‍රය තෝරන්න';
      } else if (local == 'tamil') {
        return 'தேர்வு தாள் தேர்வு';
      } else {
        return 'select exam paper';
      }
    } else if (heading == 'batch') {
      if (local == 'sinhala') {
        return 'v';
      } else if (local == 'tamil') {
        return 'தொகுதி';
      } else {
        return 'batch';
      }
    } else if (heading == 'upload_student') {
      if (local == 'sinhala') {
        return 'ශිෂ්‍ය විස්තර උඩුගත කරන්න';
      } else if (local == 'tamil') {
        return 'மாணவர் விவரங்களை பதிவேற்றவும்';
      } else {
        return 'upload student detail';
      }
    } else if (heading == 'subscribe') {
      if (local == 'sinhala') {
        return 'දායක වන්න';
      } else if (local == 'tamil') {
        return 'குழுசேர்';
      } else {
        return 'Subscribe';
      }
    }
  }
  render() {
    const { validatedUserData, teachers, teacherExamPapers } = this.props;
    const {
      isCsvFileUploaded,
      errorMsg,
      unfoundEmails,
      selectedPaperId,
      validUserDetails,
      isSubscribeClicked,
      successMsg,
      selectedTeacherId,
      batch
    } = this.state;

    return (
      <DashboardLayout>
        <Container style={{ marginTop: '10px' }}>
          <Card style={{ padding: '10px', border: '1px solid #186CEE', borderRadius: '15px' }}>
            <CardContent>
              <h3 style={{ textAlign: 'center' }}>{this.handleLocalization('paper_subscribe')}</h3>
              {successMsg ? <p style={{ color: 'green', fontSize: '20px' }}>{successMsg}</p> : null}
              <div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Autocomplete
                      id="teacher-id"
                      options={teachers}
                      getOptionLabel={(option) => option.fullName}
                      style={{ width: '75%', border: '1px solid #186CEE', borderRadius: '10px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={this.handleLocalization('select_teacher')}
                          variant="outlined"
                        />
                      )}
                      onChange={this.handleTeacherChange}
                      value={teachers.find((teacher) => teacher.id === selectedTeacherId)}
                      key={selectedTeacherId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Autocomplete
                      id="exam-id"
                      options={teacherExamPapers}
                      getOptionLabel={(option) => option.title || ''}
                      style={{ width: '75%', border: '1px solid #186CEE', borderRadius: '10px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={this.handleLocalization('select_exam')}
                          variant="outlined"
                        />
                      )}
                      onChange={this.handlePaperChange}
                      value={teacherExamPapers.find((paper) => paper.id === selectedPaperId)}
                      key={selectedPaperId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                      id="batch"
                      style={{ width: '75%', border: '1px solid #186CEE', borderRadius: '10px' }}
                      label={this.handleLocalization('batch')}
                      variant="outlined"
                      onChange={this.handleInputChange}
                      name="batch"
                      value={batch}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <CSVFileReader
                      handleFileUpload={this.handleCSVUpload}
                      isFileUploaded={isCsvFileUploaded}
                      handleClearCsv={this.handleClearCsv}
                      csvFormRef={this.csvFormRef}
                    />
                  </Grid>
                </Grid>
              </div>
              <h4 style={{ textAlign: 'center' }}>{this.handleLocalization('upload_student')}</h4>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '15px',
                  textAlign: 'center'
                }}
              >
                <StudentDetailsTable userData={validatedUserData} />
                <NotFoundStudentList
                  handleUpdateDetails={this.handleUpdateDetails}
                  handleChangeEmail={this.handleChangeEmail}
                  handleDelete={this.deleteUnFoundEmail}
                  unfoundEmails={unfoundEmails}
                  errorMsg={errorMsg}
                />
              </div>
              <Button
                disabled={
                  isSubscribeClicked ||
                  !selectedPaperId ||
                  !(validUserDetails.length !== 0 && unfoundEmails.length === 0)
                }
                onClick={this.handleSubscribe}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #186CEE',
                  padding: '10px',
                  width: '75%',
                  height: '40px',
                  margin: '0 auto',
                  borderRadius: '10px',
                  color: !(
                    isSubscribeClicked ||
                    !selectedPaperId ||
                    !(validUserDetails.length !== 0 && unfoundEmails.length === 0)
                  )
                    ? 'white'
                    : 'black',
                  backgroundColor: !(
                    isSubscribeClicked ||
                    !selectedPaperId ||
                    !(validUserDetails.length !== 0 && unfoundEmails.length === 0)
                  )
                    ? '#186CEE'
                    : 'white',
                  cursor: !(
                    isSubscribeClicked ||
                    !selectedPaperId ||
                    !(validUserDetails.length !== 0 && unfoundEmails.length === 0)
                  )
                    ? 'pointer'
                    : 'not-allowed',
                  textAlign: 'center'
                }}
              >
                {this.handleLocalization('subscribe')}
              </Button>
            </CardContent>
          </Card>
        </Container>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  validatedUserData: state.examPaper.validatedUserData,
  teachers: state.examPaper.teachers,
  teacherExamPapers: state.examPaper.teacherExamPapers,
  selectedLanguage: state.language.selectedLanguage
});

const mapDispatchToProps = {
  fetchValidatedUserList,
  fetchAllTeachers,
  fetchTeacherPapers,
  subscribePapers,
  clearValidUserList
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamPaperSubscribe);
