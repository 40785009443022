import axios from 'axios';
import { FETCH_AVAILABLE_FAQ } from '../utils/type';
import { API_URL, authHeader } from '../utils/helper';
import { getValidToken } from './filterAction';

export const fetchAvailableFaq = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/faq/all`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_AVAILABLE_FAQ,
          data: res.data
        });
      })
      .catch((error) => {
        console.error('there is an error in fatchAvailableFaq', error);
      });
  };
};

export const fetchAvailableAllFaq = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/faq/all-language-faq`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_AVAILABLE_FAQ,
          data: res.data
        });
      })
      .catch((error) => {
        console.error('There is an error in ftchAvailableAllFaq', error);
      });
  };
};

export const patchFaq = (faq, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/faq`, faq, authHeader(token))
      .then((r) => {
        onSuccess(r.data);
      })
      .catch((e) => {
        console.error('there is an error in patchFaq', e);
      });
  };
};

export const updateFaq = (faq, id, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .put(`${API_URL}/faq/${id}`, faq, authHeader(token))
      .then((r) => {
        onSuccess(r.data);
      })
      .catch((e) => {
        console.error('there is an error in updateFaq', e);
      });
  };
};

export const deleteFaq = (id, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .delete(`${API_URL}/faq/${id}`, authHeader(token))
      .then((r) => {
        onSuccess(r.data);
      })
      .catch((e) => {
        console.error('there is an error in updateFaq', e);
      });
  };
};
