import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { fetchHistoryTypingPaper } from '../../actions/typistAction';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function TypistHistory() {
  const historyPaperRequest = useSelector((state) => state.examPaperRequest.historyPaperRequest);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHistoryTypingPaper());
  }, []);
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                {t('typist_history_page')}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('start_date')}</TableCell>
                    <TableCell>{t('provided_days')}</TableCell>
                    <TableCell>{t('teacher_name')}</TableCell>
                    <TableCell>{t('subject')}</TableCell>
                    <TableCell>{t('subject_title')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyPaperRequest.length >= 1 &&
                    historyPaperRequest.map((papers, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {papers.typingAcceptDate
                            ? new Date(papers.typingAcceptDate).toDateString()
                            : 'No data'}
                        </TableCell>
                        <TableCell>
                          {papers.noOfTypingDays ? papers.noOfTypingDays : 'No data'}
                        </TableCell>
                        <TableCell>{papers.teacherName}</TableCell>
                        <TableCell>{papers.subject}</TableCell>
                        <TableCell>{papers.title}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypistHistory;
