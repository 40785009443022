import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import keycloakService from '../../keycloakService';
import TeacherDashboardLayout from '../../layouts/DashboardLayout';
import { fetchTeacherPapers, fetchExamPaperStatByBatch } from '../../actions/examPaperAction';
import './BatchResults.css';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@mui/material';

const BatchResults = () => {
  const [examDetails, setExamDetails] = useState({ examId: '', batch: '' });
  const { sub } = keycloakService.getUserDetails();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const { teacherExamPapers, batchResults } = useSelector((state) => state.examPaper);

  useEffect(() => {
    dispatch(fetchTeacherPapers(sub, 'PUBLISHED'));
  }, []);

  const handlePaperChange = (e, examPaper) => {
    setExamDetails({
      ...examDetails,
      examId: examPaper ? examPaper.id : ''
    });
  };

  const handleChange = (e) => {
    setExamDetails({
      ...examDetails,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(fetchExamPaperStatByBatch(examDetails));
  };

  return (
    <TeacherDashboardLayout>
      <div style={{ margin: '20px' }}>
        <Grid
          container
          spacing={3}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Grid item xs={3} sm={4}>
            <Autocomplete
              id="exam-id"
              options={teacherExamPapers || []}
              getOptionLabel={(option) => option.title || ''}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label={t('select_exam')} variant="outlined" />
              )}
              onChange={handlePaperChange}
              value={teacherExamPapers.find((a) => a.id === examDetails.examId)}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <TextField
              id="batch"
              fullWidth
              label={t('batch')}
              variant="outlined"
              name="batch"
              onChange={handleChange}
              value={examDetails.batch}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <Button
              disabled={!examDetails.examId || !examDetails.batch}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {t('view_result')}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3} sm={6} style={{ margin: '10px 0 0 10px' }}>
            <Typography variant="h5" gutterBottom>
              {batchResults.examTitle || ''}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={6} md={12}>
            {batchResults.examBatchCandidateStatList &&
              batchResults.examBatchCandidateStatList.map((examBatchCandidate) => (
                <Card className="d-flex result-card" key={examBatchCandidate.examAttemptId}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {t('student_name')} - {examBatchCandidate.candidateName || ''}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {t('student_email')}- {examBatchCandidate.candidateEmail || ''}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {t('score')} - {examBatchCandidate.candidateEmail || ''}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
          </Grid>
        </Grid>
      </div>
    </TeacherDashboardLayout>
  );
};

export default BatchResults;
