import React, { Component } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';

export default class TutorHome extends Component {
  render() {
    return (
      <DashboardLayout>
        <h1>This is Tutor Dashboard is Coming Soon..!</h1>
      </DashboardLayout>
    );
  }
}
