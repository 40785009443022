import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { fetchTeacherRequest } from '../../actions/typistAction';
import { useDispatch, useSelector } from 'react-redux';
import { acceptTeacherRequest } from '../../actions/typistAction';
import { rejectTeacherRequest } from '../../actions/typistAction';
import { useTranslation } from 'react-i18next';

function TypistTeacherRequest() {
  const dispatch = useDispatch();
  const teacherRequest = useSelector((state) => state.examPaperRequest.teacherRequest);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const handleClick = (click, id) => {
    if (click == 'accept') {
      dispatch(
        acceptTeacherRequest(id, () => {
          window.location.reload();
        })
      );
    } else if (click == 'reject') {
      dispatch(
        rejectTeacherRequest(id, () => {
          window.location.reload();
        })
      );
    }
    setTriggerUpdate(!triggerUpdate);
  };
  useEffect(() => {
    dispatch(fetchTeacherRequest());
  }, [triggerUpdate]);
  const requestList = (request, index) => {
    return (
      <TableRow key={index}>
        <TableCell>
          {request.firstName} {request.lastName}
        </TableCell>
        <TableCell>{request.email}</TableCell>
        <TableCell>
          <Button variant="contained" onClick={() => handleClick('accept', request.id)}>
            {t('accept')}
          </Button>
          <Button variant="contained" onClick={() => handleClick('reject', request.id)}>
            {t('reject')}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                {t('typist_teacher_request')}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>{t('teacher_name')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{t('teacher_email')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{t('action')}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teacherRequest &&
                    teacherRequest.length >= 1 &&
                    teacherRequest.map((request, index) => requestList(request, index))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypistTeacherRequest;
