import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import { fetchAllPaperForSelectSubject } from '../../actions/CorrectorAction';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

function CurrentWorkPapers(props) {
  const { examId, handleResultPage, acceptEssayPaperReaquest } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const papersForSelectedSubject = useSelector(
    (state) => state.essayPaperRequest.allPapersforSubject
  );
  useEffect(() => {
    dispatch(fetchAllPaperForSelectSubject(examId));
  }, []);
  return (
    <Grid item xs={12} sm={12}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>{t('exam_id')}</strong>
            </TableCell>
            <TableCell>
              <strong>{t('attempt_id')}</strong>
            </TableCell>
            <TableCell>
              <strong>{t('status')}</strong>
            </TableCell>
            <TableCell>
              <strong>Number of Papers</strong>
            </TableCell>
            <TableCell>
              <strong>{t('action')}</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {papersForSelectedSubject &&
            papersForSelectedSubject.length >= 1 &&
            papersForSelectedSubject.map((acceptPapers, index) => (
              <TableRow key={index}>
                <TableCell>{acceptPapers.examId}</TableCell>
                <TableCell>{acceptPapers.id}</TableCell>
                <TableCell>{acceptPapers.status}</TableCell>
                <TableCell>{acceptEssayPaperReaquest.length}</TableCell>
                <TableCell>
                  <Button variant="contained" onClick={() => handleResultPage(acceptPapers)}>
                    Start Correction
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

export default CurrentWorkPapers;
