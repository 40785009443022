import React, { useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function CurrentWorkSubject(props) {
  const { acceptEssayPaperReaquest, handleClick } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <Grid item xs={12} sm={12}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>{t('teacher_name')}</strong>
            </TableCell>
            <TableCell>
              <strong>{t('subject_name')}</strong>
            </TableCell>
            <TableCell>
              <strong>{t('subject_title')}</strong>
            </TableCell>
            <TableCell>
              <strong>{t('action')}</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {acceptEssayPaperReaquest.length >= 1 &&
            acceptEssayPaperReaquest.map((acceptPapers, index) => (
              <TableRow key={index}>
                <TableCell>{acceptPapers.teacherName}</TableCell>
                <TableCell>{acceptPapers.subject}</TableCell>
                <TableCell>{acceptPapers.title}</TableCell>
                <TableCell>
                  <Button variant="contained" onClick={() => handleClick(acceptPapers.id)}>
                    {t('view_each_paper')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

export default CurrentWorkSubject;
