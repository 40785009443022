import React, { Component } from 'react';
import TypistSubscription from '../../components/TypistSubscribe';
import { fetchAvailableTypist } from '../../actions/typistAction';
import { connect } from 'react-redux';
import { typistSubscriptionRequest } from '../../actions/typistAction';
import { fetchSubscribedTypist } from '../../actions/typistAction';
import { fetchPendingTypistRequest } from '../../actions/typistAction';
import { fetchCurrentTypingPapers } from '../../actions/typistAction';
import { fetchCompletedTypingPapers } from '../../actions/typistAction';

class TypistSubscribeHandle extends Component {
  constructor() {
    super();
    this.state = {
      typist: '',
      selectTypist: '',
      subscribedTypists: '',
      pendingTypistRequest: '',
      currentTypingPapers: '',
      completedTypingPapers: ''
    };
    this.handleTypistChange = this.handleTypistChange.bind(this);
    this.subscribeTypist = this.subscribeTypist.bind(this);
  }
  componentDidMount() {
    const {
      fetchAvailableTypist,
      fetchSubscribedTypist,
      fetchPendingTypistRequest,
      fetchCurrentTypingPapers,
      fetchCompletedTypingPapers
    } = this.props;
    fetchAvailableTypist();
    fetchSubscribedTypist();
    fetchPendingTypistRequest();
    fetchCurrentTypingPapers();
    fetchCompletedTypingPapers();
  }
  componentDidUpdate(preProps) {
    const { typists, subTypists, penTypist, currExamPapers, completedPapers } = this.props;
    if (preProps.typists !== typists) {
      this.setState({ typist: typists });
    } else if (preProps.subTypists !== subTypists) {
      this.setState({ subscribedTypists: subTypists });
    } else if (preProps.penTypist !== penTypist) {
      this.setState({ pendingTypistRequest: penTypist });
    } else if (preProps.currExamPapers !== currExamPapers) {
      this.setState({ currentTypingPapers: currExamPapers });
    } else if (preProps.completedPapers !== completedPapers) {
      this.setState({ completedTypingPapers: completedPapers });
    }
  }
  handleTypistChange = (e, val) => {
    e.preventDefault();
    this.setState({ selectTypist: val });
  };
  subscribeTypist = () => {
    const { selectTypist } = this.state;
    const { typistSubscriptionRequest } = this.props;
    typistSubscriptionRequest(selectTypist.id, () => {
      window.location.reload();
    });
  };
  render() {
    const {
      typist,
      selectTypist,
      subscribedTypists,
      pendingTypistRequest,
      currentTypingPapers,
      completedTypingPapers
    } = this.state;
    return (
      <TypistSubscription
        typists={typist}
        selectTypist={selectTypist}
        handleTypistChange={this.handleTypistChange}
        subscribeTypist={this.subscribeTypist}
        subscribedTypists={subscribedTypists}
        pendingTypistRequest={pendingTypistRequest}
        currentTypingPapers={currentTypingPapers}
        completedTypingPapers={completedTypingPapers}
      />
    );
  }
}
const mapStateToProp = (state) => ({
  typists: state.examPaperRequest.availableTypist,
  subTypists: state.examPaperRequest.subscribedTypist,
  penTypist: state.examPaperRequest.pendingTypistRequest,
  currExamPapers: state.examPaperRequest.currentTypingPaper,
  completedPapers: state.examPaperRequest.completedPapers
});
const mapDispatchToProp = {
  fetchAvailableTypist,
  typistSubscriptionRequest,
  fetchSubscribedTypist,
  fetchPendingTypistRequest,
  fetchCurrentTypingPapers,
  fetchCompletedTypingPapers
};

export default connect(mapStateToProp, mapDispatchToProp)(TypistSubscribeHandle);
