import { Button, FormControl } from '@material-ui/core';
import React, { useEffect } from 'react';
import NameSelector from '../Selector/NameSelector';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function DeleteSelector(props) {
  const {
    Language,
    Subject,
    Grade,
    Teacher,
    handleChangeClick,
    deleteSubject,
    selector,
    deleteTeacher
  } = props;

  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const addhandleSubject = () => {
    console.log('inside subject delete');
    deleteSubject();
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px'
      }}
    >
      <div
        style={{
          width: '60%',
          border: '1px solid #186CEE',
          borderRadius: '15px',
          padding: '30px',
          marginBottom: '20px',
          textAlign: 'center'
        }}
      >
        <h1 style={{ marginBottom: '20px' }}>Delete Existing Selector</h1>

        <div style={{ marginBottom: '20px' }}>
          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="language"
              onChange={handleChangeClick}
              options={Language}
              placeHolderText={t('select_language')}
            />
          </FormControl>

          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="grade"
              onChange={handleChangeClick}
              options={Grade}
              placeHolderText={t('select_grade')}
            />
          </FormControl>
          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="subject"
              onChange={handleChangeClick}
              options={Subject}
              placeHolderText={t('select_subject')}
            />
          </FormControl>
          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="teacher"
              onChange={handleChangeClick}
              options={Teacher}
              placeHolderText={t('select_teacher')}
            />
          </FormControl>
        </div>
        <div style={{ display: 'block', justifyContent: 'space-around', color: 'green' }}>
          {Teacher && <p>This Subject has a Teacher so delete Teacher First</p>}
          <Button
            disabled={
              selector.language == '' ||
              selector.grade == '' ||
              selector.subject == '' ||
              (Teacher ? selector.teacher == '' : false)
            }
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '15px',
              color:
                selector.language != '' &&
                selector.grade != '' &&
                selector.subject != '' &&
                (Teacher ? selector.teacher != '' : true)
                  ? 'white'
                  : 'black',
              backgroundColor:
                selector.language != '' &&
                selector.grade != '' &&
                selector.subject != '' &&
                (Teacher ? selector.teacher != '' : true)
                  ? '#186CEE'
                  : '#f0f0f0',
              cursor:
                selector.language != '' &&
                selector.grade != '' &&
                selector.subject != '' &&
                (Teacher ? selector.teacher != '' : true)
                  ? 'pointer'
                  : 'not-allowed',
              textAlign: 'center',
              fontWeight: 'bold'
            }}
            onClick={selector?.teacher ? deleteTeacher : addhandleSubject}
          >
            {t('delete')} {Teacher ? t('teacher') : t('subject')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeleteSelector;
