import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReadOnly from '../RichTextEditor/ReadOnly';
import { ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  questionsContainer: {
    marginTop: 25,
    marginBottom: 50
  },
  questionCard: {
    marginTop: 10,
    marginBottom: 10
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  correctMark: {
    color: 'green',
    marginTop: 35,
    marginBottom: 35
  },
  qnumber: {
    marginLeft: 10,
    marginRight: 20
  },
  ans: {
    marginLeft: 10
  },
  rightPad: {
    paddingRight: '5px'
  }
}));
const questionInitialBlock = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
const ViewQuestionsAndAnswers = (props) => {
  const { questions, examPaperDetails } = props;
  const classes = useStyles();
  const handleGrpQuestion = (index) => {
    let questionObj = { question: questionInitialBlock, show: 'none' };
    const grpQuestion = examPaperDetails.groupQuestions;
    if (grpQuestion != null) {
      for (let i = 0; i < grpQuestion.length; i++) {
        const item = grpQuestion[i];
        if (item.startQuestion <= index && index <= item.endQuestion) {
          questionObj = {
            question: JSON.parse(item.grpQuestion),
            show: ''
          };
          break;
        } else {
          questionObj = { question: questionInitialBlock, show: 'none' };
        }
      }
    }
    return questionObj;
  };
  const displayQuestions = (questionsList) => {
    let displayQuestions = null;
    if (questionsList && questionsList.length > 0) {
      displayQuestions = questionsList.map((questionItem, index) => {
        const questionData = JSON.parse(questionItem.question);
        return (
          <Card className={classes.questionCard} key={questionItem.id}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={10}>
                  <div>
                    <strong>Question No - {index + 1}</strong>
                  </div>
                  {examPaperDetails.groupQuestions != null && (
                    <div style={{ display: handleGrpQuestion(index + 1)?.show, margin: '10px' }}>
                      <p>
                        <strong>Group Question</strong>
                      </p>
                      <Grid item xs={12} sm={12} style={{ marginLeft: '20px' }}>
                        <ReadOnly initialValue={handleGrpQuestion(index + 1).question} />
                      </Grid>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} style={{ marginLeft: '20px' }}>
                  <ReadOnly initialValue={questionData} />
                </Grid>
                {examPaperDetails.category == 'MCQ' && (
                  <>
                    <p className={classes.ans}>
                      <strong>Answers</strong>
                    </p>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={1}>
                        {questionItem.answers &&
                          questionItem.answers.map((answerItem, index) => (
                            <>
                              <ListItem
                                key={index + 1}
                                style={{ backgroundColor: answerItem.valid ? '#99cc99' : '' }}
                              >
                                <label className={classes.qnumber}>{index + 1}.</label>
                                <ListItemText style={{ paddingBottom: '1px' }}>
                                  <ReadOnly
                                    key={index + 1}
                                    initialValue={JSON.parse(answerItem.answer)}
                                  />
                                </ListItemText>
                              </ListItem>
                            </>
                          ))}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        );
      });
    }
    return displayQuestions;
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <div className={classes.questionsContainer}>{displayQuestions(questions)}</div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ViewQuestionsAndAnswers;
