import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  fetchExamPaperById,
  fetchAllQuestions,
  updateQuestion,
  updateQuestionStatus,
  publishExamPaper
} from '../../actions/examPaperAction';
import QuestionsEditAndReview from '../../components/ExamPaper/QuestionsEditAndReview';
import PublishConfirmModal from '../../components/Modal/ConfirmModal';
import keycloakService from '../../keycloakService';
import { ROLE } from '../../utils/helper';
import { patchTypistSubmit } from '../../actions/typistAction';
import QuestionsEditAndReviewSecondPart from '../../components/ExamPaper/QuestionsEditAndReviewSecondPart';

class EditOrReview extends Component {
  constructor() {
    super();
    this.state = {
      price: '',
      questionsList: [],
      selectedQuestionId: '',
      selectedQuestion: '',
      selectedQuestionAnswers: [],
      selectedAnswer: '',
      submitting: false,
      publishModalOpen: false,
      grpQuestion: []
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditSecPart = this.handleEditSecPart.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCancelSecPart = this.handleCancelSecPart.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleQuestionChange = this.handleQuestionChange.bind(this);
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSaveQuestion = this.handleSaveQuestion.bind(this);
    this.handleSaveQuestionSecPart = this.handleSaveQuestionSecPart.bind(this);
    this.handleRejectQuestion = this.handleRejectQuestion.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handlePublishClose = this.handlePublishClose.bind(this);
    this.handlePublishConfirm = this.handlePublishConfirm.bind(this);
  }
  componentDidMount() {
    const { fetchExamPaperById, fetchAllQuestions, match } = this.props;
    const { examPaperId } = match.params;
    fetchExamPaperById(examPaperId);
    fetchAllQuestions(examPaperId);
  }

  componentDidUpdate(preProps) {
    const { examPaperDetails, questions } = this.props;
    if (preProps.examPaperDetails !== examPaperDetails) {
      console.log('i am inside');
      this.setState({
        price: examPaperDetails.price,
        grpQuestion: examPaperDetails.groupQuestions
      });
    }
    if (preProps.questions !== questions) {
      this.setState({ questionsList: questions });
    }
  }

  findCorrectAnswer(answers) {
    let correctAnswer = '';
    if (answers && answers.length) {
      const answerIndex = answers.findIndex((answer) => answer.valid === true);
      correctAnswer = `answer-${answerIndex}`;
    }
    return correctAnswer;
  }

  handleEdit(questionItem) {
    const correctAnswer = this.findCorrectAnswer(questionItem.answers);
    this.setState({
      selectedQuestionId: questionItem.id,
      selectedQuestion: questionItem.question,
      selectedQuestionAnswers: questionItem.answers,
      selectedAnswer: correctAnswer
    });
  }
  handleEditSecPart(questionItem) {
    this.setState({
      selectedQuestionId: questionItem.id,
      selectedQuestion: questionItem.question
    });
  }

  handleCancel() {
    this.setState({
      selectedQuestionId: '',
      selectedQuestion: '',
      selectedQuestionAnswers: '',
      selectedAnswer: ''
    });
  }
  handleCancelSecPart() {
    this.setState({
      selectedQuestionId: '',
      selectedQuestion: ''
    });
  }

  handlePriceChange(e) {
    this.setState({ price: e.target.value });
  }

  handleQuestionChange(questionId, val) {
    const { selectedQuestionId } = this.state;
    if (selectedQuestionId === questionId && val) {
      const modifiedQuestion = JSON.stringify(val);
      this.setState({ selectedQuestion: modifiedQuestion });
    }
  }

  handleAnswerChange(index, val) {
    const { selectedQuestionAnswers } = this.state;
    if (val) {
      selectedQuestionAnswers[index].answer = JSON.stringify(val);
      this.setState({ selectedQuestionAnswers });
    }
  }

  handleRadioChange(e) {
    this.setState({ selectedAnswer: e.target.value });
  }

  getAnswersData() {
    const { selectedQuestionAnswers, selectedAnswer } = this.state;
    const answersData = [];
    selectedQuestionAnswers.forEach((answerItem, i) => {
      answersData.push({
        answer: answerItem.answer,
        valid: !!selectedAnswer.includes(i)
      });
    });
    return answersData;
  }

  handleSaveQuestion(e, questionNo) {
    e.preventDefault();
    const { selectedQuestionId, selectedQuestion } = this.state;
    this.setState({ submitting: true });
    const { updateQuestion, match } = this.props;
    const { examPaperId } = match.params;
    const answers = this.getAnswersData();
    const questionData = {
      examId: examPaperId,
      questionNo: questionNo,
      group: '',
      status: 'DRAFT',
      question: selectedQuestion,
      answers: answers
    };
    if (selectedQuestionId) {
      questionData.id = selectedQuestionId;
      updateQuestion(questionData, () => {
        this.updateQuestionList(answers);
        this.setState({ submitting: false });
      });
    }
  }
  handleSaveQuestionSecPart(e, questionNo) {
    e.preventDefault();
    const { selectedQuestionId, selectedQuestion } = this.state;
    this.setState({ submitting: true });
    const { updateQuestion, match } = this.props;
    const { examPaperId } = match.params;
    const questionData = {
      examId: examPaperId,
      questionNo: questionNo,
      group: '',
      status: 'DRAFT',
      question: selectedQuestion
    };
    if (selectedQuestionId) {
      questionData.id = selectedQuestionId;
      updateQuestion(questionData, () => {
        this.updateQuestionListSecondPart();
        this.setState({ submitting: false });
      });
    }
  }
  handleRejectQuestion(e, questionId) {
    e.preventDefault();
    this.setState({ submitting: true });
    const { questionsList } = this.state;
    const { updateQuestionStatus, examPaperDetails } = this.props;
    const questionData = {
      questionId: questionId,
      status: 'PENDING'
    };
    updateQuestionStatus(questionData, () => {
      const selectedQuestionIndex = questionsList.findIndex((item) => item.id === questionId);
      if (selectedQuestionIndex >= 0 && examPaperDetails.category == 'MCQ') {
        questionsList[selectedQuestionIndex].examStatus = 'PENDING';
        this.setState({ questionsList, submitting: false }, () => {
          this.handleCancel();
        });
      } else {
        questionsList[selectedQuestionIndex].examStatus = 'PENDING';
        this.setState({ questionsList, submitting: false }, () => {
          this.handleCancelSecPart();
        });
      }
    });
  }

  updateQuestionList(answers) {
    const { selectedQuestionId, selectedQuestion, questionsList } = this.state;
    const selectedQuestionIndex = questionsList.findIndex((item) => item.id === selectedQuestionId);
    if (selectedQuestionIndex >= 0) {
      questionsList[selectedQuestionIndex].question = selectedQuestion;
      questionsList[selectedQuestionIndex].answers = answers;
      this.setState({ questionsList }, () => {
        this.handleCancel();
      });
    }
  }
  updateQuestionListSecondPart() {
    const { selectedQuestionId, selectedQuestion, questionsList } = this.state;
    const selectedQuestionIndex = questionsList.findIndex((item) => item.id === selectedQuestionId);
    if (selectedQuestionIndex >= 0) {
      questionsList[selectedQuestionIndex].question = selectedQuestion;
      this.setState({ questionsList }, () => {
        this.handleCancelSecPart();
      });
    }
  }

  handlePublish() {
    this.setState({ publishModalOpen: true });
  }

  handlePublishClose() {
    this.setState({ publishModalOpen: false });
  }

  handlePublishConfirm(e) {
    e.preventDefault();
    this.setState({ submitting: true });
    const { questionsList } = this.state;
    const { publishExamPaper, match, history, examPaperDetails, patchTypistSubmit } = this.props;
    const { examPaperId } = match.params;
    if (examPaperId) {
      if (examPaperDetails.noOfQuestions === questionsList.length) {
        const isTeacher = keycloakService.hasRole([ROLE.Teacher]);
        if (isTeacher) {
          publishExamPaper(examPaperId, 'PUBLISHED', () => {
            history.push('/published-exam-papers');
            this.setState({ submitting: false });
          });
        }

        const isTypist = keycloakService.hasRole([ROLE.Typist]);
        if (isTypist) {
          patchTypistSubmit(examPaperId);
          this.setState({ submitting: false });
          history.push('/in-review');
        }
      }
    }
  }

  render() {
    const { examPaperDetails } = this.props;
    const {
      selectedQuestionId,
      price,
      questionsList,
      selectedQuestion,
      selectedQuestionAnswers,
      selectedAnswer,
      submitting,
      publishModalOpen
    } = this.state;
    const isPublished = examPaperDetails && examPaperDetails.typistStatus === 'COMPLETED';

    return (
      <Fragment>
        <DashboardLayout>
          <Container>
            <Card>
              <CardContent>
                <h5>{examPaperDetails.title || ''}</h5>
              </CardContent>
            </Card>
            <Divider />
            {examPaperDetails.category == 'MCQ' ? (
              <QuestionsEditAndReview
                questions={questionsList}
                selectedQuestionId={selectedQuestionId}
                selectedQuestion={selectedQuestion}
                selectedQuestionAnswers={selectedQuestionAnswers}
                selectedAnswer={selectedAnswer}
                submitting={submitting}
                examPaperDetails={examPaperDetails}
                handleEdit={this.handleEdit}
                handleCancel={this.handleCancel}
                handleQuestionChange={this.handleQuestionChange}
                handleAnswerChange={this.handleAnswerChange}
                handleRadioChange={this.handleRadioChange}
                handleSaveQuestion={this.handleSaveQuestion}
                handleRejectQuestion={this.handleRejectQuestion}
              />
            ) : (
              <QuestionsEditAndReviewSecondPart
                questions={questionsList}
                selectedQuestionId={selectedQuestionId}
                selectedQuestion={selectedQuestion}
                submitting={submitting}
                handleEdit={this.handleEditSecPart}
                handleCancel={this.handleCancelSecPart}
                handleQuestionChange={this.handleQuestionChange}
                handleSaveQuestion={this.handleSaveQuestionSecPart}
                handleRejectQuestion={this.handleRejectQuestion}
              />
            )}
            <Divider />
            <Card style={{ marginBottom: 20 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={this.handlePriceChange}
                      value={price}
                      id="standard-basic"
                      label="price"
                      name="price"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      disabled={isPublished || submitting}
                      onClick={this.handlePublish}
                      variant="contained"
                      color="primary"
                    >
                      {keycloakService.hasRole([ROLE.Typist]) == true ? 'Submit' : 'Publish'}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <PublishConfirmModal
              open={publishModalOpen}
              handleClose={this.handlePublishClose}
              handleSubmit={this.handlePublishConfirm}
              isSubmitting={submitting}
              msg="Are you sure want to publish the paper"
            />
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  examPaperDetails: state.examPaper.examPaperDetails,
  questions: state.examPaper.questions
});

const mapDispatchToProps = {
  fetchExamPaperById,
  fetchAllQuestions,
  updateQuestion,
  updateQuestionStatus,
  publishExamPaper,
  patchTypistSubmit
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrReview);
