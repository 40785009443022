import React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';

const SettlementDetails = () => {
  return (
    <DashboardLayout>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1>Coming soon</h1>
      </div>
    </DashboardLayout>
  );
};

export default SettlementDetails;
