import React, { Component, Fragment } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import Addselector from '../../components/AddSelector/Addselector';
import { Card, CardContent } from '@material-ui/core';
import { cascadeFilter } from '../../actions/filterAction';
import { connect } from 'react-redux';
import {
  addNewSelector,
  updateNewSelector,
  DeleteNewSelector,
  addNewTeacherSelector
} from '../../actions/filterAction'; //
import UpdateSelector from '../../components/AddSelector/UpdateSelector';
import DeleteSelector from '../../components/AddSelector/DeleteSelector';
import { fetchAllTeachers } from '../../actions/examPaperAction';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const SELECTOR = {
  language: '',
  grade: '',
  subject: '',
  teacher: ''
};
class HandleSelector extends Component {
  constructor() {
    super();
    this.state = {
      selector: SELECTOR,
      Language: '',
      Grade: '',
      Subject: '',
      Teacher: '',
      image: '',
      newSubject: '',
      newTeacher: '',
      parent: '',
      parentId: '',
      navigate: 'add',
      subjectVal: '',
      teacher: '',
      preId: '',
      preParent: '',
      selectedTeacherId: '',
      selectedTab: 'add',
      local: ''
    };
    this.handleChangeClick = this.handleChangeClick.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.addSubject = this.addSubject.bind(this);
    this.handleButton = this.handleButton.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.updateSubject = this.updateSubject.bind(this);
    this.deleteSubject = this.deleteSubject.bind(this);
    this.addhandleTeacher = this.addhandleTeacher.bind(this);
    this.updatehandleTeacher = this.updatehandleTeacher.bind(this);
    this.handleTeacherChange = this.handleTeacherChange.bind(this);
    this.deleteTeacher = this.deleteTeacher.bind(this);
    this.handleLocalization = this.handleLocalization.bind(this);
  }
  componentDidMount() {
    const { cascadeFilter, fetchAllTeachers, selectedLanguage } = this.props;
    cascadeFilter('root');
    fetchAllTeachers();
    this.setState({ local: selectedLanguage });
  }
  componentDidUpdate(preProps) {
    const { selectedLanguage, cascadeFilterResult } = this.props;
    if (preProps.cascadeFilterResult !== cascadeFilterResult) {
      this.setState({ allFilter: cascadeFilterResult });
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Language') {
        this.setState({ Language: cascadeFilterResult, Grade: '', Subject: '', Teacher: '' });
      }
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Grade') {
        this.setState({ Grade: cascadeFilterResult, Subject: '', Teacher: '' });
      }
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Subject') {
        this.setState({ Subject: cascadeFilterResult, Teacher: '' });
      }
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Teacher') {
        this.setState({ Teacher: cascadeFilterResult });
      }
    }
    if (selectedLanguage !== preProps.selectedLanguage) {
      this.setState({ local: selectedLanguage });
    }
  }
  handleButton(navigate) {
    this.setState({
      navigate: navigate,
      selector: SELECTOR,
      Grade: '',
      Subject: '',
      Teacher: '',
      image: '',
      newSubject: '',
      newTeacher: '',
      parent: '',
      parentId: '',
      subjectVal: '',
      teacher: '',
      preId: '',
      preParent: ''
    });
  }
  handleTabChange(event, newValue) {
    this.setState({ selectedTab: newValue });
    this.handleButton(newValue);
  }
  handleChangeClick(e) {
    const { cascadeFilter } = this.props;
    const { selector } = this.state;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const keys = selectedOption.dataset.key;
    const image = selectedOption.dataset.img;
    const { name, value } = e.target;
    selector[name] = keys;
    if (name == 'subject') {
      this.setState({
        image: image,
        subjectVal: value,
        newSubject: value
      });
    }
    if (name == 'teacher') {
      this.setState({ image: image });
    }
    this.setState({ selector });
    cascadeFilter(keys);
  }
  handleSubjectChange(e) {
    const { value } = e.target;
    this.setState({ newSubject: value });
  }

  addSubject = async (image) => {
    const { newSubject, selector } = this.state;
    const { addNewSelector } = this.props;
    this.setState({ image: image });
    const addSelector = {
      name: newSubject,
      group: 'Subject',
      parentId: selector.grade,
      image: image
    };
    await addNewSelector(addSelector, () => {
      window.location.reload();
    });
  };
  addhandleTeacher = async () => {
    const { selectedTeacherId, selector } = this.state;
    const { addNewTeacherSelector } = this.props;
    const addSelector = {
      parentId: selector.subject,
      teacherId: selectedTeacherId
    };
    await addNewTeacherSelector(addSelector, () => {
      window.location.reload();
    });
  };
  updateSubject = async (img) => {
    const { newSubject, image, selector } = this.state;
    const { updateNewSelector } = this.props;
    const addSelector = {
      id: selector.subject,
      name: newSubject,
      group: 'Subject',
      parentId: selector.grade,
      image: img ? img : image
    };
    await updateNewSelector(addSelector, () => {
      window.location.reload();
    });
  };
  updatehandleTeacher() {
    const { newTeacher, preId, preParent, selectedTeacherId } = this.state;
    const { updateNewSelector } = this.props;
    const addSelector = {
      id: selectedTeacherId,
      name: newTeacher,
      group: 'Teacher',
      parent: preParent,
      parentId: preId
    };
    updateNewSelector(addSelector);
  }
  deleteSubject = async () => {
    const { DeleteNewSelector } = this.props;
    const { selector } = this.state;
    await DeleteNewSelector(selector.subject, () => {
      window.location.reload();
    });
  };
  handleTeacherChange(e, teacher) {
    const { selector } = this.state;
    e.preventDefault();
    if (!teacher || !teacher.id) return;
    selector['teacher'] = teacher.id;
    this.setState({ selectedTeacherId: teacher.id, newTeacher: teacher.fullName, selector });
  }
  deleteTeacher = async () => {
    const { DeleteNewSelector } = this.props;
    const { selector } = this.state;
    await DeleteNewSelector(selector.teacher);
    window.location.reload();
  };
  handleLocalization(heading) {
    const { local } = this.state;
    if (heading == 'selectors') {
      if (local == 'sinhala') {
        return 'තේරීම්කරු';
      } else if (local == 'tamil') {
        return 'தேர்வாளர்';
      } else {
        return 'Selectors';
      }
    } else if (heading == 'add_selector') {
      if (local == 'sinhala') {
        return 'නව තේරීම් එකතු කරන්න';
      } else if (local == 'tamil') {
        return 'புதிய தேர்வாளர்களைச் சேர்க்கவும்';
      } else {
        return 'Add New Selectors';
      }
    } else if (heading == 'update_selector') {
      if (local == 'sinhala') {
        return 'යාවත්කාලීන තේරීම';
      } else if (local == 'tamil') {
        return 'தேர்வாளரைப் புதுப்பிக்கவும்';
      } else {
        return 'Update Selector';
      }
    } else if (heading == 'delete_selector') {
      if (local == 'sinhala') {
        return 'තේරීම මකන්න';
      } else if (local == 'tamil') {
        return 'தேர்வியை நீக்கு';
      } else {
        return 'Delete Selector';
      }
    }
  }

  render() {
    const {
      Language,
      Subject,
      Grade,
      navigate,
      newSubject,
      Teacher,
      selectedTeacherId,
      selector,
      subjectVal,
      selectedTab
    } = this.state;
    const { teachers } = this.props;
    return (
      <Fragment>
        <DashboardLayout>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Card
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                width: '63%',
                borderRadius: '15px',
                backgroundColor: '#fff'
              }}
            >
              <CardContent
                style={{
                  border: '1px solid #186CEE',
                  borderRadius: '15px',
                  padding: '20px'
                }}
              >
                <h1>{this.handleLocalization('selectors')}</h1>
                <Tabs
                  value={selectedTab}
                  onChange={this.handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Tab
                    value="add"
                    label={this.handleLocalization('add_selector')}
                    sx={{
                      flex: 1,
                      textAlign: 'center'
                    }}
                  />
                  <Tab
                    value="update"
                    label={this.handleLocalization('update_selector')}
                    sx={{
                      flex: 1,
                      textAlign: 'center'
                    }}
                  />
                  <Tab
                    value="delete"
                    label={this.handleLocalization('delete_selector')}
                    sx={{
                      flex: 1,
                      textAlign: 'center'
                    }}
                  />
                </Tabs>
              </CardContent>
            </Card>
          </div>
          {navigate == 'add' ? (
            <Addselector
              Language={Language}
              Subject={Subject}
              Grade={Grade}
              teachers={teachers}
              selector={selector}
              newSubject={newSubject}
              selectedTeacherId={selectedTeacherId}
              handleChangeClick={this.handleChangeClick}
              handleSubjectChange={this.handleSubjectChange}
              handleTeacherChange={this.handleTeacherChange}
              addhandleTeacher={this.addhandleTeacher}
              addSubject={this.addSubject}
            />
          ) : navigate == 'update' ? (
            <UpdateSelector
              Language={Language}
              Subject={Subject}
              Grade={Grade}
              subject={newSubject}
              newSubject={newSubject}
              selector={selector}
              subjectVal={subjectVal}
              handleChangeClick={this.handleChangeClick}
              handleSubjectChange={this.handleSubjectChange}
              updateSubject={this.updateSubject}
            />
          ) : (
            <DeleteSelector
              Language={Language}
              Subject={Subject}
              Grade={Grade}
              Teacher={Teacher}
              selector={selector}
              handleChangeClick={this.handleChangeClick}
              handleSubjectChange={this.handleSubjectChange}
              deleteSubject={this.deleteSubject}
              deleteTeacher={this.deleteTeacher}
            />
          )}
        </DashboardLayout>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  cascadeFilterResult: state.filter.cascadeFilterResult,
  teachers: state.examPaper.teachers,
  selectedLanguage: state.language.selectedLanguage
});
const mapDispatchToProps = {
  cascadeFilter,
  addNewSelector,
  updateNewSelector,
  DeleteNewSelector,
  addNewTeacherSelector,
  fetchAllTeachers
};
export default connect(mapStateToProps, mapDispatchToProps)(HandleSelector);
