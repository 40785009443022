import React, { Component } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { connect } from 'react-redux';
import { getNetTeacherEarnings } from '../../actions/userAction';
import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Box,
  Chip
} from '@mui/material';

class Income extends Component {
  constructor() {
    super();
    this.state = {
      income: []
    };
  }

  componentDidMount() {
    const { getNetTeacherEarnings } = this.props;
    getNetTeacherEarnings();
  }

  componentDidUpdate(prevProps) {
    const { netInCome, selectedLanguage } = this.props;
    if (prevProps.netInCome !== netInCome) {
      this.setState({ income: netInCome });
    }
    if (selectedLanguage !== prevProps.selectedLanguage) {
      this.setState({ local: selectedLanguage });
    }
  }

  computeNetMonthlyIncome(monthlyIncome) {
    return Object.keys(monthlyIncome).reduce((acc, month) => {
      const adjustedIncome = monthlyIncome[month] - 100 >= 0 ? monthlyIncome[month] - 100 : 0;
      acc[month] = adjustedIncome;
      return acc;
    }, {});
  }

  computeAnnualNetIncome(netMonthlyIncome) {
    return Object.values(netMonthlyIncome).reduce((acc, curr) => acc + curr, 0);
  }

  renderMonthlyIncome(monthlyIncome) {
    return Object.keys(monthlyIncome).map((month) => (
      <Box
        key={month}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {month}:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {monthlyIncome[month] || 0}
        </Typography>
      </Box>
    ));
  }

  renderNetMonthlyIncome(netMonthlyIncome) {
    return Object.keys(netMonthlyIncome).map((month) => (
      <Box
        key={month}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {month}:{}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {netMonthlyIncome[month]}
        </Typography>
      </Box>
    ));
  }
  handleLocalization(heading) {
    const { local } = this.state;
    if (heading == 'teacher_income') {
      if (local == 'sinhala') {
        return 'ගුරු ආදායම් උපකරණ පුවරුව';
      } else if (local == 'tamil') {
        return 'ஆசிரியர் வருமான டாஷ்போர்டு';
      } else {
        return 'Teacher Income Dashboard';
      }
    }
    if (heading == 'teacher_income-description') {
      if (local == 'sinhala') {
        return 'පහත දැක්වෙන්නේ ඔබගේ මාසික ආදායම සහ මුළු ඉපැයීම් වල බිඳ වැටීමයි.';
      } else if (local == 'tamil') {
        return 'ஆசிரியர் வருமான டாஷ்போர்டு';
      } else {
        return ' Below is the breakdown of your monthly income and total earnings.';
      }
    }
    if (heading == 'year') {
      if (local == 'sinhala') {
        return 'වසර';
      } else if (local == 'tamil') {
        return 'ஆண்டு';
      } else {
        return 'Year';
      }
    }
    if (heading == 'monthly_income') {
      if (local == 'sinhala') {
        return 'මාසික ආදායම';
      } else if (local == 'tamil') {
        return 'மாத வருமானம்';
      } else {
        return 'Monthly Income';
      }
    }
    if (heading == 'net_monthly_income') {
      if (local == 'sinhala') {
        return 'ශුද්ධ මාසික ආදායම';
      } else if (local == 'tamil') {
        return 'நிகர மாத வருமானம்';
      } else {
        return 'Monthly Income';
      }
    }
    if (heading == 'total_annual') {
      if (local == 'sinhala') {
        return 'මුළු වාර්ෂික ආදායම';
      } else if (local == 'tamil') {
        return 'மொத்த ஆண்டு வருமானம்';
      } else {
        return 'Total Annual Income';
      }
    }
    if (heading == 'total_net_annual') {
      if (local == 'sinhala') {
        return 'මුළු ශුද්ධ වාර්ෂික ආදායම';
      } else if (local == 'tamil') {
        return 'மொத்த நிகர ஆண்டு வருமானம்';
      } else {
        return 'Total Net Annual Income';
      }
    }
  }
  render() {
    const { income } = this.state;

    return (
      <DashboardLayout>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {this.handleLocalization('teacher_income')}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          {this.handleLocalization('teacher_income-description')}
        </Typography>
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {this.handleLocalization('year')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {this.handleLocalization('monthly_income')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {this.handleLocalization('net_monthly_income')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {income.map((eachIncome, index) => {
                const netMonthlyIncome = this.computeNetMonthlyIncome(eachIncome.monthlyIncome);
                const annualNetIncome = this.computeAnnualNetIncome(netMonthlyIncome);

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Chip label={eachIncome.year} color="primary" variant="outlined" />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ padding: 1 }}>
                        {this.renderMonthlyIncome(eachIncome.monthlyIncome)}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ mt: 2 }}
                        >
                          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                            {this.handleLocalization('total_annual')}
                          </Typography>
                          <Typography variant="subtitle2" color="text.primary">
                            Rs.{' '}
                            {Object.values(eachIncome.monthlyIncome).reduce(
                              (acc, curr) => acc + (curr || 0),
                              0
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ padding: 1 }}>
                        {this.renderNetMonthlyIncome(netMonthlyIncome)}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ mt: 2 }}
                        >
                          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                            {this.handleLocalization('total_net_annual')}
                          </Typography>
                          <Typography variant="subtitle2" color="secondary">
                            Rs. {annualNetIncome}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  netInCome: state.user.netInCome,
  selectedLanguage: state.language.selectedLanguage
});

const mapDispatchToProps = {
  getNetTeacherEarnings
};

export default connect(mapStateToProps, mapDispatchToProps)(Income);
