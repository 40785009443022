import { Route } from 'react-router-dom';
import keycloakService from '../keycloakService';
import Welcome from '../components/Welcome';

const PrivateElement = ({ Component, requiredRoles, ...rest }) => {
  const isAuthenticated = keycloakService.isLoggedIn();
  console.log('isAuthenticated', isAuthenticated);
  const userHasRequiredRole = keycloakService.hasRole(requiredRoles);
  console.log('userHasRequiredRole', userHasRequiredRole);
  return isAuthenticated && userHasRequiredRole ? <Component {...rest} /> : <Welcome />;
};

const PrivateRoute = ({ component, requiredRoles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return <PrivateElement {...props} Component={component} requiredRoles={requiredRoles} />;
      }}
    />
  );
};

export default PrivateRoute;
