import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  table: {
    padding: 2,
    minWidth: 650
  },
  header: {
    margin: 5,
    padding: 5
  }
});

export default function StudentDetailsTable(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const { userData } = props;

  return (
    <TableContainer style={{ marginTop: '10px', width: '75%' }} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead style={{ border: '1px solid #186CEE', borderRadius: '10px' }}>
          <TableRow>
            <TableCell>{t('first_name')}</TableCell>
            <TableCell>{t('last_name')}</TableCell>
            <TableCell>{t('email')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.USERS_FOUND &&
            userData.USERS_FOUND.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.firstName}</TableCell>
                <TableCell>{row.lastName}</TableCell>
                <TableCell>{row.email}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
