import React from 'react';
import './Chart.css';
import ApexChart from 'react-apexcharts';

const Chart = ({ title, options, series, type, width }) => {
  return (
    <div className="chart">
      <div className="top">
        <h1 className="title">{title}</h1>
      </div>
      <ApexChart options={options} series={series} type={type} width={width} />
    </div>
  );
};

export default Chart;
