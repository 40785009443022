import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { fetchAcceptEssayPaperRequest } from '../../actions/CorrectorAction';
import { useDispatch, useSelector } from 'react-redux';
import CurrentWorkSubject from './CurrentWorkSubject';
import CurrentWorkPapers from './CurrentWorkPapers';
import AnnotationResult from '../../containers/Annotation';
import { useTranslation } from 'react-i18next';

function CorrectorCurrentWork() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const [showEachPaper, setShowEachPaper] = useState(true);
  const [essayAnswers, setEssayAnswers] = useState('');

  const [examId, setExamId] = useState('');
  const acceptEssayPaperReaquest = useSelector(
    (state) => state.essayPaperRequest.acceptEssayPaperRequest
  );
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchAcceptEssayPaperRequest());
    };
    fetchData();
  }, []);
  const handleClick = async (id) => {
    setExamId(id);
    setShowEachPaper(false);
  };
  const handleResultPage = (acceptPapers) => {
    setEssayAnswers(acceptPapers);
  };
  return (
    <DashboardLayout>
      {essayAnswers == '' ? (
        <Card>
          <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <h2
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px'
                  }}
                >
                  {t('corrector_accepted_papers')}
                </h2>
              </Grid>
              {showEachPaper == true ? (
                <CurrentWorkSubject
                  acceptEssayPaperReaquest={acceptEssayPaperReaquest}
                  handleClick={handleClick}
                />
              ) : (
                <CurrentWorkPapers
                  examId={examId}
                  handleResultPage={handleResultPage}
                  acceptEssayPaperReaquest={acceptEssayPaperReaquest}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <AnnotationResult essayAnswers={essayAnswers} />
      )}
    </DashboardLayout>
  );
}

export default CorrectorCurrentWork;
