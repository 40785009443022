import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormControl } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Selector from '../../Selector';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const DetailsEdit = (props) => {
  const {
    handleChange,
    handleChangeClick,
    handleSubjectChange,
    examPaper,
    errors,
    Language,
    Grade,
    Subject,
    categoryOptionsTemp,
    classes,
    selectedSubject
  } = props;
  console.log('Grade', Grade.slice(0, 3));
  return (
    <div style={{ marginTop: '50px' }}>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.lable} required>
            Language
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <FormControl
            style={{
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px',
              width: '400px'
            }}
          >
            <Selector
              name="language"
              onChange={handleChangeClick}
              options={Language}
              placeHolderText="select language"
              error={!!errors.language}
              value={examPaper.language}
            />
          </FormControl>
          <FormHelperText id="language-helper-text">{errors.language || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            Title
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <OutlinedInput
            name="title"
            value={examPaper.title}
            onChange={handleChange}
            placeholder=""
            inputProps={{ 'aria-label': 'title' }}
            style={{ width: '420px' }}
            error={!!errors.title}
          />
          <FormHelperText id="title-helper-text">{errors.title || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            Grade
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <FormControl
            style={{
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px',
              width: '400px'
            }}
          >
            <Selector
              name="grade"
              onChange={handleChangeClick}
              options={Grade.slice(0, 3)}
              placeHolderText="select grade"
              error={!!errors.grade}
              value={examPaper.grade}
            />
          </FormControl>
          <FormHelperText id="title-helper-text">{errors.grade || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            Subject
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <Autocomplete
            id="subject"
            name="subject"
            options={Subject}
            getOptionLabel={(option) => option.value || ''}
            style={{ width: '420px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="select Subject"
                variant="outlined"
                error={!!errors.subject}
              />
            )}
            onChange={handleSubjectChange}
            value={selectedSubject}
          />
          <FormHelperText id="title-helper-text">{errors.subject || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            Category
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <FormControl
            style={{
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px',
              width: '400px'
            }}
          >
            <Selector
              name="category"
              onChange={handleChange}
              options={categoryOptionsTemp}
              placeHolderText="select Subject"
              error={!!errors.category}
              value={examPaper.category}
            />
          </FormControl>
          <FormHelperText id="title-helper-text">{errors.category || ''}</FormHelperText>
        </Grid>
      </Grid>
    </div>
  );
};

export default DetailsEdit;
