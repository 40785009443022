import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { promoCodeGenerating } from '../../actions/pointAction';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputLabel,
  OutlinedInput,
  makeStyles
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  right: {
    margin: '4px',
    marginTop: '10px'
  },
  bold: {
    fontWeight: 'bold'
  }
}));
const promo = {
  promoCode: '',
  promoAmount: 0,
  usageLimit: 0
};
function PromoCodeSubscription() {
  const [promoCode, setPromoCode] = useState(promo);
  const dispatch = useDispatch();
  const [disable, setdisable] = useState(true);
  const [message, setMessage] = useState({ status: '', msg: '' });
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'promoAmount' || name === 'usageLimit') {
      if (/^\d*$/.test(value)) {
        promoCode[name] = value;
        setPromoCode(promo);
      } else {
        setMessage({
          msg: 'please_numeric_value',
          status: 'error'
        });
        setTimeout(() => {
          setMessage({});
        }, 3000);
      }
    } else {
      promoCode[name] = value;
      setPromoCode(promo);
    }
    if (promoCode.promoCode != '' && promoCode.promoAmount != 0 && promoCode.usageLimit != 0) {
      setdisable(false);
    }
  };
  const handlePromoCode = () => {
    try {
      dispatch(
        promoCodeGenerating(promoCode, (res) => {
          setMessage({
            msg: res,
            status: 'success'
          });

          setTimeout(() => {
            setMessage({});
          }, 3000);
        })
      );
    } catch {
      console.error('something wrong');
    } finally {
      setPromoCode({});
    }
  };
  return (
    <DashboardLayout>
      <Card
        style={{
          border: '1px solid #186CEE',
          margin: '10% 20% 0 20%',
          borderRadius: '15px'
        }}
      >
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <form className={classes.root} noValidate autoComplete="off">
            <h2
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px'
              }}
            >
              {t('email_base_point_topup')}
            </h2>
            <Divider />
            <Grid className={classes.container} container spacing={3}>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>{t('promo_code')}</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="promoCode"
                  onChange={handleChange}
                  placeholder={t('enter_promo')}
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>{t('promo_amount')}</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="promoAmount"
                  onChange={handleChange}
                  placeholder={t('enter_promo_amount')}
                  // inputProps={{ 'aria-label': 'promoCode' }}
                  inputProps={{
                    'aria-label': 'promoCode',
                    inputMode: 'numeric' // For better mobile input experience
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <InputLabel className={classes.bold}>{t('usage_limit')}</InputLabel>
              </Grid>
              <Grid item xs={6} sm={8}>
                <OutlinedInput
                  name="usageLimit"
                  onChange={handleChange}
                  placeholder={t('enter_promo_limit')}
                  inputProps={{ 'aria-label': 'promoCode' }}
                  fullWidth
                />
              </Grid>
            </Grid>
            {message.msg != '' && (
              <p style={{ color: message.status === 'success' ? 'green' : 'red' }}>
                {t(message.msg)}
              </p>
            )}
            <Grid
              container
              spacing={3}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Grid item xs={6} sm={6}>
                <Button
                  fullWidth
                  onClick={() => handlePromoCode()}
                  disabled={disable}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #186CEE',
                    padding: '10px',
                    height: '40px',
                    margin: '0 auto',
                    borderRadius: '15px',
                    color: !disable ? 'white' : 'black',
                    backgroundColor: !disable ? '#186CEE' : 'white',
                    cursor: !disable ? 'pointer' : 'not-allowed',
                    textAlign: 'center'
                  }}
                >
                  {t('create')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default PromoCodeSubscription;
