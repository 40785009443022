import React from 'react';
import Header from '../components/Header/DashboardHeader';
import SideBar from '../components/SideBar/SideBar';
import './layout.css';

export default function TeacherDashboardLayout(props) {
  return (
    <div className="home">
      <SideBar />
      <main className="homeContainer">
        <Header />
        <div className="children-top">{props.children}</div>
      </main>
    </div>
  );
}
