import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { decimalNumbersOnly, ROLE, numbersOnly } from '../../../utils/helper';
import keycloakService from '../../../keycloakService';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Price = (props) => {
  const {
    handleChange,
    handleTypistChange,
    examPaper,
    typists,
    allTeachers,
    selectedTypist,
    handleTeacherChange,
    selectedTeacher,
    handleCorrectorChange,
    errors,
    finalPrice,
    availableCorrector,
    selectedCorrector,
    classes
  } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);
  return (
    <div>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('price')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={4}>
          <OutlinedInput
            name="price"
            value={examPaper.price}
            onChange={handleChange}
            placeholder=""
            inputProps={{ 'aria-label': 'price' }}
            style={{ width: '420px' }}
            error={!!errors.price}
            onKeyPress={decimalNumbersOnly}
          />
          <FormHelperText id="title-helper-text">{errors.price || ''}</FormHelperText>
          <InputLabel style={{ marginTop: '5px' }}>
            <span style={{ fontSize: '14px' }}>
              {t('final_price')}
              {finalPrice}
            </span>
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={4}></Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('typist')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <Autocomplete
            id="typist-id"
            name="typist"
            options={typists}
            getOptionLabel={(option) => option.fullName}
            style={{ width: '420px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Typist"
                variant="outlined"
                error={!!errors.typist}
              />
            )}
            onChange={handleTypistChange}
            value={selectedTypist}
          />
          <FormHelperText id="title-helper-text">{errors.typist || ''}</FormHelperText>
          {/*<CSVFileReader />*/}
        </Grid>
        {examPaper.typist && (
          <>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>{t('typing_days')}</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <OutlinedInput
                name="noOfTypingDays"
                value={examPaper.noOfTypingDays}
                onChange={handleChange}
                placeholder=""
                inputProps={{ 'aria-label': 'no of typing days' }}
                fullWidth
                error={!!errors.noOfTypingDays}
                onKeyPress={numbersOnly}
                style={{ width: '420px' }}
              />
              <FormHelperText id="title-helper-text">{errors.noOfTypingDays || ''}</FormHelperText>
            </Grid>
          </>
        )}
        {examPaper.category == 'ESSAY' && (
          <>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>{t('corrector')}</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <Autocomplete
                id="corrector-id"
                name="corrector"
                options={availableCorrector}
                getOptionLabel={(option) => option.fullName}
                style={{ width: '420px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Corrector"
                    variant="outlined"
                    error={!!errors.corrector}
                  />
                )}
                onChange={handleCorrectorChange}
                value={selectedCorrector}
              />
              <FormHelperText id="title-helper-text">{errors.corrector || ''}</FormHelperText>
              {/*<CSVFileReader />*/}
            </Grid>
          </>
        )}
        {hasAdminRole && (
          <>
            <Grid item xs={6} sm={4}>
              <InputLabel className={classes.label}>{t('teacher')}</InputLabel>
            </Grid>
            <Grid item xs={6} sm={8}>
              <Autocomplete
                id="teacher-id"
                options={allTeachers}
                getOptionLabel={(option) => option.fullName}
                style={{ width: '420px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Teacher"
                    variant="outlined"
                    error={!!errors.teacher}
                  />
                )}
                onChange={handleTeacherChange}
                value={selectedTeacher}
              />
              <FormHelperText id="title-helper-text">{errors.teacher || ''}</FormHelperText>
              {/*<CSVFileReader />*/}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Price;
