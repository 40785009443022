import {
  FETCH_EXAMS_PAPERS,
  FETCH_EXAM_PAPER_BY_ID,
  FETCH_EXAM_QUESTION_BY_ID,
  FETCH_ALL_TEACHERS,
  FETCH_TEACHER_EXAM_PAPERS,
  FETCH_ALL_QUESTIONS,
  FETCH_EXAM_PAPERS,
  FETCH_ALL_TYPISTS,
  FETCH_VALIDATED_USER_LIST,
  FETCH_TEACHER_PAPERS_BY_EXAM_STATUS,
  FETCH_PUBLISHED_PAPERS,
  CLEAR_VALID_USER_LIST,
  CLEAR_EXAM_PAPER_DETAILS,
  FETCH_OVERALL_EXAM_PAPERS_STAT,
  FETCH_EXAM_PAPER_STAT_BY_PAPER_ID,
  FETCH_EXAM_PAPER_STAT_BY_BATCH,
  FETCH_TEACHER_FINAL_PRICE,
  TEST_GROUP_QUESTION
} from '../utils/type';

const initialState = {
  examPapers: {
    content: []
  },
  examPaperDetails: {},
  questionDetails: {},
  questions: [],
  teachers: [],
  teacherExamPapers: [],
  pendingExamPapers: [],
  typists: [],
  validatedUserData: {},
  publishedPapers: [],
  overallExamPapersStat: {},
  examPaperStat: {},
  batchResults: {},
  teacherFinalPrice: '',
  grpQuestion: []
};

const examPaperReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXAMS_PAPERS:
      return {
        ...state,
        examPapers: action.data
      };
    case FETCH_EXAM_PAPER_BY_ID:
      return {
        ...state,
        examPaperDetails: action.data
      };
    case FETCH_EXAM_QUESTION_BY_ID:
      return {
        ...state,
        questionDetails: action.data
      };
    case FETCH_ALL_TEACHERS:
      return {
        ...state,
        teachers: action.data
      };
    case FETCH_TEACHER_EXAM_PAPERS:
    case FETCH_TEACHER_PAPERS_BY_EXAM_STATUS:
      return {
        ...state,
        teacherExamPapers: action.data
      };
    case FETCH_EXAM_PAPERS:
      return {
        ...state,
        pendingExamPapers: action.data
      };
    case FETCH_ALL_QUESTIONS:
      return {
        ...state,
        questions: action.data
      };
    case FETCH_ALL_TYPISTS:
      return {
        ...state,
        typists: action.data
      };
    case FETCH_VALIDATED_USER_LIST:
      return {
        ...state,
        validatedUserData: action.data
      };
    case FETCH_PUBLISHED_PAPERS:
      return {
        ...state,
        publishedPapers: action.data
      };
    case CLEAR_VALID_USER_LIST:
      return {
        ...state,
        validatedUserData: []
      };
    case CLEAR_EXAM_PAPER_DETAILS:
      return {
        ...state,
        examPaperDetails: {}
      };
    case FETCH_OVERALL_EXAM_PAPERS_STAT:
      return {
        ...state,
        overallExamPapersStat: action.data
      };
    case FETCH_EXAM_PAPER_STAT_BY_PAPER_ID:
      return {
        ...state,
        examPaperStat: action.data
      };
    case FETCH_EXAM_PAPER_STAT_BY_BATCH:
      return {
        ...state,
        batchResults: action.data
      };
    case FETCH_TEACHER_FINAL_PRICE:
      return {
        ...state,
        teacherFinalPrice: action.data
      };
    case TEST_GROUP_QUESTION:
      return {
        ...state,
        grpQuestion: action.data
      };
    default:
      return state;
  }
};

export default examPaperReducer;
