/* eslint-disable no-undef */
import keycloakService from '../keycloakService';
export const ROLE = {
  Student: 'student',
  Teacher: 'teacher',
  Typist: 'typist',
  Tutor: 'tutor',
  Admin: 'admin',
  Corrector: 'corrector'
};

export const isStudent = () => {
  return keycloakService && keycloakService.hasRole([ROLE.Student]);
};

export const isTeacher = () => {
  return keycloakService && keycloakService.hasRole([ROLE.Teacher]);
};

export const isTypist = () => {
  return keycloakService && keycloakService.hasRole([ROLE.Typist]);
};

export const isTutor = () => {
  return keycloakService && keycloakService.hasRole([ROLE.Tutor]);
};
export const isCorrector = () => {
  return keycloakService && keycloakService.hasRole([ROLE.Corrector]);
};

export const API_URL = process.env.REACT_APP_API_URL;
export const KEYCLOAK_SERVER_BASE_URL = process.env.REACT_APP_KEYCLOAK_SERVER_BASE_URL;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_SERVER_CLIENT_ID;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_SERVER_REALM;

export const authHeader = (token) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  if (token) {
    headers.headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export const authMutliFormHeader = (token) => {
  const headers = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  if (token) {
    headers.headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export const appendQueryParams = (requestUrl, selectedValuesObj) => {
  const url = new URL(requestUrl);
  Object.keys(selectedValuesObj).map((key) => {
    const value = selectedValuesObj[key];
    url.searchParams.append(key, value);
  });
  return url;
};

export const findTeacher = (teachers, examPaper) => {
  let teacherObj = {};
  if (teachers && teachers.length > 0) {
    const teacherId = examPaper.teacher;
    if (teacherId) {
      teacherObj = teachers.find((teacher) => teacher.id === teacherId);
    }
  }
  return teacherObj;
};

export const findTypist = (typists, examPaper) => {
  let typistObj = {};
  if (typists && typists.length > 0) {
    const typistId = examPaper.typist;
    if (typistId) {
      typistObj = typists.find((typist) => typist.id === typistId);
    }
  }
  return typistObj;
};
export const findCorrector = (correctors, examPaper) => {
  let correctorObj = {};
  if (correctors && correctors.length > 0) {
    const correctorId = examPaper.corrector;
    if (correctorId) {
      correctorObj = correctors.find((corrector) => corrector.id === correctorId);
    }
  }
  return correctorObj;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password) => {
  const validString = /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const passwordObj = {
    isValid: true,
    message: ''
  };
  if (!password) {
    passwordObj.isValid = false;
    passwordObj.message = 'Please enter your password';
  }
  if (password.length < 8) {
    passwordObj.isValid = false;
    passwordObj.message = 'Your password should have minimum 8 characters';
  }
  if (!validString.test(password)) {
    passwordObj.isValid = false;
    passwordObj.message =
      'Your password should have atleast a special charater, upper and lower case letters';
  }
  return passwordObj;
};

export const validateUser = (userData) => {
  let isValid = true;
  const validationErrors = {};
  const userPassword = validatePassword(userData.password);
  if (!userData.firstName) {
    isValid = false;
    validationErrors.firstName = 'please enter your first name';
  }
  if (!userData.lastName) {
    isValid = false;
    validationErrors.lastName = 'please enter your last name';
  }
  if (!userData.userName) {
    isValid = false;
    validationErrors.userName = 'please enter your user name';
  }
  if (!userData.role) {
    isValid = false;
    validationErrors.role = 'please select your role';
  }
  if (!validateEmail(userData.email)) {
    isValid = false;
    validationErrors.email = 'please enter a valid email';
  }
  if (!userPassword.isValid) {
    isValid = false;
    validationErrors.password = userPassword.message;
  }
  if (userPassword.isValid && userData.password !== userData.confirmPassword) {
    isValid = false;
    validationErrors.confirmPassword = "Password doesn't match";
  }
  return { isValid, validationErrors };
};

export const validateExamPaper = (examData, role, examType) => {
  let isValid = true;
  const validationErrors = {};
  if (!examData.title) {
    isValid = false;
    validationErrors.title = 'please enter the title';
  }
  if (!examData.language) {
    isValid = false;
    validationErrors.language = 'please select the language';
  }
  if (!examData.grade) {
    isValid = false;
    validationErrors.grade = 'please select the grade';
  }
  if (!examData.subject) {
    isValid = false;
    validationErrors.subject = 'please select the subject';
  }
  if (!examData.category) {
    isValid = false;
    validationErrors.category = 'please select the category';
  }
  if (!examData.noOfQuestions) {
    isValid = false;
    validationErrors.noOfQuestions = 'please enter the no of questions';
  }
  if (!examData.noOfTypingDays) {
    isValid = false;
    validationErrors.noOfTypingDays = 'please enter the no of typing days';
  }
  if (!examData.noOfAnswers && examType == 'MCQ') {
    isValid = false;
    validationErrors.noOfAnswers = 'please enter the no of answers for each question';
  }
  if (!examData.durationInMinute) {
    isValid = false;
    validationErrors.durationInMinute = 'please enter the exam duration';
  }
  if (!examData.price) {
    isValid = false;
    validationErrors.price = 'please enter the price';
  }
  if (!examData.description) {
    isValid = false;
    validationErrors.description = 'please enter the instructions';
  }
  if (!examData.language) {
    isValid = false;
    validationErrors.language = 'please enter the language';
  }
  if (!examData.typist) {
    isValid = false;
    validationErrors.typist = 'please enter the typist';
  }
  if (!examData.teacher && role) {
    isValid = false;
    validationErrors.teacher = 'please Select the teacher';
  }
  if (!examData.corrector && examType != 'MCQ') {
    isValid = false;
    validationErrors.corrector = 'please Select the Corrector';
  }
  return { isValid, validationErrors };
};

export const numbersOnly = (event) => {
  if (!(event.charCode >= 48 && event.charCode <= 57)) {
    event.preventDefault();
  }
};

export const decimalNumbersOnly = (e) => {
  if (
    !(
      e.charCode === 0 ||
      (e.charCode >= 48 && e.charCode <= 57) ||
      (e.charCode === 46 && e.target.value.indexOf('.') < 0)
    )
  ) {
    e.preventDefault();
  }
};

export const convertTimeDigitToString = (digit) => {
  return digit < 10 ? '0' + digit.toString() : digit.toString();
};

export const hrs = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];

export const sec = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59'
];

export const mins = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59'
];
