import React, { Fragment, Component } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import moment from 'moment';
import DashboardLayout from '../../layouts/DashboardLayout';
import CreateForm from '../../components/ExamPaper/CreateForm';
import EditForm from '../../components/ExamPaper/EditForm';
import keycloakService from '../../keycloakService';
import { fetchSubscribedTypist } from '../../actions/typistAction';
import { fetchCorrectorSubscribeRequest } from '../../actions/CorrectorAction';
import {
  createExamPaper,
  updateExamPaper,
  fetchExamPaperById,
  fetchFinalTeacherPrice,
  clearExamPaperDetails,
  fetchAllTeachers
} from '../../actions/examPaperAction';
import { cascadeFilter, getSelecterAll } from '../../actions/filterAction';
import {
  findTypist,
  validateExamPaper,
  convertTimeDigitToString,
  findCorrector,
  findTeacher,
  ROLE
} from '../../utils/helper';

const questionInitialBlock = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];

const EXAM_DATA = {
  title: '',
  language: '',
  grade: '',
  subject: '',
  category: '',
  noOfQuestions: '',
  noOfAnswers: '',
  durationInMinute: '',
  typist: '',
  noOfTypingDays: '',
  corrector: '',
  teacher: '',
  price: '',
  description: '',
  scheduledTime: new Date(),
  edited: true,
  status: 'DRAFT',
  scheduled: true,
  public: true,
  pastPaper: true,
  coverImage: ''
};

const findSubject = (subjects, examPaper) => {
  let subjectObj = {};
  if (subjects && subjects.length > 0) {
    const subjectId = examPaper.subject;
    if (subjectId) {
      subjectObj = subjects.find((sub) => sub.id === subjectId);
    }
  }
  return subjectObj;
};

class PaperCreateContainer extends Component {
  constructor() {
    super();
    this.state = {
      examPaper: EXAM_DATA,
      submitting: false,
      selectedExamPaperId: '',
      errors: {},
      durationInHrs: 0,
      durationInMins: 0,
      allowpublic: true,
      allowpastPaper: true,
      initialValue: questionInitialBlock,
      Language: '',
      Grade: '',
      Subject: '',
      selectedTeacher: '',
      finalPrice: '0.00'
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.adminhandleCreate = this.adminhandleCreate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeClick = this.handleChangeClick.bind(this);
    this.handlePublic = this.handlePublic.bind(this);
    this.handlePublicedit = this.handlePublicedit.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleTypistChange = this.handleTypistChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleChangeDuration = this.handleChangeDuration.bind(this);
    this.setTotoalDurationInMinutes = this.setTotoalDurationInMinutes.bind(this);
    this.setDurationInHrsAndMinutes = this.setDurationInHrsAndMinutes.bind(this);
    this.handleTeacherChange = this.handleTeacherChange.bind(this);
    this.handleCorrectorChange = this.handleCorrectorChange.bind(this);
    this.handlePastPapers = this.handlePastPapers.bind(this);
  }

  async componentDidMount() {
    let { selectedExamPaperId } = this.state;
    const {
      match,
      fetchExamPaperById,
      fetchSubscribedTypist,
      fetchCorrectorSubscribeRequest,
      getSelecterAll,
      cascadeFilter,
      fetchAllTeachers
    } = this.props;
    const { examPaperId } = match.params;
    selectedExamPaperId = examPaperId ? examPaperId : '';
    if (selectedExamPaperId) {
      fetchExamPaperById(selectedExamPaperId);
      this.setState({ selectedExamPaperId });
    }
    fetchCorrectorSubscribeRequest();
    fetchSubscribedTypist();
    getSelecterAll();
    cascadeFilter('root');
    fetchAllTeachers();
  }

  componentDidUpdate(preProps) {
    let { examPaper, selectedExamPaperId } = this.state;
    const {
      examPaperDetails,
      match,
      clearExamPaperDetails,
      cascadeFilterResult,
      finalTeacherPrice
      //availableCorrector
    } = this.props;
    const { examPaperId } = match.params;
    if (examPaperId !== selectedExamPaperId) {
      clearExamPaperDetails();
      this.setState({
        selectedExamPaperId: examPaperId,
        examPaper: EXAM_DATA,
        submitting: false,
        errors: {},
        durationInHrs: 0,
        durationInMins: 0
      });
    }
    if (preProps.examPaperDetails !== examPaperDetails) {
      this.setDurationInHrsAndMinutes(examPaperDetails.durationInMinute);
      examPaper = examPaperDetails;
      examPaper['public'] = true;
      examPaper['pastPaper'] = true;
      examPaper.scheduledTime = examPaperDetails.scheduledTime
        ? moment(examPaperDetails.scheduledTime).format('YYYY-MM-DDTHH:mm')
        : new Date();
      this.setState({
        examPaper,
        initialValue: examPaper.description
          ? JSON.parse(examPaper.description)
          : questionInitialBlock,
        finalPrice: examPaper.sellingPrice
      });
    }
    if (preProps.cascadeFilterResult !== cascadeFilterResult) {
      this.setState({ allFilter: cascadeFilterResult });
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Language') {
        this.setState({ Language: cascadeFilterResult });
      }
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Grade') {
        this.setState({ Grade: cascadeFilterResult });
      }
      if (cascadeFilterResult.length >= 1 && cascadeFilterResult[0].group == 'Subject') {
        this.setState({ Subject: cascadeFilterResult });
      }
    }
    if (preProps.finalTeacherPrice !== finalTeacherPrice) {
      this.setState({ finalPrice: finalTeacherPrice });
    }
  }

  handleChange(e) {
    const { examPaper, errors } = this.state;
    const { fetchFinalTeacherPrice } = this.props;
    const { name, value } = e.target;

    examPaper[name] = value;
    errors[name] = '';
    this.setState({ examPaper, errors });
    if (name == 'price' && value != 0) {
      fetchFinalTeacherPrice(value == '' ? 0 : value);
    }
  }
  handleChangeClick(e) {
    const { examPaper, errors, Grade, Subject } = this.state;
    const { cascadeFilter } = this.props;
    const { name, value } = e.target;
    if (name == 'language' && Grade && Subject) {
      examPaper['subject'] = '';
      examPaper['grade'] = '';
      this.setState({ Subject: '', Grade: '', examPaper });
    } else if (name == 'grade' && Subject) {
      examPaper['subject'] = '';
      this.setState({ Subject: '', examPaper });
    }
    const selectedOption = e.target.options[e.target.selectedIndex];
    const keys = selectedOption.dataset.key;
    const image = selectedOption.dataset.img;
    examPaper[name] = value;
    errors[name] = '';
    examPaper['coverImage'] = image;
    this.setState({ examPaper, errors });
    cascadeFilter(keys);
  }
  handlePublicedit(e) {
    const { name } = e.target;
    const { examPaper } = this.state;
    examPaper[name] = !examPaper.public;
    const boolallowpublic = examPaper.public;
    this.setState({ examPaper, allowpublic: boolallowpublic });
  }
  handlePublic(e) {
    const { name } = e.target;
    const { examPaper, allowpublic } = this.state;
    examPaper[name] = !allowpublic;
    this.setState({ allowpublic: !allowpublic });
  }
  handlePastPapers(e) {
    const { name } = e.target;
    const { examPaper, allowpastPaper } = this.state;
    examPaper[name] = !allowpastPaper;
    this.setState({ examPaper, allowpastPaper: !allowpastPaper });
  }
  handleDescription(descript, val) {
    const { examPaper, errors } = this.state;
    const name = descript;
    const value = val;
    examPaper[name] = JSON.stringify(value);
    errors[name] = '';
    this.setState({ examPaper, errors, initialValue: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setTotoalDurationInMinutes();
    const { examPaper, selectedExamPaperId, initialValue, errors, allowpublic } = this.state;
    const { history, updateExamPaper, createExamPaper } = this.props;
    const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);
    const { isValid, validationErrors } = validateExamPaper(
      examPaper,
      hasAdminRole,
      examPaper.category
    );
    if (!isValid) {
      this.setState({ errors: validationErrors });
      return;
    }
    const userDetails = keycloakService.getUserDetails();
    const { sub } = keycloakService.getUserDetails('sub');
    const teacherName = userDetails.name;
    examPaper.teacher = sub;
    examPaper.author = keycloakService.getUserName();
    examPaper.scheduledTime = new Date(examPaper.scheduledTime).toISOString();
    examPaper.selectors = this.getSelectorsData(teacherName);
    examPaper.description = JSON.stringify(initialValue);
    examPaper.public = allowpublic;
    examPaper.examStatus = 'DRAFT';
    this.setState({ submitting: true, errors });
    if (selectedExamPaperId) {
      examPaper.id = selectedExamPaperId;
      updateExamPaper(examPaper, (res) => {
        examPaper.category == 'MCQ'
          ? history.push(`/exam-paper/${res.id}/questions`)
          : history.push(`/exam-paper/${res.id}/part_2-questions`);
      });
    } else {
      createExamPaper(examPaper, (res) => {
        examPaper.category == 'MCQ'
          ? history.push(`/exam-paper/${res.id}/questions`)
          : history.push(`/exam-paper/${res.id}/part_2-questions`);
      });
    }
  }
  adminhandleCreate(e) {
    e.preventDefault();
    this.setTotoalDurationInMinutes();
    const { examPaper, selectedExamPaperId, initialValue, errors, allowpublic, selectedTeacher } =
      this.state;
    const { history, updateExamPaper, createExamPaper } = this.props;
    const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);
    const { isValid, validationErrors } = validateExamPaper(
      examPaper,
      hasAdminRole,
      examPaper.category
    );
    if (!isValid) {
      this.setState({ errors: validationErrors });
      return;
    }
    const userDetails = keycloakService.getUserDetails();
    const { sub } = keycloakService.getUserDetails('sub');
    const teacherName = userDetails.name;
    examPaper.teacher = examPaper.teacher == '' ? sub : examPaper.teacher;
    examPaper.author = keycloakService.getUserName();
    examPaper.scheduledTime = new Date(examPaper.scheduledTime).toISOString();
    examPaper.selectors = this.getSelectorsData(
      selectedTeacher == '' ? teacherName : selectedTeacher.fullName
    );
    examPaper.description = JSON.stringify(initialValue);
    examPaper.public = allowpublic;
    examPaper.examStatus = 'DRAFT';
    this.setState({ submitting: true, errors });
    if (selectedExamPaperId) {
      examPaper.id = selectedExamPaperId;
      updateExamPaper(examPaper, (res) => {
        examPaper.category == 'MCQ'
          ? history.push(`/exam-paper/${res.id}/questions`)
          : history.push(`/exam-paper/${res.id}/part_2-questions`);
      });
    } else {
      createExamPaper(examPaper, (res) => {
        examPaper.category == 'MCQ'
          ? history.push(`/exam-paper/${res.id}/questions`)
          : history.push(`/exam-paper/${res.id}/part_2-questions`);
      });
    }
  }
  setTotoalDurationInMinutes() {
    const { durationInHrs, durationInMins, examPaper } = this.state;
    const totalMinutes = parseInt(durationInHrs) * 60 + parseInt(durationInMins);
    examPaper.durationInMinute = totalMinutes;
    this.setState({ examPaper });
  }

  setDurationInHrsAndMinutes(totalMinutes) {
    if (!totalMinutes) return;
    const mins = parseInt(totalMinutes) % 60;
    const hrs = Math.floor(parseInt(totalMinutes) / 60);
    const hrsString = convertTimeDigitToString(hrs);
    const minsString = convertTimeDigitToString(mins);
    this.setState({ durationInHrs: hrsString, durationInMins: minsString });
  }

  getSelectorsData(teacherName) {
    const selectors = [];
    const { examPaper } = this.state;
    if (examPaper.language) {
      selectors.push({ id: 'Language', value: examPaper.language });
    }
    if (examPaper.category) {
      selectors.push({ id: 'Category', value: examPaper.category });
    }
    if (examPaper.grade) {
      selectors.push({ id: 'Grade', value: examPaper.grade });
    }
    if (examPaper.subject) {
      selectors.push({ id: 'Subject', value: examPaper.subject });
    }
    if (teacherName) {
      selectors.push({ id: 'Teacher', value: teacherName });
    }
    if (examPaper.language) {
      selectors.push({ id: 'Language', value: examPaper.language });
    }
    return selectors;
  }

  handleTypistChange(e, val) {
    e.preventDefault();
    const { examPaper, errors } = this.state;
    examPaper.typist = val && val.id ? val.id : '';
    errors.typist = '';
    this.setState({ examPaper, errors });
  }
  handleTeacherChange(e, val) {
    e.preventDefault();
    const { examPaper, errors } = this.state;
    examPaper.teacher = val && val.id ? val.id : '';
    errors.teacher = '';
    this.setState({ selectedTeacher: val, examPaper, errors });
  }
  handleCorrectorChange(e, val) {
    e.preventDefault();
    const { examPaper, errors } = this.state;
    examPaper.corrector = val && val.id ? val.id : '';
    errors.corrector = '';
    this.setState({ examPaper, errors });
  }
  handleSubjectChange(e) {
    e.preventDefault();
    const { examPaper, errors } = this.state;
    const { name, value } = e.target;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const image = selectedOption.dataset.img;
    examPaper['coverImage'] = image;
    examPaper[name] = value;
    errors[name] = '';
    this.setState({ examPaper, errors });
  }
  handleSubjectChangeEdit(e, subject) {
    e.preventDefault();
    const { examPaper, errors } = this.state;
    examPaper.subject = subject && subject.id ? subject.id : '';
    errors.subject = '';
    this.setState({ examPaper, errors });
  }

  handleChangeDuration(e) {
    e.preventDefault;
    const { errors } = this.state;
    const { name, value } = e.target;
    const currentState = Object.assign(this.state);
    errors[name] = '';
    currentState[name] = value;
    this.setState(currentState);
  }

  render() {
    const {
      examPaper,
      initialValue,
      Language,
      Grade,
      Subject,
      selectedTeacher,
      finalPrice,
      isValidation
    } = this.state;
    const { typists, selectors, match, allTeachers, availableCorrector } = this.props;
    const selectedTypist = findTypist(typists, examPaper);
    const selectedSubject = findSubject(selectors.Subject, examPaper);
    const selectedCorrector = findCorrector(availableCorrector, examPaper);
    const selectTeacher = findTeacher(allTeachers, examPaper);
    const { examPaperId } = match.params;
    return (
      <Fragment>
        <DashboardLayout>
          <Container>
            {!examPaperId ? (
              <CreateForm
                {...this.state}
                handleChange={this.handleChange}
                handleChangeClick={this.handleChangeClick}
                handlePublic={this.handlePublic}
                handlePastPapers={this.handlePastPapers}
                handleDescription={this.handleDescription}
                handleCreate={this.handleSubmit}
                adminhandleCreate={this.adminhandleCreate}
                handleTypistChange={this.handleTypistChange}
                handleTeacherChange={this.handleTeacherChange}
                handleSubjectChange={this.handleSubjectChange}
                handleChangeDuration={this.handleChangeDuration}
                typists={typists}
                isValidation={isValidation}
                selectedTeacher={selectedTeacher}
                allTeachers={allTeachers}
                initialValue={initialValue}
                selectedTypist={selectedTypist}
                Language={Language}
                Grade={Grade}
                Subject={Subject}
                finalPrice={finalPrice}
                availableCorrector={availableCorrector}
                handleCorrectorChange={this.handleCorrectorChange}
                selectedCorrector={selectedCorrector}
              />
            ) : (
              <EditForm
                {...this.state}
                handleChange={this.handleChange}
                handleChangeClick={this.handleChangeClick}
                handlePublicedit={this.handlePublicedit}
                handlePastPapers={this.handlePastPapers}
                handleDescription={this.handleDescription}
                adminhandleCreate={this.adminhandleCreate}
                handleCreate={this.handleSubmit}
                handleTypistChange={this.handleTypistChange}
                handleSubjectChangeEdit={this.handleSubjectChange}
                handleChangeDuration={this.handleChangeDuration}
                typists={typists}
                initialValue={initialValue}
                selectedTypist={selectedTypist}
                gradeOptions={selectors.Grade}
                subjectOptions={selectors.Subject}
                selectedSubject={selectedSubject}
                finalPrice={finalPrice}
                handleTeacherChange={this.handleTeacherChange}
                selectedTeacher={selectTeacher}
                allTeachers={allTeachers}
                availableCorrector={availableCorrector}
                handleCorrectorChange={this.handleCorrectorChange}
                selectedCorrector={selectedCorrector}
              />
            )}
          </Container>
        </DashboardLayout>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  filterValues: state.filter.filterValues,
  examPaperDetails: state.examPaper.examPaperDetails,
  typists: state.examPaperRequest.subscribedTypist,
  selectors: state.filter.selectors,
  cascadeFilterResult: state.filter.cascadeFilterResult,
  allTeachers: state.examPaper.teachers,
  finalTeacherPrice: state.examPaper.teacherFinalPrice,
  availableCorrector: state.essayPaperRequest.getSubscribedCorrector
});

const mapDispatchToProps = {
  fetchExamPaperById,
  createExamPaper,
  updateExamPaper,
  fetchFinalTeacherPrice,
  fetchSubscribedTypist,
  getSelecterAll,
  clearExamPaperDetails,
  cascadeFilter,
  fetchAllTeachers,
  fetchCorrectorSubscribeRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(PaperCreateContainer);
