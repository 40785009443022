import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  a: {
    display: 'flex',
    cursor: 'pointer'
  }
});

export default function BasicTable(props) {
  const classes = useStyles();
  const { historyYearData, handleBack } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <div>
      <a className={classes.a} onClick={handleBack}>
        <ArrowBackIcon />
        &nbsp;{t('back')}
      </a>
      <div>
        <ul>
          <li>
            {t('paper')}:{' '}
            {historyYearData && historyYearData[0] ? historyYearData[0].paperTitle : null}
          </li>
        </ul>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>January</TableCell>
              <TableCell>February</TableCell>
              <TableCell>March</TableCell>
              <TableCell>April</TableCell>
              <TableCell>May</TableCell>
              <TableCell>June</TableCell>
              <TableCell>July</TableCell>
              <TableCell>August</TableCell>
              <TableCell>September</TableCell>
              <TableCell>October</TableCell>
              <TableCell>November</TableCell>
              <TableCell>December</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {t('no_of_papers')}
              </TableCell>
              {historyYearData &&
                historyYearData.length &&
                historyYearData[0].stats.map((row) => (
                  <TableCell key={row} component="th" scope="row">
                    {row.count}
                  </TableCell>
                ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
