import React, { useEffect } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamPapers } from '../../actions/examPaperAction';
import keycloakService from '../../keycloakService';
import { useTranslation } from 'react-i18next';

function TypingPapers() {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const dispatch = useDispatch();
  const pendingExamPapers = useSelector((state) => state.examPaper.pendingExamPapers);

  useEffect(() => {
    const userDetails = keycloakService.getUserDetails();
    dispatch(fetchExamPapers(userDetails.sub, { status: 'PENDING,DRAFT' }));
  }, [dispatch]);

  // Filter papers with typistStatus === 'TYPING'
  const typingPapers = pendingExamPapers.filter((paper) => paper.typistStatus === 'TYPING');

  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                {t('typist_typing_papers')}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('typist_name')}</TableCell>
                    <TableCell>{t('subject_name')}</TableCell>
                    <TableCell>{t('subject_title')}</TableCell>
                    <TableCell>{t('typist_status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {typingPapers.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: 'center', color: 'orange' }}>
                        {t('no_typing_papers')}
                      </TableCell>
                    </TableRow>
                  ) : (
                    typingPapers.map((paper, index) => (
                      <TableRow key={index}>
                        <TableCell>{paper.typistName}</TableCell>
                        <TableCell>{paper.subject}</TableCell>
                        <TableCell>{paper.title}</TableCell>
                        <TableCell>{paper.typistStatus}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypingPapers;
