import React, { useEffect } from 'react';
import './Widget.css';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import income from './income.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Widget = ({ type, amount, item }) => {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;
  const history = useHistory();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  let data;
  const handleClick = async (item) => {
    const menu = {
      title: item.title,
      id: item.id
    };
    await localStorage.setItem('menu', JSON.stringify(menu));
    history.push(item.path);
  };
  //let amount = 100;
  switch (type) {
    case 'subscription':
      data = {
        title: `${t('teacher_subscription')}`,
        isMoney: false,
        link: `${t('view_details')}`,
        icon: <SubscriptionsOutlinedIcon />
      };
      break;
    case 'paper':
      data = {
        title: `${t('published_exam_papers')}`,
        isMoney: false,
        link: `${t('view_all')}`,
        icon: <DescriptionOutlinedIcon />
      };
      break;
    case 'income':
      data = {
        title: `${t('monthly_income')}`,
        isMoney: true,
        link: `${t('view_net_earning')}`,
        icon: <img src={income} className="image" />
      };
      break;

    default:
      break;
  }

  return (
    <div className="widget" onClick={() => handleClick(item)}>
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && 'Rs.'}
          {amount}
        </span>
        <a className="link">{data.link}</a>
      </div>
      <div className="right">{data.icon}</div>
    </div>
  );
};

export default Widget;
