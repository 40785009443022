import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ROLE } from '../../utils/helper';
import { Card, CardContent, Divider } from '@material-ui/core';
import keycloakService from '../../keycloakService';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Details from './MultiStepForm/Details';
import ExamInstructions from './MultiStepForm/ExamInstructions';
import Price from './MultiStepForm/Price';
import Submit from './MultiStepForm/Submit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  right: {
    margin: '4px',
    marginTop: '10px'
  }
}));

export default function CreateExamForm(props) {
  const [page, setPage] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const pageTitles = ['details', 'exam_instruction', 'price', 'submit'];

  const {
    handleCreate,
    adminhandleCreate,
    handleChange,
    handleChangeClick,
    handlePublic,
    handlePastPapers,
    handleDescription,
    handleTypistChange,
    handleSubjectChange,
    handleChangeDuration,
    examPaper,
    submitting,
    errorMsg,
    typists,
    allTeachers,
    selectedTypist,
    handleTeacherChange,
    selectedTeacher,
    handleCorrectorChange,
    errors,
    durationInMins,
    durationInHrs,
    initialValue,
    allowpublic,
    allowpastPaper,
    Language,
    Grade,
    Subject,
    finalPrice,
    availableCorrector,
    selectedCorrector
  } = props;
  const classes = useStyles();
  const descript = 'description';
  const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);
  const hasTeacherRole = keycloakService.hasRole([ROLE.Teacher]);

  const categoryOptionsTemp = [
    {
      id: 'MCQ',
      value: 'MCQ'
    },
    {
      id: 'ESSAY',
      value: 'ESSAY'
    }
  ];

  const handleStep = (step) => () => {
    console.log('page', page);
    console.log('pageTitles', pageTitles.length);
    setPage(step);
    setActiveStep(step);
  };

  const handleNext = (e) => {
    if (page === pageTitles.length - 1) {
      hasTeacherRole && hasAdminRole ? adminhandleCreate() : handleCreate(e);
    } else {
      setPage(page + 1);
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    setPage(page - 1);
    setActiveStep(activeStep - 1);
  };

  const pageDisplay = () => {
    if (page == 0) {
      return (
        <Details
          handleChangeClick={handleChangeClick}
          Language={Language}
          errors={errors}
          handleChange={handleChange}
          handleSubjectChange={handleSubjectChange}
          examPaper={examPaper}
          categoryOptionsTemp={categoryOptionsTemp}
          Grade={Grade}
          Subject={Subject}
          classes={classes}
        />
      );
    } else if (page == 1) {
      return (
        <ExamInstructions
          handleChange={handleChange}
          handleDescription={handleDescription}
          handleChangeDuration={handleChangeDuration}
          examPaper={examPaper}
          errors={errors}
          durationInMins={durationInMins}
          durationInHrs={durationInHrs}
          initialValue={initialValue}
          classes={classes}
          descript={descript}
        />
      );
    } else if (page == 2) {
      return (
        <Price
          handleChange={handleChange}
          handleTypistChange={handleTypistChange}
          examPaper={examPaper}
          typists={typists}
          allTeachers={allTeachers}
          selectedTypist={selectedTypist}
          handleTeacherChange={handleTeacherChange}
          selectedTeacher={selectedTeacher}
          handleCorrectorChange={handleCorrectorChange}
          errors={errors}
          finalPrice={finalPrice}
          availableCorrector={availableCorrector}
          selectedCorrector={selectedCorrector}
          classes={classes}
        />
      );
    } else {
      return (
        <Submit
          handlePublic={handlePublic}
          handlePastPapers={handlePastPapers}
          examPaper={examPaper}
          allowpublic={allowpublic}
          allowpastPaper={allowpastPaper}
          classes={classes}
        />
      );
    }
  };
  return (
    <div>
      <h2
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px'
        }}
      >
        {t('create_exam_papers')}
      </h2>
      <Card
        style={{
          border: '1px solid #186CEE',
          borderRadius: '15px'
        }}
      >
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <form className={classes.root} noValidate autoComplete="off">
            <Stepper nonLinear activeStep={activeStep}>
              {pageTitles.map((label, index) => (
                <Step key={label}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {t(label)}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Divider style={{ margin: '20px 0px 20px 0px' }} />
            {errorMsg ? <p className={classes.errorText}>{errorMsg}</p> : null}

            {pageDisplay()}

            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '10px'
              }}
            >
              {(errors.language ||
                errors.title ||
                errors.grade ||
                errors.subject ||
                errors.category ||
                errors.noOfQuestions ||
                errors.noOfTypingDays ||
                errors.noOfAnswers ||
                errors.typist ||
                errors.price ||
                errors.durationInMinute) && (
                <p style={{ color: 'red' }}>please fill all requesting field before you proceed</p>
              )}
              <Grid item xs={6} sm={6}></Grid>
              <Grid
                item
                xs={6}
                sm={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginTop: '20px'
                }}
              >
                <Button
                  onClick={() => {
                    handleBack();
                  }}
                  disabled={page == 0}
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: '10px' }}
                >
                  {t('back')}
                </Button>
                <Button
                  onClick={(e) => {
                    handleNext(e);
                  }}
                  disabled={submitting}
                  variant="contained"
                  style={{ marginRight: '15px', backgroundColor: '#186CEE', color: 'white' }}
                >
                  {page === pageTitles.length - 1 ? `${t('create')}` : `${t('next')}`}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
