import React, { useEffect } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Science from './chemistry-bg.d15053e8159411c51ddc.png';
import './pendingExamPaper.css';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PublishIcon from '@mui/icons-material/Publish';

const displayActionBtn = (examData, edit, edit_review) => {
  let btn = null;
  if (examData.examStatus === 'DRAFT') {
    btn = (
      <div
        style={{
          border: '1px solid #186CEE',
          padding: '5px 20px',
          marginBottom: '5px',
          borderRadius: '10px'
        }}
      >
        <a
          className="button"
          href={`edit-paper/${examData.id}`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {edit}
        </a>
      </div>
    );
  }

  if (examData.examStatus === 'PENDING') {
    btn = (
      <div
        style={{
          border: '1px solid #186CEE',
          padding: '5px 20px',
          marginBottom: '5px',
          borderRadius: '10px'
        }}
      >
        <a
          className="button"
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
          href={`exam-paper/${examData.id}/review`}
        >
          {edit_review}
        </a>
      </div>
    );
  }
  return btn;
};

const ExamPaperList = (props) => {
  const { examList, title } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const renderExamPaperList = (list) => {
    if (list && list.length > 0) {
      return (
        <Grid container spacing={3}>
          {list.map((exam) => (
            <Grid item xs={12} sm={12} key={exam.id}>
              <Card className="paper-card">
                <CardMedia
                  image={Science}
                  title="Live from space album cover"
                  className="pcard-image"
                />
                <CardContent className="card-content">
                  <Typography variant="h6" gutterBottom>
                    {exam.title || ''}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {t('subject')} - {exam.subject || ''}
                  </Typography>
                </CardContent>
                <CardActions>
                  <div className="d-flex flex-flow-col align-items-center">
                    {displayActionBtn(exam, t('edit'), t('edit_review'))}
                    {exam.typistStatus == 'REVIEW' && (
                      <div
                        className="status"
                        style={{
                          border: '1px solid green',
                          padding: '5px 5px',
                          borderRadius: '10px'
                        }}
                      >
                        <PublishIcon className="icon" />
                        <span>{t('publish')}</span>
                      </div>
                    )}
                  </div>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  return (
    <div>
      <h3>{t(title)}</h3>

      {renderExamPaperList(examList)}
    </div>
  );
};

export default ExamPaperList;
