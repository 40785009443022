import React, { useEffect } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getAcceptedPapersByCorrector } from '../../actions/CorrectorAction';
import { useTranslation } from 'react-i18next';

function CorrectingPapers() {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const dispatch = useDispatch();
  const correctingExamPapers = useSelector(
    (state) => state.essayPaperRequest.getAcceptedPapersByCorrector
  );
  useEffect(() => {
    dispatch(getAcceptedPapersByCorrector());
  }, []);
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                {t('corrector_correcting_papers')}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table arial-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('corrector_name')}</TableCell>
                    <TableCell>{t('subject_name')}</TableCell>
                    <TableCell>{t('subject_title')}</TableCell>
                    <TableCell>{t('corrector_status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {correctingExamPapers.length >= 1 &&
                    correctingExamPapers.map(
                      (allPapers, index) =>
                        allPapers.correctorStatus === 'CORRECTING' && (
                          <TableRow key={index}>
                            <TableCell>{allPapers.correctorName}</TableCell>
                            <TableCell>{allPapers.subject}</TableCell>
                            <TableCell>{allPapers.title}</TableCell>
                            <TableCell>{allPapers.correctorStatus}</TableCell>
                          </TableRow>
                        )
                    )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default CorrectingPapers;
