import { Button, Grid, InputLabel, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import RichTextEditor from '../RichTextEditor';
import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function FaqAdding(props) {
  const { newQuestionAnswer, addNewAnswer, addNewQuestion, handleAddQuestionAnswer } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <div>
      <h1>{t('add_faq')}</h1>
      <Card>
        <CardContent style={{ padding: '15px', border: '1px solid #186CEE', borderRadius: '15px' }}>
          <Grid container spacing={1} style={{ alignItems: 'center' }}>
            <Grid item xs={2} sm={2}>
              <InputLabel>{t('question')}</InputLabel>
            </Grid>
            <Grid item xs={10} sm={10} style={{ padding: '20px' }}>
              <TextField
                variant="outlined"
                style={{
                  width: '100%',
                  border: '1px solid #186CEE',
                  borderRadius: '15px'
                }}
                value={newQuestionAnswer.question}
                name="question"
                onChange={addNewQuestion}
                required
                placeholder={t('enter_english_question')}
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputLabel>{t('answer')}</InputLabel>
            </Grid>
            <Grid item xs={10} sm={10}>
              <RichTextEditor
                value={newQuestionAnswer.answer}
                setValue={(val) => addNewAnswer(val, 'answer')}
                placeholder={t('enter_english_answer')}
                borderColor="#186CEE"
                borderRadius="15px"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ alignItems: 'center' }}>
            <Grid item xs={2} sm={2}>
              <InputLabel>{t('question')}</InputLabel>
            </Grid>
            <Grid item xs={10} sm={10} style={{ padding: '20px' }}>
              <TextField
                value={newQuestionAnswer.questionTa}
                name="questionTa"
                variant="outlined"
                style={{ width: '100%', border: '1px solid #186CEE', borderRadius: '15px' }}
                onChange={addNewQuestion}
                required
                placeholder={t('enter_tamil_question')}
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputLabel>{t('answer')}</InputLabel>
            </Grid>
            <Grid item xs={10} sm={10}>
              <RichTextEditor
                value={newQuestionAnswer.answerTa}
                setValue={(val) => addNewAnswer(val, 'answerTa')}
                placeholder={t('enter_tamil_answer')}
                borderColor="#186CEE"
                borderRadius="15px"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ alignItems: 'center' }}>
            <Grid item xs={2} sm={2}>
              <InputLabel>{t('question')}</InputLabel>
            </Grid>
            <Grid item xs={10} sm={10} style={{ padding: '20px' }}>
              <TextField
                value={newQuestionAnswer.questionSi}
                name="questionSi"
                variant="outlined"
                style={{ width: '100%', border: '1px solid #186CEE', borderRadius: '15px' }}
                onChange={addNewQuestion}
                required
                placeholder={t('enter_sinhala_question')}
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <InputLabel>{t('answer')}</InputLabel>
            </Grid>
            <Grid item xs={10} sm={10}>
              <RichTextEditor
                value={newQuestionAnswer.answerSi}
                setValue={(val) => addNewAnswer(val, 'answerSi')}
                placeholder={t('enter_sinhala_answer')}
                borderColor="#186CEE"
                borderRadius="15px"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Grid item xs={6} sm={6}>
              <Button
                fullWidth
                onClick={() => handleAddQuestionAnswer()}
                disabled={
                  newQuestionAnswer.question == '' ||
                  newQuestionAnswer.questionTa == '' ||
                  newQuestionAnswer.questionSi == ''
                }
                variant="contained"
                // style={{ backgroundColor: '#186CEE', color: 'white' }}
                color="primary"
              >
                {t('add')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default FaqAdding;
