import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { fetchExamPaperRequest } from '../../actions/typistAction';
import { useDispatch, useSelector } from 'react-redux';
import { acceptPaperRequest } from '../../actions/typistAction';
import { rejectPaperRequest } from '../../actions/typistAction';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

function TypistPaperRequest() {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const dispatch = useDispatch();
  // const history = useHistory();
  const paperRequest = useSelector((state) => state.examPaperRequest.paperRequest);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const handleClick = async (click, id) => {
    if (click == 'accept') {
      await dispatch(acceptPaperRequest(id));
      // const menu = {
      //   title: 'Current Work',
      //   id: 40
      // };
      // await localStorage.setItem('menu', JSON.stringify(menu));
      // history.push('/typist-current-work');
      window.location.reload();
    } else if (click == 'reject') {
      dispatch(rejectPaperRequest(id));
      window.location.reload();
    }
    setTriggerUpdate(!triggerUpdate);
  };
  useEffect(() => {
    dispatch(fetchExamPaperRequest());
  }, [triggerUpdate]);
  const requestList = (request, index) => {
    return (
      <TableRow key={index}>
        {/* <TableCell>{new Date(request.scheduledTime).toDateString()}</TableCell> */}
        <TableCell>{request.subject}</TableCell>
        <TableCell>{request.title}</TableCell>
        <TableCell>{request.teacherName}</TableCell>
        <TableCell>{request.noOfTypingDays ? request.noOfTypingDays : 'N/A'}</TableCell>
        <TableCell>
          <Button variant="contained" onClick={() => handleClick('accept', request.id)}>
            {t('accept')}
          </Button>
          <Button variant="contained" onClick={() => handleClick('reject', request.id)}>
            {t('reject')}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                {t('typist_paper_request')}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>{t('subject_name')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{t('subject_title')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{t('teacher_name')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{t('need_to_finish')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{t('action')}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paperRequest &&
                    paperRequest.length >= 1 &&
                    paperRequest.map(
                      (request, index) =>
                        request.examStatus === 'PUBLISHED' && requestList(request, index)
                    )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypistPaperRequest;
