import React, { useEffect } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function CorrectorSubscription(props) {
  const {
    correctors,
    selectCorrector,
    handleCorrectorChange,
    subscribeCorrector,
    subscribedCorrector,
    pendingCorrectorRequest
  } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <h3>{t('corrector_subscription')}</h3>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <Autocomplete
                id="corrector-id"
                options={correctors}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField {...params} label={t('select_corrector')} variant="outlined" />
                )}
                //value={selectTypist}
                onChange={handleCorrectorChange}
                style={{ border: '1px solid #186CEE', borderRadius: '10px' }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #186CEE',
                  padding: '10px',
                  width: '50%',
                  height: '40px',
                  margin: '0 auto',
                  borderRadius: '15px',
                  color: selectCorrector ? 'white' : 'black',
                  backgroundColor: selectCorrector ? '#186CEE' : 'white',
                  cursor: selectCorrector ? 'pointer' : 'not-allowed',
                  textAlign: 'center'
                }}
                onClick={subscribeCorrector}
                disabled={!selectCorrector}
              >
                {t('subscribe')}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            {selectCorrector && (
              <div>
                <ul>
                  <li>
                    {t('first_name')} :- {selectCorrector.firstName}
                  </li>
                  <li>
                    {t('last_name')} :- {selectCorrector.lastName}
                  </li>
                  <li>
                    {t('user_name')} :- {selectCorrector.userName}
                  </li>
                  <li>
                    {t('email')} :- {selectCorrector.email}
                  </li>
                  <li>
                    {t('photo')} :- {selectCorrector.profileImage}
                  </li>
                </ul>
              </div>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <h3>{t('subscribed_correctors')}</h3>

              {subscribedCorrector.length == 0 ? (
                <p>{t('no_subscribed_correctors')}</p>
              ) : (
                subscribedCorrector.length >= 1 &&
                subscribedCorrector.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      border: '1px solid #186CEE',
                      borderRadius: '10px',
                      padding: '10px',
                      marginTop: '2px'
                    }}
                  >
                    <div>
                      <strong>{index + 1}. </strong>
                      <span>
                        {item.firstName} {item.lastName}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <h3>{t('pending_correctors')}</h3>

              {pendingCorrectorRequest.length == 0 ? (
                <p style={{ color: 'orange' }}>{t('no_pending_correctors')}</p>
              ) : (
                pendingCorrectorRequest.length >= 1 &&
                pendingCorrectorRequest.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      border: '1px solid #186CEE',
                      borderRadius: '10px',
                      padding: '10px'
                    }}
                  >
                    <div>
                      <strong>{index + 1}. </strong>
                      <span>
                        {item.firstName} {item.lastName}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default CorrectorSubscription;
