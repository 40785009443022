import React, { Component } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { Card, CardContent } from '@material-ui/core';
import Latex from 'react-latex-next';

export default class LearnLaText extends Component {
  render() {
    const temp = '$$X_k=\\frac{1}{N}\\sum_{n=0}^{N-1}x_k e^{i2\\pi k\\frac{n}{N}}$$';
    return (
      <DashboardLayout>
        <Card>
          <CardContent>
            <h1 style={{ display: 'flex', justifyContent: 'center' }}>
              This is Learning Latex Page..!
            </h1>
            <Latex>{temp}</Latex> <label>Learning to create this formula</label>
            <ul>
              <li>
                <Latex>{'$$X_y$$'}</Latex>
              </li>
            </ul>
          </CardContent>
        </Card>
      </DashboardLayout>
    );
  }
}
