import React, { useEffect } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function TypistSubscription(props) {
  const {
    typists,
    selectTypist,
    handleTypistChange,
    subscribeTypist,
    subscribedTypists,
    pendingTypistRequest
  } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <h3 style={{ display: 'flex', justifyContent: 'center' }}>{t('typist_subscription')}</h3>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <Autocomplete
                id="typist-id"
                options={typists}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField {...params} label={t('select_typist')} variant="outlined" />
                )}
                onChange={handleTypistChange}
                style={{ border: '1px solid #186CEE', borderRadius: '10px' }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                onClick={subscribeTypist}
                disabled={!selectTypist}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #186CEE',
                  padding: '10px',
                  width: '50%',
                  height: '40px',
                  margin: '0 auto',
                  borderRadius: '15px',
                  color: selectTypist ? 'white' : 'black',
                  backgroundColor: selectTypist ? '#186CEE' : 'white',
                  cursor: selectTypist ? 'pointer' : 'not-allowed',
                  textAlign: 'center'
                }}
              >
                {t('subscribe')}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            {selectTypist && (
              <div>
                <ul>
                  <li>
                    {t('first_name')} :- {selectTypist.firstName}
                  </li>
                  <li>
                    {t('last_name')} :- {selectTypist.lastName}
                  </li>
                  <li>
                    {t('user_name')} :- {selectTypist.userName}
                  </li>
                  <li>
                    {t('email')} :- {selectTypist.email}
                  </li>
                  <li>
                    {t('photo')} :- {selectTypist.profileImage}
                  </li>
                </ul>
              </div>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <h3>{t('subscribed_typist')}</h3>

              {subscribedTypists.length == 0 ? (
                <p style={{ color: 'orange' }}>{t('not_subscribed_typist')}</p>
              ) : (
                subscribedTypists.length >= 1 &&
                subscribedTypists.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      border: '1px solid #186CEE',
                      borderRadius: '10px',
                      padding: '10px',
                      marginTop: '2px'
                    }}
                  >
                    <div>
                      <strong>{index + 1}. </strong>
                      <span>
                        {item.firstName} {item.lastName}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <h3>{t('pending_typist')}</h3>

              {pendingTypistRequest.length == 0 ? (
                <p style={{ color: 'orange' }}>{t('not_subscribed_typist')}</p>
              ) : (
                pendingTypistRequest.length >= 1 &&
                pendingTypistRequest.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      border: '1px solid #186CEE',
                      borderRadius: '10px',
                      padding: '10px'
                    }}
                  >
                    <div>
                      <strong>{index + 1}. </strong>
                      <span>
                        {item.firstName} {item.lastName}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </Grid>
          </Grid>
          <Grid></Grid>
          <Grid></Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypistSubscription;
