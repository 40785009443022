import React from 'react';
import CSVReader from 'react-csv-reader';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  csvInput: {
    padding: 10,
    display: 'block',
    border: '1px solid #186CEE',
    borderRadius: 5,
    width: '506px',
    height: '55px',
    alignItems: 'center'
  },
  csvLabel: {
    alignItems: 'center',
    justifyContent: 'center',
    color: 'gray'
  },
  csvFile: {
    margin: '5px'
  },
  deleteBtn: {
    margin: '15px auto',
    padding: 5,
    color: 'red',
    border: 'none',
    backgroundColor: '#fff'
  }
});

const CSVFileReader = (props) => {
  const classes = useStyles();
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_')
  };

  const { handleFileUpload, isFileUploaded, csvFormRef, handleClearCsv } = props;
  return (
    <>
      <form ref={csvFormRef}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} sm={8}>
            <CSVReader
              cssClass={classes.csvInput}
              label="upload .csv file "
              parserOptions={papaparseOptions}
              onFileLoaded={handleFileUpload}
              disabled={isFileUploaded}
              cssLabelClass={classes.csvLabel}
              cssInputClass={classes.csvFile}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {isFileUploaded ? (
              <button className={classes.deleteBtn} type="button" onClick={handleClearCsv}>
                <DeleteIcon />
              </button>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CSVFileReader;
