import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { fetchAcceptPaperRequest } from '../../actions/typistAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function TypistCurrentWork() {
  const dispatch = useDispatch();
  const history = useHistory();
  const acceptPaperRequest = useSelector((state) => state.examPaperRequest.acceptPaperRequest);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchAcceptPaperRequest());
    };
    fetchData();
  }, []);
  const handleClick = (id) => {
    history.push(`/exam-paper/${id}/questions`);
  };
  const handleDateValidation = (date, noOfTypingDays) => {
    console.log(date, noOfTypingDays);
    if (!date) return 'no data';
    else {
      const currentDate = new Date();
      const typistAcceptDate = new Date(date);
      const timeDifference = currentDate - typistAcceptDate;
      const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return dayDifference < noOfTypingDays
        ? `${noOfTypingDays - dayDifference}-left`
        : 'days exceeded';
    }
  };
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                This is Typist Current Page
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Teacher Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Subject Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Subject Title</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Need to finish(Days)</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Action</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acceptPaperRequest.length >= 1 &&
                    acceptPaperRequest.map(
                      (acceptPapers, index) =>
                        acceptPapers.examStatus === 'PUBLISHED' && (
                          <TableRow key={index}>
                            <TableCell>{acceptPapers.teacherName}</TableCell>
                            <TableCell>{acceptPapers.subject}</TableCell>
                            <TableCell>{acceptPapers.title}</TableCell>
                            <TableCell>
                              {handleDateValidation(
                                acceptPapers?.typingAcceptDate,
                                acceptPapers?.noOfTypingDays
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                onClick={() => handleClick(acceptPapers.id)}
                              >
                                Start
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default TypistCurrentWork;
