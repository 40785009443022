import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReadOnly from '../RichTextEditor/ReadOnly';
import RichTextEditor from '../RichTextEditor';
import { TextField } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import GradingIcon from '@mui/icons-material/Grading';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 100,
    width: '100%'
  },
  boxSpacing: {
    padding: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  ponints: {
    display: 'block',
    flexDirection: 'row',
    gap: 20
  },
  iconSize: {
    fontSize: 30
  }
}));
const renderNode = (faq, handleFaqChange, selectedFaqId, selectedFaqAnswer) => {
  const faqNode = JSON.parse(faq.answer);
  return faq.id == selectedFaqId ? (
    <div>
      <RichTextEditor
        value={
          faq.id == selectedFaqId ? JSON.parse(selectedFaqAnswer.answer) : JSON.parse(faq.answer)
        }
        setValue={(val) => handleFaqChange(val, 'answer')}
      />
      <RichTextEditor
        value={
          faq.id == selectedFaqId
            ? JSON.parse(selectedFaqAnswer.answerTa)
            : JSON.parse(faq.answerTa)
        }
        setValue={(val) => handleFaqChange(val, 'answerTa')}
      />
      <RichTextEditor
        value={
          faq.id == selectedFaqId
            ? JSON.parse(selectedFaqAnswer.answerSi)
            : JSON.parse(faq.answerSi)
        }
        setValue={(val) => handleFaqChange(val, 'answerSi')}
      />
    </div>
  ) : (
    faqNode.map((item, index) => {
      const arr = [item];

      switch (item.type) {
        case 'paragraph':
          return (
            <div key={index}>
              <ReadOnly initialValue={arr} />
            </div>
          );
        case 'image':
          return (
            <img
              key={index}
              src={item.src}
              alt={item.alt}
              style={{ maxWidth: '50%', height: 'auto' }}
            />
          );
        default:
          return null;
      }
    })
  );
};
function FaqHandler(props) {
  const {
    faqDetail,
    handleFaqChange,
    handleEditSetData,
    selectedFaqId,
    selectedFaqQuestion,
    selectedFaqAnswer,
    handleFaqQuesChange,
    handleDeleteFaq,
    handleUpdateChanges,
    setHandleButtonsDrop
  } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const [handleUpdateBtn, setHandleButtons] = useState(false);
  const [isHoveredDelete, setIsHoveredDelete] = useState(false);
  const [isHoveredEdit, setIsHoveredEdit] = useState(false);
  const [isHoveredUpdate, setIsHoveredUpdate] = useState(false);
  const classes = useStyles();
  console.log('inside', faqDetail);
  const handleEdit = (faq) => {
    setHandleButtons(!handleUpdateBtn, () => setHandleButtonsDrop());
    handleEditSetData(faq);
  };
  const handleUpdate = () => {
    setHandleButtons(!handleUpdateBtn, () => setHandleButtonsDrop());
    handleUpdateChanges();
  };
  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <h1>{t('update_faq')}</h1>
        {faqDetail.length >= 1 &&
          faqDetail.map((faq) => (
            <Accordion
              key={faq.id}
              style={{ padding: '10px', border: '1px solid #186CEE', borderRadius: '15px' }}
            >
              <Grid style={{ display: 'flex' }}>
                <Grid item xs={8} sm={8}>
                  {faq.id != selectedFaqId ? (
                    <div>
                      <Typography className={classes.heading}>{faq.question}</Typography>
                      <Typography className={classes.heading}>{faq.questionTa}</Typography>
                      <Typography className={classes.heading}>{faq.questionSi}</Typography>
                    </div>
                  ) : (
                    <div>
                      <TextField
                        value={
                          faq.id == selectedFaqId ? selectedFaqQuestion.question : faq.question
                        }
                        style={{ width: '100%' }}
                        name="question"
                        onChange={handleFaqQuesChange}
                      />
                      <TextField
                        value={
                          faq.id == selectedFaqId ? selectedFaqQuestion.questionTa : faq.questionTa
                        }
                        style={{ width: '100%' }}
                        name="questionTa"
                        onChange={handleFaqQuesChange}
                      />
                      <TextField
                        value={
                          faq.id == selectedFaqId ? selectedFaqQuestion.questionSi : faq.questionSi
                        }
                        style={{ width: '100%' }}
                        name="questionSi"
                        onChange={handleFaqQuesChange}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={4} sm={4}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setHandleButtons(false, setHandleButtonsDrop())}
                  ></AccordionSummary>
                </Grid>
              </Grid>

              <AccordionDetails>
                <Grid container spacing={3} className={classes.boxSpacing}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        justifyContent: 'flex-end',
                        margin: 'auto',
                        alignItems: 'flex-end',
                        display: 'flex'
                      }}
                    >
                      <div
                        style={{
                          display: faq.id != selectedFaqId ? 'block' : 'none',
                          padding: '5px',
                          color: '#186CEE',
                          transition: 'all 0.3s ease',
                          ...(isHoveredEdit && { transform: 'scale(1.1)' })
                        }}
                        onMouseEnter={() => setIsHoveredEdit(true)}
                        onMouseLeave={() => setIsHoveredEdit(false)}
                        onClick={() => handleEdit(faq)}
                      >
                        <EditNoteIcon style={{ fontSize: '40px' }} />
                      </div>
                      <div
                        style={{
                          color: '#59981A',
                          display: faq.id == selectedFaqId ? 'block' : 'none',
                          padding: '7px',
                          transition: 'all 0.3s ease',
                          ...(isHoveredUpdate && { transform: 'scale(1.1)' })
                        }}
                        onMouseEnter={() => setIsHoveredUpdate(true)}
                        onMouseLeave={() => setIsHoveredUpdate(false)}
                        onClick={() => handleUpdate()}
                      >
                        <GradingIcon style={{ fontSize: '30px' }} />
                      </div>
                      <div
                        onClick={() => handleDeleteFaq(faq.id)}
                        style={{
                          color: '#EC2A2A',
                          padding: '10px',
                          transition: 'all 0.3s ease',
                          ...(isHoveredDelete && { transform: 'scale(1.1)' }) // Example of a hover effect
                        }}
                        onMouseEnter={() => setIsHoveredDelete(true)}
                        onMouseLeave={() => setIsHoveredDelete(false)}
                      >
                        <DeleteIcon style={{ fontSize: '30px' }} />
                      </div>
                    </div>
                    <div className={classes.ponints}>
                      {renderNode(faq, handleFaqChange, selectedFaqId, selectedFaqAnswer)}
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </Fragment>
  );
}

export default FaqHandler;
