import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { fetchExamPaperById, fetchAllQuestions } from '../../actions/examPaperAction';
import DashboardLayout from '../../layouts/DashboardLayout';
import ViewQuestionsAndAnswers from '../../components/PublishedExamPaper/ViewQuestionsAndAnswers';
import { acceptTypingPaperRequest, rejectTypingPaperRequest } from '../../actions/typistAction';
import { Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import { publishExamPaper } from '../../actions/examPaperAction';
import './viewExam.css';

class ViewExam extends Component {
  constructor() {
    super();
    this.state = {
      openCommentReject: false,
      rejectReason: ''
    };
    this.handleAction = this.handleAction.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    const { fetchExamPaperById, fetchAllQuestions, match } = this.props;
    const { examPaperId } = match.params;
    fetchExamPaperById(examPaperId);
    fetchAllQuestions(examPaperId);
  }
  handleAction = (action, examID) => {
    const { acceptTypingPaperRequest, history, publishExamPaper } = this.props;
    if (action == 'Accept') {
      publishExamPaper(examID, 'PUBLISHED', () => {
        acceptTypingPaperRequest(examID, () => {
          const menu = {
            title: 'Published exam papers',
            id: 22
          };
          localStorage.setItem('menu', JSON.stringify(menu));
          history.push('/published-exam-papers');
        });
      });
    } else {
      this.setState({ openCommentReject: true });
    }
  };
  handleReject = (e) => {
    const { value } = e.target;
    this.setState({ rejectReason: value });
  };
  handleSubmit = (examID) => {
    const { rejectReason } = this.state;
    const { history, rejectTypingPaperRequest } = this.props;
    rejectTypingPaperRequest(examID, rejectReason);
    history.push('/published-exam-papers');
  };
  render() {
    const { examPaperDetails, questions } = this.props;
    const REVIEW = examPaperDetails.typistStatus;
    const { openCommentReject, rejectReason } = this.state;
    return (
      <Fragment>
        <DashboardLayout>
          <div className="view-container">
            <div className="top">
              <h3 className="title">Title - {examPaperDetails.title || ''}</h3>
            </div>
            <ViewQuestionsAndAnswers questions={questions} examPaperDetails={examPaperDetails} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <a
                  style={{
                    margin: '10px',
                    border: '1px solid blue',
                    padding: '5px 10px',
                    borderRadius: '10px',
                    textDecoration: 'none'
                  }}
                  href="/teacher-in-review"
                >
                  Back
                </a>
              </div>
              {REVIEW == 'REVIEW' && !openCommentReject == true && (
                <div>
                  <a
                    onClick={() => this.handleAction('Accept', examPaperDetails.id)}
                    style={{
                      margin: '10px',
                      border: '1px solid green',
                      padding: '5px 10px',
                      borderRadius: '10px',
                      textDecoration: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    Accept
                  </a>
                  <a
                    onClick={() => this.handleAction('Reject', examPaperDetails.id)}
                    style={{
                      margin: '10px',
                      border: '1px solid red',
                      padding: '5px 10px',
                      borderRadius: '10px',
                      textDecoration: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    Reject
                  </a>
                </div>
              )}
            </div>
          </div>

          {openCommentReject == true && (
            <Card>
              <CardContent
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Grid item xs={5} sm={3}>
                  <InputLabel style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                    Enter the Reject Reason:
                  </InputLabel>
                </Grid>
                <Grid item xs={5} sm={7}>
                  <OutlinedInput
                    name="rejectReason"
                    onChange={this.handleReject}
                    value={rejectReason}
                    placeholder="Enter the Reason"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sm={2}>
                  <a
                    style={{
                      margin: '10px',
                      border: '1px solid blue',
                      padding: '5px 10px',
                      borderRadius: '10px',
                      textDecoration: 'none',
                      alignContent: 'end'
                    }}
                    onClick={() => this.handleSubmit(examPaperDetails.id)}
                  >
                    Submit
                  </a>
                </Grid>
              </CardContent>
            </Card>
          )}
        </DashboardLayout>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  examPaperDetails: state.examPaper.examPaperDetails,
  questions: state.examPaper.questions
});

const mapDispatchToProps = {
  fetchExamPaperById,
  fetchAllQuestions,
  acceptTypingPaperRequest,
  rejectTypingPaperRequest,
  publishExamPaper
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewExam);
