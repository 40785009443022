import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { Box, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Submit = (props) => {
  const { handlePublic, handlePastPapers, examPaper, allowpublic, allowpastPaper, classes } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <div>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('allow_public')}
          </InputLabel>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Checkbox
            checked={allowpublic}
            onChange={handlePublic}
            inputProps={{ 'aria-label': 'controlled' }}
            name="public"
          />
        </Grid>
        <Grid item xs={3} sm={4}>
          <Box>
            <img
              src={examPaper.coverImage}
              style={{ width: '100px', hight: '100px', borderRadius: '10px' }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('past_paper')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <Checkbox
            checked={allowpastPaper}
            onChange={handlePastPapers}
            inputProps={{ 'aria-label': 'controlled' }}
            name="pastPaper"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Submit;
