import { Button, FormControl, OutlinedInput } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import NameSelector from '../Selector/NameSelector';
import { uploadFiles } from '../../actions/examPaperAction';
import Compressor from 'compressorjs';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function UpdateSelector(props) {
  const {
    Language,
    Subject,
    Grade,
    handleChangeClick,
    handleSubjectChange,
    newSubject,
    updateSubject,
    selector,
    subject
  } = props;

  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const dispatch = useDispatch();
  const [image, setImage] = useState('');

  const [msg, setMsg] = useState(null);

  const handleCompressImage = (e) => {
    const file = e.target.files;
    const compressPromises = [];

    if (file[0] && file[0].size > 1024 * 1024 * 2) {
      setMsg('please_upload_less_than_2MB');
    }
    setMsg('uploading');

    const compressPromise = new Promise((resolve, reject) => {
      new Compressor(file[0], {
        quality: 0.8,
        success(result) {
          const formData = new FormData();
          formData.append('file', result);
          dispatch(
            uploadFiles(formData, (url) => {
              setImage(url);
              resolve();
            })
          );
        },
        error(err) {
          console.error(err.message);
          reject(err);
        }
      });
    });

    compressPromises.push(compressPromise);
    Promise.all(compressPromises)
      .then(() => {
        console.log('All files processed and dispatched successfully');
        setMsg('please_Click_upload_button');
      })
      .catch((error) => {
        console.error('Error processing files:', error);
      });
  };
  const addhandleSubject = () => {
    updateSubject(image);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px'
      }}
    >
      <div
        style={{
          width: '60%',
          border: '1px solid #186CEE',
          borderRadius: '15px',
          padding: '30px',
          marginBottom: '20px',
          textAlign: 'center'
        }}
      >
        <h1 style={{ marginBottom: '20px' }}>{t('update_selector')}</h1>
        <div style={{ marginBottom: '20px' }}>
          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="language"
              onChange={handleChangeClick}
              options={Language}
              placeHolderText={t('select_language')}
            />
          </FormControl>

          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="grade"
              onChange={handleChangeClick}
              options={Grade}
              placeHolderText={t('select_grade')}
            />
          </FormControl>
          <FormControl variant="outlined" style={{ width: '100%', marginBottom: '15px' }}>
            <NameSelector
              name="subject"
              onChange={handleChangeClick}
              options={Subject}
              placeHolderText={t('select_subject')}
            />
          </FormControl>
          <OutlinedInput
            name="subject"
            onChange={handleSubjectChange}
            placeholder={t('new_subject_name')}
            inputProps={{ 'aria-label': 'title' }}
            value={subject}
            disabled={!selector?.subject}
            style={{ width: '100%', marginBottom: '15px' }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '15px' }}>
          <div
            style={{
              border: '1px solid #186CEE',
              padding: '10px',
              borderRadius: '15px',
              width: '35%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {msg && <span>{msg}</span>}
            <input
              type="file"
              id="fileInput"
              onChange={handleCompressImage}
              accept="image/*"
              style={{ display: 'none' }}
            />
            <label
              htmlFor="fileInput"
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                border: '1px solid #186CEE',
                padding: '5px',
                borderRadius: '15px',
                marginTop: '10px',
                textAlign: 'center'
              }}
            >
              {t('choose_image')}
            </label>
          </div>
          <div
            style={{
              border: '1px solid #186CEE',
              borderRadius: '10px',
              width: '35%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px'
            }}
          >
            {image ? (
              <img
                src={image}
                alt="Preview"
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
            ) : (
              <p style={{ textAlign: 'center' }}>{t('image_not')}</p>
            )}
          </div>
        </div>
        <Button
          disabled={
            selector.language == '' || selector.grade == '' || newSubject == '' || image == ''
          }
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '15px',
            color:
              selector.language != '' && selector.grade != '' && newSubject != '' && image != ''
                ? 'white'
                : 'black',
            backgroundColor:
              selector.language != '' && selector.grade != '' && newSubject != '' && image != ''
                ? '#186CEE'
                : '#f0f0f0',
            cursor:
              selector.language != '' && selector.grade != '' && newSubject != '' && image != ''
                ? 'pointer'
                : 'not-allowed',
            textAlign: 'center',
            fontWeight: 'bold'
          }}
          onClick={addhandleSubject}
        >
          {t('update_subject_btn')}
        </Button>
      </div>
    </div>
  );
}

export default UpdateSelector;
