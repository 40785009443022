import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormControl } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import NameSelector from '../../Selector/NameSelector';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Selector from '../../Selector';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Details = (props) => {
  const {
    handleChange,
    handleChangeClick,
    handleSubjectChange,
    examPaper,
    errors,
    Language,
    Grade,
    Subject,
    categoryOptionsTemp,
    classes,
    selectedSubject
  } = props;
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <div style={{ marginTop: '50px' }}>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.lable} required>
            {t('language')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <FormControl
            style={{
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px',
              width: '400px'
            }}
          >
            <NameSelector
              name="language"
              onChange={handleChangeClick}
              options={Language}
              placeHolderText="select language"
              error={!!errors.language}
              value={examPaper.language}
            />
          </FormControl>
          <FormHelperText id="language-helper-text">{errors.language || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('title')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <OutlinedInput
            name="title"
            value={examPaper.title}
            onChange={handleChange}
            placeholder=""
            inputProps={{ 'aria-label': 'title' }}
            style={{ width: '420px' }}
            error={!!errors.title}
          />{' '}
          <FormHelperText id="title-helper-text">{errors.title || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('grade')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <FormControl
            style={{
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px',
              width: '400px'
            }}
          >
            <NameSelector
              name="grade"
              onChange={handleChangeClick}
              options={Grade}
              placeHolderText="select grade"
              error={!!errors.grade}
              value={examPaper.grade}
            />
          </FormControl>
          <FormHelperText id="title-helper-text">{errors.grade || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('subject')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <FormControl
            style={{
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px',
              width: '400px'
            }}
          >
            <NameSelector
              name="subject"
              onChange={handleSubjectChange}
              options={Subject}
              placeHolderText="select grade"
              error={!!errors.subject}
              value={selectedSubject}
            />
          </FormControl>
          <FormHelperText id="title-helper-text">{errors.subject || ''}</FormHelperText>
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputLabel className={classes.label} required>
            {t('category')}
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={8}>
          <FormControl
            style={{
              border: '1px solid #bdc7bf',
              borderRadius: '5px',
              padding: '10px',
              width: '400px'
            }}
          >
            <Selector
              name="category"
              onChange={handleChange}
              options={categoryOptionsTemp}
              placeHolderText="select Subject"
              error={!!errors.category}
            />
          </FormControl>
          <FormHelperText id="title-helper-text">{errors.category || ''}</FormHelperText>
        </Grid>
      </Grid>
    </div>
  );
};

export default Details;
