import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
const OverallStatCard = (props) => {
  const { statData } = props;
  console.log(statData);
  var options = {
    series: [70],
    chart: {
      height: 350
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%'
        }
      }
    },
    labels: ['Pass']
  };
  return (
    <Card>
      <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Chart options={options} series={options.series} type="radialBar" width={300} />;
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <span>No of exams: {statData.totalExamAttempts || ''}</span>
          </Grid>
          <Grid item xs={6} sm={6}>
            <span>No of questions: {statData.totalQuestionAttempts || ''}</span>
          </Grid>
          <Grid item xs={6} sm={6}>
            <span>Questions answered correctly: {statData.totalCorrectQuestionAttempts || ''}</span>
          </Grid>
          <Grid item xs={6} sm={6}>
            <span>Questions answered wrongly: {statData.totalIncorrectQuestionAttempts || ''}</span>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OverallStatCard;
