import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper } from '@material-ui/core';

const TableView = (props) => {
  if (props.data && props.data.length) {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Question number</TableCell>
              <TableCell align="right">Avg Time taken(s)</TableCell>
              <TableCell align="right">Max Time taken(s)</TableCell>
              <TableCell align="right">Min time taken(s)</TableCell>
              <TableCell align="right">Correct Percentage(%)</TableCell>
              <TableCell align="right">Incorrect Percentage(%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row.questionNo}>
                <TableCell component="th" scope="row">
                  {row.questionNo || ''}
                </TableCell>
                <TableCell align="center">{row.avgTimeSpentInSecond || ''}</TableCell>
                <TableCell align="center">{row.maxTimeSpentInSecond || ''}</TableCell>
                <TableCell align="center">{row.minTimeSpentInSecond || ''}</TableCell>
                <TableCell align="center">{row.correctPercentage || ''}</TableCell>
                <TableCell align="center">{row.incorrectPercentage || ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
};

export default TableView;
