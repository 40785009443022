import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ROLE } from '../../utils/helper';
import { Card, CardContent, Divider } from '@material-ui/core';
import keycloakService from '../../keycloakService';
import DetailsEdit from './MultiStepForm/DetailsEdit';
import ExamInstructions from './MultiStepForm/ExamInstructions';
import Price from './MultiStepForm/Price';
import Submit from './MultiStepForm/Submit';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  container: {
    maxWidth: 600
  },
  errorText: {
    color: 'red'
  },
  deleteIcon: {
    cursor: 'pointer'
  },
  right: {
    margin: '4px'
  }
}));
export default function EditExamForm(props) {
  const [page, setPage] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const pageTitles = ['Details', 'Exam Instruction', 'Price', 'Submit'];
  const {
    adminhandleCreate,
    handleCreate,
    handleChange,
    handlePublicedit,
    handleDescription,
    handleTypistChange,
    handleSubjectChangeEdit,
    handleChangeDuration,
    examPaper,
    allowpastPaper,
    submitting,
    errorMsg,
    typists,
    selectedTypist,
    selectedSubject,
    gradeOptions,
    subjectOptions,
    durationInHrs,
    durationInMins,
    initialValue,
    finalPrice,
    errors,
    availableCorrector,
    handleCorrectorChange,
    selectedCorrector,
    allTeachers,
    selectedTeacher,
    handleTeacherChange,
    handlePastPapers
  } = props;
  const classes = useStyles();
  const descript = 'description';
  console.log('examPaper', examPaper);
  const hasAdminRole = keycloakService.hasRole([ROLE.Admin]);
  const hasTeacherRole = keycloakService.hasRole([ROLE.Teacher]);
  const categoryOptionsTemp = [
    {
      id: 'MCQ',
      value: 'MCQ'
    },
    {
      id: 'ESSAY',
      value: 'ESSAY'
    }
  ];
  const languageSelectOption = [
    {
      id: 'English',
      value: 'English'
    },
    {
      id: 'Tamil',
      value: 'Tamil'
    },
    {
      id: 'Sinhala',
      value: 'Sinhala'
    }
  ];
  const handleStep = (step) => () => {
    setPage(step);
    setActiveStep(step);
  };

  const handleNext = (e) => {
    console.log('page', page);
    console.log('pageTitles', pageTitles.length);
    if (page === pageTitles.length - 1) {
      hasTeacherRole && hasAdminRole ? adminhandleCreate(e) : handleCreate(e);
    } else {
      setPage(page + 1);
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setPage(page - 1);
    setActiveStep(activeStep - 1);
  };

  const pageDisplay = () => {
    if (page == 0) {
      return (
        <DetailsEdit
          handleChangeClick={handleChange}
          Language={languageSelectOption}
          errors={errors}
          handleChange={handleChange}
          handleSubjectChange={handleSubjectChangeEdit}
          examPaper={examPaper}
          categoryOptionsTemp={categoryOptionsTemp}
          Grade={gradeOptions}
          Subject={subjectOptions}
          classes={classes}
          selectedSubject={selectedSubject}
        />
      );
    } else if (page == 1) {
      return (
        <ExamInstructions
          handleChange={handleChange}
          handleDescription={handleDescription}
          handleChangeDuration={handleChangeDuration}
          examPaper={examPaper}
          errors={errors}
          durationInMins={durationInMins}
          durationInHrs={durationInHrs}
          initialValue={initialValue}
          classes={classes}
          descript={descript}
        />
      );
    } else if (page == 2) {
      return (
        <Price
          handleChange={handleChange}
          handleTypistChange={handleTypistChange}
          examPaper={examPaper}
          typists={typists}
          allTeachers={allTeachers}
          selectedTypist={selectedTypist}
          handleTeacherChange={handleTeacherChange}
          selectedTeacher={selectedTeacher}
          handleCorrectorChange={handleCorrectorChange}
          errors={errors}
          finalPrice={finalPrice}
          availableCorrector={availableCorrector}
          selectedCorrector={selectedCorrector}
          classes={classes}
        />
      );
    } else {
      return (
        <Submit
          handlePublic={handlePublicedit}
          handlePastPapers={handlePastPapers}
          examPaper={examPaper}
          allowpublic={examPaper.public}
          allowpastPaper={allowpastPaper}
          classes={classes}
        />
      );
    }
  };
  return (
    <div>
      <h2
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px'
        }}
      >
        Edit Exam paper
      </h2>
      <Card
        style={{
          border: '1px solid #186CEE',
          borderRadius: '15px'
        }}
      >
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <form className={classes.root} noValidate autoComplete="off">
            <Stepper nonLinear activeStep={activeStep}>
              {pageTitles.map((label, index) => (
                <Step key={label}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Divider />
            {errorMsg ? <p className={classes.errorText}>{errorMsg}</p> : null}

            {pageDisplay()}

            <Grid
              container
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '10px'
              }}
            >
              <Grid item xs={6} sm={6}></Grid>
              <Grid
                item
                xs={6}
                sm={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginTop: '20px'
                }}
              >
                <Button
                  onClick={() => {
                    handleBack();
                  }}
                  disabled={page == 0}
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: '10px' }}
                >
                  Back
                </Button>
                <Button
                  onClick={(e) => {
                    handleNext(e);
                  }}
                  disabled={submitting}
                  variant="contained"
                  style={{ marginRight: '15px', backgroundColor: '#186CEE', color: 'white' }}
                >
                  {page === pageTitles.length - 1 ? 'Update' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
