import axios from 'axios';
import { API_URL, authHeader, authMutliFormHeader } from '../utils/helper';
import {
  FETCH_EXAM_PAPER_BY_ID,
  FETCH_EXAM_QUESTION_BY_ID,
  FETCH_ALL_TEACHERS,
  FETCH_TEACHER_EXAM_PAPERS,
  FETCH_ALL_QUESTIONS,
  FETCH_EXAM_PAPERS,
  FETCH_ALL_TYPISTS,
  FETCH_VALIDATED_USER_LIST,
  FETCH_TEACHER_PAPERS_BY_EXAM_STATUS,
  FETCH_PUBLISHED_PAPERS,
  CLEAR_VALID_USER_LIST,
  CLEAR_EXAM_PAPER_DETAILS,
  FETCH_OVERALL_EXAM_PAPERS_STAT,
  FETCH_EXAM_PAPER_STAT_BY_PAPER_ID,
  FETCH_EXAM_PAPER_STAT_BY_BATCH,
  FETCH_TEACHER_FINAL_PRICE,
  TEST_GROUP_QUESTION
} from '../utils/type';
import { getValidToken } from './filterAction';
import { displayLoader } from './loaderAction';

export const createExamPaper = (examPaperData, onSuccess) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/exam/paper`, examPaperData, authHeader(token))
      .then((res) => {
        dispatch(displayLoader(false));
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
        dispatch(displayLoader(false));
      });
  };
};

export const updateExamPaper = (examPaperData, onSuccess) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .put(`${API_URL}/exam/paper`, examPaperData, authHeader(token))
      .then((res) => {
        dispatch(displayLoader(false));
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
        dispatch(displayLoader(false));
      });
  };
};

export const publishExamPaper = (examPaperId, examStatus, onsuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(`${API_URL}/exam/paper/${examPaperId}?examStatus=${examStatus}`, {}, authHeader(token))
      .then(() => {
        onsuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const createQuestion = (examPaperData, onSuccess) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/exam/question`, examPaperData, authHeader(token))
      .then((res) => {
        dispatch(displayLoader(false));
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
        dispatch(displayLoader(false));
      });
  };
};

export const updateQuestion = (examPaperData, onSuccess) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .put(`${API_URL}/exam/question`, examPaperData, authHeader(token))
      .then((res) => {
        dispatch(displayLoader(false));
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
        dispatch(displayLoader(false));
      });
  };
};

export const fetchSearchResult = (teacherId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/teacher/papers?teacherId=${teacherId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_TEACHER_EXAM_PAPERS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchExamPaperById = (examPaperId, onSuccess = () => {}) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/paper/${examPaperId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_EXAM_PAPER_BY_ID,
          data: res.data
        });
        onSuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchExamQuestionById = (questionId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/question/${questionId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_EXAM_QUESTION_BY_ID,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchAllTeachers = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/user/teachers`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_TEACHERS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const uploadFiles = (fileData, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/file/upload`, fileData, authMutliFormHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const deleteFile = (filepath, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .delete(`${API_URL}/file?path=${filepath}`, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchAllQuestions = (examPaperId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/paper/${examPaperId}/questions`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_QUESTIONS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const updateQuestionStatus = (questionData, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(
        `${API_URL}/exam/question/${questionData.questionId}?questionStatus=${questionData.status}`,
        {},
        authHeader(token)
      )
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchExamPapers = (teacherId, paperdetails) => {
  const url = `${API_URL}/exam/teacher/papers?teacherId=${teacherId}`;
  let status = '';
  if (paperdetails.status) {
    status = paperdetails.status;
  }
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${url}&examStatusList=${status}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_EXAM_PAPERS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchAllTypists = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/user/typists`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_TYPISTS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong fetching typists', e);
      });
  };
};

export const fetchValidatedUserList = (userEmails) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/user/search-by-email?emails=${userEmails}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_VALIDATED_USER_LIST,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong fetching typists', e);
      });
  };
};

export const subscribePapers = (subscriptionData, onSuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/exam/subscriptions`, subscriptionData, authHeader(token))
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong subscribe papers', e);
      });
  };
};

export const fetchTeacherPapers = (teacherId, examStatus) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(
        `${API_URL}/exam/teacher/papers?teacherId=${teacherId}&examStatus=${examStatus}`,
        authHeader(token)
      )
      .then((res) => {
        dispatch({
          type: FETCH_TEACHER_PAPERS_BY_EXAM_STATUS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchPublishedPapers = (teacherId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(
        `${API_URL}/exam/teacher/papers?teacherId=${teacherId}&examStatusList=PUBLISHED`,
        authHeader(token)
      )
      .then((res) => {
        dispatch({
          type: FETCH_PUBLISHED_PAPERS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchOverAllExamPapersStat = (onSuccess = () => {}) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/report/exam-paper/stat/overall`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_OVERALL_EXAM_PAPERS_STAT,
          data: res.data
        });
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong fetchOverAllExamPapersStat', e);
      });
  };
};

export const fetchExamPaperStatByPaperId = (examPaperId, onSuccess = () => {}) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/report/exam-paper/stat/${examPaperId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_EXAM_PAPER_STAT_BY_PAPER_ID,
          data: res.data
        });
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong fetchExamPaperStatByPaperId', e);
      });
  };
};

export const fetchExamPaperStatByBatch = (examDetails) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(
        `${API_URL}/report/exam-paper/stat/batch?examId=${examDetails.examId}&batch=${examDetails.batch}`,
        authHeader(token)
      )
      .then((res) => {
        dispatch({
          type: FETCH_EXAM_PAPER_STAT_BY_BATCH,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong fetchExamPaperStatByBatch', e);
      });
  };
};
export const clearValidUserList = () => {
  return {
    type: CLEAR_VALID_USER_LIST
  };
};

export const clearExamPaperDetails = () => {
  return {
    type: CLEAR_EXAM_PAPER_DETAILS
  };
};

export const fetchFinalTeacherPrice = (finalPrice) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/get-selling-price?teacherPrice=${finalPrice}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_TEACHER_FINAL_PRICE,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong fetchExamPaperStatByPaperId', e);
      });
  };
};

export const testGroupQuestion = (grpQuestion) => (dispatch) => {
  dispatch({
    type: TEST_GROUP_QUESTION,
    data: grpQuestion // assuming you want to use "payload" instead of "action"
  });
};
