import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamPapers } from '../../actions/examPaperAction';
import keycloakService from '../../keycloakService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAvailableCorrectors } from '../../actions/CorrectorAction';
import { Autocomplete } from '@material-ui/lab';
import { findTypist } from '../../utils/helper';
import { reAssignCorrector } from '../../actions/CorrectorAction';
import { useTranslation } from 'react-i18next';

function CorrectorRejectedRequest() {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const [open, setOpen] = useState({ id: '' });
  const [typist, setTypist] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const pendingExamPapers = useSelector((state) => state.examPaper.pendingExamPapers);
  const AllCorrector = useSelector((state) => state.essayPaperRequest.availableCorrector);

  useEffect(() => {
    const userDetails = keycloakService.getUserDetails();
    dispatch(fetchExamPapers(userDetails.sub, { status: 'PENDING,DRAFT' }));
    dispatch(getAvailableCorrectors());
  }, []);
  const handleClick = async (examPaper) => {
    try {
      examPaper.corrector = typist;
      dispatch(reAssignCorrector(examPaper.id, typist));
      history.push('/typing-papers');

      const menu = {
        title: 'Corrector Reject Request',
        id: 42
      };
      await localStorage.setItem('menu', JSON.stringify(menu));
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      window.location.reload();
    }
  };
  const handleChange = (e, val, allPapers) => {
    e.preventDefault();
    if (val != null && findTypist(AllCorrector, allPapers) != val) {
      setOpen({ id: allPapers?.id });
      setTypist(val?.id);
    } else {
      setOpen({ id: '' });
      setTypist('');
    }
  };
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                {t('corrector_rejected_teacher')}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table arial-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('corrector_name')}</TableCell>
                    <TableCell>{t('subject_name')}</TableCell>
                    <TableCell>{t('subject_title')}</TableCell>
                    <TableCell>{t('corrector_status')}</TableCell>
                    <TableCell>{t('change_corrector')}</TableCell>
                    <TableCell>{t('action')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingExamPapers.length >= 1 &&
                    pendingExamPapers.map(
                      (allPapers, index) =>
                        allPapers.correctorStatus === 'REQUEST_REJECTED' && (
                          <TableRow key={index}>
                            <TableCell>{allPapers.correctorName}</TableCell>
                            <TableCell>{allPapers.subject}</TableCell>
                            <TableCell>{allPapers.title}</TableCell>
                            <TableCell>{allPapers.correctorStatus}</TableCell>
                            <TableCell>
                              <Autocomplete
                                id="corrector-id"
                                name="corrector"
                                options={AllCorrector}
                                getOptionLabel={(option) => option.fullName}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t('select_corrector')}
                                    variant="outlined"
                                  />
                                )}
                                onChange={(e, val) => handleChange(e, val, allPapers)}
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={() => handleClick(allPapers)}
                                color="primary"
                                style={{
                                  backgroundColor: open?.id == allPapers?.id ? '#7EC8E3' : '#C5C6D0'
                                }}
                                disabled={open.id == allPapers.id ? false : true}
                              >
                                {t('update_corrector')}
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                    )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default CorrectorRejectedRequest;
