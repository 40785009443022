import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import CardMedia from '@material-ui/core/CardMedia';
import Science from './chemistry-bg.d15053e8159411c51ddc.png';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
// import PublishIcon from '@mui/icons-material/Publish';
import './yearBreakDown.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);

export default function BasicTable(props) {
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const { historyData, yearCountClick } = props;
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const displayYearCountTable = (data) => {
    if (data.stats && data.stats.length) {
      return data.stats.map((stat) => (
        <div
          key={stat.year}
          onClick={() => yearCountClick(data.paperId, stat.year)}
          className="d-flex align-items-center mt-2"
        >
          <div className="d-flex align-items-center me-1">
            <Chip
              label={
                <div className="d-flex align-items-center">
                  <Typography variant="subtitle2" color="textSecondary">
                    {t('year')}:
                  </Typography>
                  <span className="ms-1">{stat.year}</span>
                </div>
              }
              href="#"
              clickable
            ></Chip>
          </div>
          <div className="d-flex align-items-center ">
            <Chip
              label={
                <div className="d-flex align-items-center">
                  <Typography variant="subtitle2" color="textSecondary">
                    {t('paper_count')}:
                  </Typography>
                  <span className="ms-1">{stat.count}</span>
                </div>
              }
              href="#"
              clickable
            ></Chip>
          </div>
        </div>
      ));
    }
    return (
      <div className="d-flex align-items-center mt-2">
        <div className="d-flex align-items-center me-1">
          <Chip
            label={
              <div className="d-flex align-items-center">
                <Typography variant="subtitle2" color="textSecondary">
                  {t('year')}:
                </Typography>
                <span className="ms-1"></span>
              </div>
            }
            href="#"
            clickable
          ></Chip>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex top-filter-area align-items-center">
        <div>
          <span>Showing 1 of 10</span>
          <span>125 papers available</span>
        </div>
        <div className="ml-auto">
          <FormControl className="me-3" style={{ width: '150px' }}>
            <InputLabel htmlFor="demo-customized-select-native">Category</InputLabel>
            <NativeSelect
              id="demo-customized-select-native"
              value={age}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </NativeSelect>
          </FormControl>
          <FormControl className="me-3" style={{ width: '150px' }}>
            <InputLabel htmlFor="demo-customized-select-native">Year</InputLabel>
            <NativeSelect
              id="demo-customized-select-native"
              value={age}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </NativeSelect>
          </FormControl>
          <FormControl style={{ width: '150px' }}>
            <InputLabel htmlFor="demo-customized-select-native">Sort by best match</InputLabel>
            <NativeSelect
              id="demo-customized-select-native"
              value={age}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      {historyData.map((row) => (
        <Card className="history-paper-card" key={row.paperId}>
          <CardMedia image={Science} title="Live from space album cover" className="card-image" />
          <CardContent className="card-content">
            <Typography variant="h6" gutterBottom>
              {row.paperTitle}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Typography>
            <div className="d-flex flex-flow-row">{displayYearCountTable(row)}</div>
          </CardContent>
          <CardActions className="card-action">
            <div className="d-flex flex-flow-col ">
              <Button
                size="small"
                color="primary"
                style={{ width: '120px', border: '0.5px solid blue', marginBottom: '30px' }}
              >
                {t('view_report')}
              </Button>
              {/* <div className="status">
                <PublishIcon className="icon" />
                <span>{t('published')}</span>
              </div> */}
            </div>
          </CardActions>
        </Card>
      ))}
    </div>
  );
}
