import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPendingReviewRequest } from '../../actions/typistAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

function PaperReviewRequest() {
  const dispatch = useDispatch();
  const pendingReviewRequest = useSelector((state) => state.examPaperRequest.pendingReviewRequest);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  useEffect(() => {
    dispatch(fetchPendingReviewRequest());
  }, []);
  const handleClick = (id) => {
    history.push(`/exam-paper/${id}/questions`);
  };
  const handleDateValidation = (date, noOfTypingDays) => {
    if (!date) return 'No data';
    else {
      const currentDate = new Date();
      const typistAcceptDate = new Date(date);
      const timeDifference = currentDate - typistAcceptDate;
      const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return dayDifference < noOfTypingDays
        ? `${noOfTypingDays - dayDifference}-left`
        : 'days exceeded';
    }
  };
  return (
    <DashboardLayout>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <h2
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '20px'
                }}
              >
                {t('review_pending_paper')}
              </h2>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('teacher_name')}</TableCell>
                    <TableCell>{t('subject_name')}</TableCell>
                    <TableCell>{t('subject_title')}</TableCell>
                    <TableCell>{t('need_to_finish')}</TableCell>
                    <TableCell>{t('review_message')}</TableCell>
                    <TableCell>{t('review_status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingReviewRequest.length >= 1 &&
                    pendingReviewRequest.map((acceptPapers, index) => (
                      <TableRow key={index}>
                        <TableCell>{acceptPapers.teacherName}</TableCell>
                        <TableCell>{acceptPapers.subject}</TableCell>
                        <TableCell>{acceptPapers.title}</TableCell>
                        <TableCell>
                          {handleDateValidation(
                            acceptPapers?.typingAcceptDate,
                            acceptPapers?.noOfTypingDays
                          )}
                        </TableCell>
                        <TableCell>
                          {acceptPapers.reviewComments ? acceptPapers.reviewComments : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {acceptPapers.typistStatus == 'REVIEW_REJECTED' ? (
                            <Button
                              onClick={() => handleClick(acceptPapers.id)}
                              color="primary"
                              style={{ backgroundColor: '#7EC8E3' }}
                            >
                              {t('edit')}
                            </Button>
                          ) : (
                            acceptPapers.typistStatus
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
}

export default PaperReviewRequest;
