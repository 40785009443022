import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './Subscriptions.css';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherSubscriptions } from '../../actions/userAction';
import { useTranslation } from 'react-i18next';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.candidateFirstName}</TableCell>
        <TableCell align="left">{row.candidateLastName}</TableCell>
        <TableCell align="left">{row.candidateEmail}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {t('subscribed_paper')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('exam_title')}</TableCell>
                    <TableCell>{t('batch')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subscribedPapers.map((subscribedPaperRow) => (
                    <TableRow key={subscribedPaperRow.examId}>
                      <TableCell component="th" scope="row">
                        {subscribedPaperRow.examTitle}
                      </TableCell>
                      <TableCell>{subscribedPaperRow.batch}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

export default function Subscriptions() {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const { subscriptions } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();

  const language = useSelector((state) => state.language).selectedLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    dispatch(getTeacherSubscriptions());
  }, []);

  useEffect(() => {
    if (subscriptions && subscriptions.length > 0) {
      setRows(rows);
    }
  }, [subscriptions]);

  return (
    <DashboardLayout>
      <div className="tableContainer">
        <span className="title" style={{ justifyContent: 'center', display: 'flex' }}>
          {t('subscription_detail')}
        </span>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow style={{ border: '1px solid #186CEE', borderRadius: '10px' }}>
                <TableCell />
                <TableCell align="left">{t('first_name')}</TableCell>
                <TableCell align="left">{t('last_name')}</TableCell>
                <TableCell align="left">{t('email')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </DashboardLayout>
  );
}
